import { writable, readable, readonly } from 'svelte/store'
import validator from 'services/validator.js'
import api from 'services/api.js'

const noPreviousLoad = { previousLoad: null, comparisonArgsEqual: false, offsetsEqual: false }
const unset = Symbol('unset')
const failed = Symbol('failed')

// TODO: This LoadManager is hardcoded with the intention of being used in conjunction
// with an InfiniteScroll component. Its behavior should be different if used with a
// Grid component or something else that has a different loading/display pattern.
// Consider making this an abstract class and then creating two subclasses:
// InfiniteScrollLoadManager and PaginatedLoadManager.

export const dummyLoadManager = {
  loadingFirstPage: readable(false),
  loading: readable(false),
  failedLoadingFirstPage: readable(false),
  failedLoading: readable(false),
  isFullyLoaded: readable(false),
}

export default class LoadManager {
  #apiFn = null
  #onFirstPageResponse = null
  #responseListSelector = null
  // TODO: Implement this. The intention is that when you pass in something like selectedCapacityIds: [9001],
  // the LoadManager can intelligently look at it and the comparisonArgs and determine if the request needs to be made.
  // Maybe there's already an in-flight request that'll satisfy the criteria, so don't issue another one.
  // #requestSelectedListSelector = null
  #responseSelectedListSelector = null
  #keySelector = unset
  #loads = []
  #currentLoadId = 1 // Instead of zero, to avoid >= comparisons with null/undefined resulting in true, even though we're being careful down below.
  #resetAtLoadId = 0

  // We need to read the values of these state variables in condition checking places,
  // so we're going to add subscriptions to the writables to keep them current.
  #_loading = false
  #_totalCount = null
  #_currentCount = null
  #_isFullyLoaded = false

  #loadingFirstPage = writable(false)
  #loading = writable(false)

  #failedLoadingFirstPage = writable(false)
  #failedLoading = writable(false)

  #isFullyLoaded = writable(false)
  #totalCount = writable(null)
  #results = writable(null)
  #selectedMap = new Map()
  #selected = writable(this.#selectedMap)
  #cacheByKeyMap = new Map()
  #cacheByKey = writable(this.#cacheByKeyMap)

  loadingFirstPage = readonly(this.#loadingFirstPage)
  loading = readonly(this.#loading)
  failedLoadingFirstPage = readonly(this.#failedLoadingFirstPage)
  failedLoading = readonly(this.#failedLoading)

  isFullyLoaded = readonly(this.#isFullyLoaded)
  totalCount = readonly(this.#totalCount)
  results = readonly(this.#results)
  selected = readonly(this.#selected)
  cacheByKey = readonly(this.#cacheByKey)

  constructor(apiFn, { responseListSelector, /*requestSelectedListSelector,*/ responseSelectedListSelector, keySelector, onFirstPageResponse } = {}) {
    if (responseListSelector == null) throw new Error('responseListSelector is required')

    this.#apiFn = apiFn
    this.#responseListSelector = responseListSelector
    // this.#requestSelectedListSelector = requestSelectedListSelector
    this.#responseSelectedListSelector = responseSelectedListSelector
    this.#keySelector = keySelector ?? unset
    this.#onFirstPageResponse = onFirstPageResponse
    this.#loading.subscribe(v => (this.#_loading = v))
    this.#totalCount.subscribe(v => (this.#_totalCount = v))
    this.#results.subscribe(v => (this.#_currentCount = v?.length))
    this.#isFullyLoaded.subscribe(v => (this.#_isFullyLoaded = v))
  }

  // This is intended to be called after a user has submitted a form impacting
  // the underlying list of data. It will reset the LoadManager to its initial state
  // so fresh data can be loaded, and outstanding requests can be ignored when they complete.
  reset() {
    this.#loads = []
    this.#resetAtLoadId = this.#currentLoadId
    this.#cacheByKeyMap.clear()
    this.#cacheByKey.set(this.#cacheByKeyMap)
    this.#loadingFirstPage.set(false)
    this.#failedLoadingFirstPage.set(false)
    this.#loading.set(false)
    this.#failedLoading.set(false)
    this.#totalCount.set(null)
    this.#results.set(null)
    this.#selectedMap.clear()
    this.#selected.set(this.#selectedMap)
  }

  // TODO: Figure out what the return value should be. Maybe include the result of the timing
  //       so the caller can decide what to do about the fact there are more pending loads.
  // If comparisonArgs are different than the previous request, we will reset the offset to 0.
  // If comparisonArgs are the same, but the offset is different, we will load the new offset.
  // If comparisonArgs are the same and the offset is the same, we will do nothing.
  async loadIfNecessary(comparisonArgs, offset, alwaysArgs, firstPageArgs, nextPageArgs) {
    const { previousLoad, comparisonArgsEqual, offsetsEqual } = this.#compareLoadWithPrevious(comparisonArgs, offset)
    if (comparisonArgsEqual && offsetsEqual) return null
    // Could maybe throw an error here when we're attempting to load a page that's already been
    // requested to be loaded, but let's just soft-fail for now.
    if (comparisonArgsEqual && previousLoad && offset < previousLoad.offset) return null
    if (!comparisonArgsEqual) offset = 0

    const args = this.#buildArgs(offset, alwaysArgs, firstPageArgs, nextPageArgs)
    const thisLoad = {
      loadId: this.#currentLoadId++,
      comparisonArgs: structuredClone(comparisonArgs),
      offset,
      alwaysArgs,
      nextPageArgs,
      requestArgs: args,
      request: unset,
      response: unset,
      error: null,
      comparisonArgsEqualPrevious: comparisonArgsEqual,
    }
    this.#loads.push(thisLoad)
    try {
      thisLoad.response = await this.#startLoading(thisLoad)
    } catch (error) {
      return this.#handleFailedLoad(thisLoad, error)
    }
    return this.#handleResponse(thisLoad)
  }

  async loadNextPageIfNecessary() {
    if (this.#_loading || this.#_isFullyLoaded) return
    const lastLoad = this.#loads.at(-1)
    if (lastLoad == null) return
    const pageSize = lastLoad.alwaysArgs.route?.pageSize ?? lastLoad.alwaysArgs.body?.pageSize ?? lastLoad.alwaysArgs.query?.pageSize
    const offset = lastLoad.offset + pageSize
    return this.loadIfNecessary(lastLoad.comparisonArgs, offset, lastLoad.alwaysArgs, null, lastLoad.nextPageArgs)
  }

  async retry() {
    const failedLoads = []
    for (const thisLoad of this.#loads) {
      if (thisLoad.response !== failed) continue
      if (thisLoad.loadId <= this.#resetAtLoadId) continue
      failedLoads.push(thisLoad)
      this.#startLoading(thisLoad)
    }
    if (!failedLoads.length) return

    for (const failedLoad of failedLoads) {
      failedLoad.request
        .then(response => {
          failedLoad.response = response
          this.#handleResponse(failedLoad)
        })
        .catch(error => {
          this.#handleFailedLoad(failedLoad, error)
        })
    }
  }

  getCachedValue(key) {
    return this.#cacheByKeyMap.get(key)?.value ?? null
  }

  #startLoading(thisLoad) {
    thisLoad.request = this.#apiFn(...thisLoad.requestArgs)
    this.#loading.set(true)
    if (thisLoad.offset === 0) this.#loadingFirstPage.set(true)
    return thisLoad.request
  }

  #handleFailedLoad(thisLoad, error) {
    thisLoad.response = failed
    thisLoad.error = error
    if (thisLoad.loadId <= this.#resetAtLoadId) return
    if (!this.#loads.includes(thisLoad)) return
    this.#failedLoading.set(true)
    this.#updateLoading()
    if (thisLoad.offset === 0) {
      //TODO: Consider updating this.#isFullyLoaded here.
      this.#updateLoadingFirstPage()
      this.#updateFailedLoadingFirstPage()
    }
  }

  #handleResponse(thisLoad) {
    // Let's handle the case where a misbehaving component or eager user has kicked off requests
    // for pages 1, 2, and 3 with one criteria-set, then switched the criteria-set and requested
    // pages 1-3 for that one. To discuss expectations, let's assume the completion order is this:
    // B3: We don't have B1-B2, so we can't show B3 yet.
    // A1: We can show A1.
    // A3: We don't have A2, so we can't show A3 yet.
    // B1: We can replace A1 with B1. At this point, we could cancel A2 if we wanted, as when it completes, it should be discarded.
    // A2: We could now show A1-A3, but it's irrelevant because we've already shown B1.
    // B2: We can show B1-B3 because B3 already completed.
    // Note that we're assuming both the component and the user also never request a page out of sequence.
    // That is, we expect loadIfNecessary() to be called with increasing offsets (unless the criteria-set changes).

    // If we reset prior to this completing, we shouldn't update the cache.
    if (thisLoad.loadId <= this.#resetAtLoadId) return

    // There's only going to be one index of thisLoad, but start from the end because it's more likely
    // to be near the end when users are scrolling through an infinite list and we're not removing loads.
    let index = this.#loads.lastIndexOf(thisLoad)

    // Even if we're not going to use this response for display, we can add these values to the cache.
    this.#updateCacheByKey(thisLoad)
    if (index < 0) return // Another load deemed this one unnecessary for display.

    // If we're on the first page of any criteria-set, we can blow away everything before this load.
    if (thisLoad.offset === 0) {
      this.#results.set([])
      // TODO: This is potentially going to cause the InfiniteScroll to kick off another request for page 2
      //       but this is problematic because there could be another criteria-set in flight.
      this.#totalCount.set(thisLoad.response.totalCount)
      this.#loads.splice(0, index)
      index = 0

      this.#updateLoadingFirstPage()
      this.#updateFailedLoadingFirstPage()
    }

    // If we can get back to index === 0, then we can display the results.
    let canDisplayResults = true
    for (let i = index; i >= 0; i--) {
      const load = this.#loads[i]
      if (load.response === unset) {
        canDisplayResults = false
        break
      }
    }

    if (canDisplayResults) {
      const thisPageResults = this.#responseListSelector?.(thisLoad.response)
      if (thisPageResults) {
        this.#results.update(r => {
          r.push(...thisPageResults)
          return r
        })
      }
    }
    this.#updateLoading()
    this.#updateFailedLoading()
    if (thisLoad.offset === 0) this.#onFirstPageResponse?.(thisLoad)
    this.#isFullyLoaded.set(this.#_currentCount >= this.#_totalCount)
  }

  #compareLoadWithPrevious(comparisonArgs, offset) {
    const previousLoad = this.#loads.at(-1)
    if (previousLoad == null) return noPreviousLoad
    return {
      previousLoad,
      comparisonArgsEqual: validator.equals(previousLoad.comparisonArgs, comparisonArgs),
      offsetsEqual: previousLoad.offset === offset,
    }
  }

  #updateLoading() {
    const anyLoading = this.#loads.some(load => load.response === unset)
    this.#loading.set(anyLoading)
  }

  #updateFailedLoading() {
    const anyFailedLoading = this.#loads.some(load => load.response === failed)
    this.#failedLoading.set(anyFailedLoading)
  }

  #updateLoadingFirstPage() {
    const anyLoadingFirstPage = this.#loads.some(load => load.offset === 0 && load.response === unset)
    this.#loadingFirstPage.set(anyLoadingFirstPage)
  }

  #updateFailedLoadingFirstPage() {
    const anyFailedLoadingFirstPage = this.#loads.some(load => load.offset === 0 && load.response === failed)
    this.#failedLoadingFirstPage.set(anyFailedLoadingFirstPage)
  }

  #buildArgs(offset, alwaysArgs, firstPageArgs, nextPageArgs) {
    const extraArgs = offset === 0 ? firstPageArgs : nextPageArgs
    const args = []
    this.#pushArgs(args, 'route', alwaysArgs, extraArgs)
    this.#pushArgs(args, 'body', alwaysArgs, extraArgs, { body: { offset } })
    this.#pushArgs(args, 'query', alwaysArgs, extraArgs)
    args.push(api.noMonitor)
    return args
  }

  #pushArgs(args, key, ...sources) {
    const value = {}
    let hasValue = false
    for (const source of sources) {
      const sourceValue = source?.[key]
      if (sourceValue == null) continue
      hasValue = true
      Object.assign(value, sourceValue)
    }
    if (hasValue) args.push(value)
  }

  #updateCacheByKey(load) {
    if (this.#keySelector === unset) return
    const selectedValues = this.#responseSelectedListSelector?.(load.response)
    const pageValues = this.#responseListSelector?.(load.response)
    // Highly likely that the selected values are just as up-to-date as the page values.
    // However, let's update the cache with the selected values first in case the selected values have
    // more up - to - date information than the page values (since we discard data from  loads <= the cache entry).
    // This isn't currently a pattern in our app, but if we introduce caching on paginated values, it could be.
    const anyUpdatedFromSelectedValues = this.#updateCacheValues(load.loadId, selectedValues, true)
    this.#updateCacheValues(load.loadId, pageValues)
    if (anyUpdatedFromSelectedValues) this.#selected.set(this.#selectedMap)
  }

  #updateCacheValues(loadId, values, setIntoSelected) {
    if (!Array.isArray(values)) return false
    let anyUpdated = false
    for (const value of values) {
      const key = this.#keySelector(value)
      if (key == null) continue
      const cacheEntry = this.#cacheByKeyMap.get(key)
      if (cacheEntry && cacheEntry.loadId >= loadId) continue
      anyUpdated = true
      this.#cacheByKeyMap.set(key, { loadId, value })
      if (setIntoSelected) this.#selectedMap.set(key, value)
    }
    return anyUpdated
  }
}
