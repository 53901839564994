<ButtonModalPicker
  bind:this={buttonModalPicker}
  bind:open
  lg
  {disabled}
  {multiple}
  valueSelector={a => a.agreementId}
  labelSelector={a => a.name}
  options={agreements}
  optionsForLabel={agreementsForLabel}
  bind:value
  bind:valueTemp
  {modalTitle}
  {placeholder}
  class={className}
  {dataTest}
  modalClass="overflow-visible"
  modalContentClass="overflow-visible"
  {allowSelectNull}
  {or}
>
  <!-- We don't want the contents of the picker to appear invalid, so wrap it in an always-valid <FormGroup> -->
  <FormGroup valid class="full-height flex-column g1">
    <AgreementFilters fullWidth {excludedFilterTypes} bind:filters {interceptors} onChanged={() => loadPage(0)}>
      <svelte:fragment slot="after-keyword-search">
        <AgreementPickerShowDropdown />
      </svelte:fragment>
    </AgreementFilters>

    {#if loading && currentXhrBody.offset === 0}
      <div class="text-center mt1">
        <Spinner x3 class="m2" />
      </div>
    {:else}
      <InfiniteScroll
        currentCount={agreements?.length}
        {totalCount}
        distanceToLoadPage={100}
        {loadPage}
        class="scrollable-lg flex-grow flex-column {showMinimal ? 'g05' : 'g1'}"
        style="padding-right: 15px"
      >
        <EmptyPickerSlot
          bind:valueTemp
          {allowSelectNull}
          text="No agreement"
          {multiple}
          {buttonModalPicker}
          icon="agreement"
          iconAutoColor
          {dataTest}
          lg={!showMinimal}
        />
        {#if agreements?.length}
          {#each agreements as a, i (a.agreementId)}
            <PickerSlot
              {i}
              {dataTest}
              {multiple}
              value={a.agreementId}
              {buttonModalPicker}
              lg={!showMinimal}
              bind:valueTemp
              let:isSelected
              let:isHovered
            >
              <slot name="header" agreement={a} slot="header">
                <Icon lg={!showMinimal} name="agreement" autoColor />
                <svelte:element this={showMinimal ? 'h4' : 'h3'} class="leading-none m0 normal">{a.name}</svelte:element>
              </slot>

              <slot agreement={a} {isSelected} {isHovered}>
                <AgreementPickerSlot agreement={a} {isHovered} {isSelected} />
              </slot>
            </PickerSlot>
          {/each}
        {:else if agreements == null}
          <!-- This should never happen, but just in case... -->
          Failed to load agreements. <a href={null} on:click={() => loadPage(0)}>Retry?</a>
        {:else}
          <h4 class="p3 text-center">No agreements found.</h4>
        {/if}
      </InfiniteScroll>
    {/if}
  </FormGroup>
</ButtonModalPicker>

<script context="module">
  import { AgreementStatus, FeatureType, FilterType } from 'config/enums.js'
  export const activeStatusesOnly = {
    type: FilterType.AgreementStatuses,
    config: {
      statuses: [AgreementStatus.Unsubmitted, AgreementStatus.PendingClinicalSite, AgreementStatus.PendingSchool, AgreementStatus.Active],
    },
  }
</script>

<script>
  import api from 'services/api.js'
  import AgreementPickerShowDropdown from 'components/AgreementPickerShowDropdown.svelte'
  import ButtonModalPicker from 'components/fields/ButtonModalPicker.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import Icon from 'components/Icon.svelte'
  import InfiniteScroll from 'components/InfiniteScroll.svelte'
  import persona from 'stores/persona.js'
  import personaService from 'services/persona-service.js'
  import PickerSlot from 'components/PickerSlot.svelte'
  import AgreementFilters from 'components/AgreementFilters.svelte'
  import AgreementPickerSlot from 'components/fields/AgreementPicker.Slot.svelte'
  import showDropdowns from 'stores/show-dropdowns.js'
  import Spinner from 'components/Spinner.svelte'
  import validator from 'services/validator.js'
  import EmptyPickerSlot from 'components/EmptyPickerSlot.svelte'

  // Common picker exports
  export let value
  export let filters = []
  export let hiddenFilters = []
  export let excludedFilterTypes = []
  export let placeholder = 'None selected'
  export let multiple = false
  export let modalTitle = multiple ? 'Select the agreements' : 'Select the agreement'
  export let disabled = false
  export let allowSelectNull = false
  let className = ''
  export { className as class }
  export let dataTest = 'agreement-picker'
  export let interceptors = {}
  export let open = false
  // Specific picker exports
  export let agreementCount = null
  export let selectedAgreement = null
  export let or = false

  const pageSize = 15
  let buttonModalPicker = null
  let totalCount = null
  let agreements = null
  let agreementsForLabel = []
  let valueTemp = null
  let loading = false
  let currentXhrBody = null

  $: show = $showDropdowns.agreementPicker
  $: showMinimal = !Object.keys(show)
    .map(k => show[k])
    .some(Boolean)

  // TODO(services): Retain changes in a store.

  $: hasCoreSchedulingFeature = personaService.canUseAnyFeatureType(FeatureType.CoreScheduling)
  $: personaOrgId = $persona.orgId
  $: personaOrgId, hasCoreSchedulingFeature, loadPage(0)
  $: allAgreements = agreements && agreementsForLabel ? [...agreements, ...agreementsForLabel] : []
  $: selectedAgreement = value && allAgreements?.length ? allAgreements.find(a => a.agreementId === value) : null
  $: allFilters = _.cloneDeep([...filters, ...hiddenFilters])

  $: open, loadFirstPageIfFiltersChanged()

  function loadFirstPageIfFiltersChanged() {
    if (!open || validator.equals(filters ?? [], currentXhrBody?.filters ?? [])) return
    if (open) loadPage(0)
  }

  async function loadPage(offset) {
    if (!$persona.orgId || !hasCoreSchedulingFeature) return
    const thisXhrBody = {
      filters: allFilters,
      includeOrgAndTeam: true,
      includeAddresses: true,
      includeServices: true,
      includeDisciplines: true,
      pageSize,
      offset,
    }

    const selectedAgreementIds = (multiple ? value ?? [] : [value]).filter(id => id != null && !agreementsForLabel.some(a => a.agreementId === id))
    if (selectedAgreementIds.length) thisXhrBody.selectedAgreementIds = selectedAgreementIds

    if (validator.equals(currentXhrBody, thisXhrBody)) return
    loading = true
    currentXhrBody = thisXhrBody

    try {
      const task = api.agreement.list(thisXhrBody, api.noMonitor)
      const response = await task
      // TODO: Could handle if filters were changed prior to response being received... for now, assume server is fast enough.
      totalCount = response.totalCount
      agreements = offset ? [...agreements, ...response.agreements] : response.agreements
      if (selectedAgreementIds.length) agreementsForLabel = [...agreementsForLabel, ...(response.selectedAgreements ?? [])]
      if (offset === 0 && agreementCount == null) agreementCount = totalCount
    } finally {
      if (validator.equals(currentXhrBody, thisXhrBody)) loading = false
    }
  }

  export function clear() {
    value = null
    valueTemp = null
  }

  export function focusAndOpen() {
    buttonModalPicker?.focusAndOpen()
  }
</script>
