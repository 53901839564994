import { CountBy, CriteriaFilterType, DashboardViewCategory, DateCountType, FeatureType, FilterType, Permission } from 'config/enums.js'

import dayjs from 'dayjs'

const excludedFilterTypes = [
  FilterType.KeywordSearch,
  FilterType.MatchEndDate,
  FilterType.MatchHasIncompleteSteps,
  FilterType.MatchHasProposedChanges,
  FilterType.Steps,
  FilterType.MatchHasStepsICanComplete,
  FilterType.MatchHasStepsICanVerify,
  FilterType.MatchStartDate,
  FilterType.MatchStatusCategory,
  FilterType.StepSubmissionTargetUser,
]
// TODO: filterTypes and ignoredFilterTypes should use excludedFilterTypes but there is a conflict with MatchStatusCategory

export default {
  category: DashboardViewCategory.Reports,
  permission: Permission.ViewRotationBreakdownReport,
  featureTypes: [FeatureType.ReportRotationBreakdown],
  componentName: 'RotationBreakdownReport',
  name: 'Rotation breakdown',
  icon: 'chart-bar',
  criteria: {
    filters: [],
    startDate: dayjs().startOf('month').subtract(6, 'months').format('M/D/YYYY'),
    endDate: dayjs().format('M/D/YYYY'),
    countBy: CountBy.AcceptedRotations,
    includePlaceholderStudentCount: true,
    dateCountType: DateCountType.ScheduledBetween,
    categories: ['Month'],
  },
  excludedFilterTypes,
  filtersConfig: [
    {
      prefix: '',
      name: 'filters',
      type: CriteriaFilterType.MatchFilter,
    },
  ],
  criteriaConfig: {
    countBy: { name: 'cb', method: 'readInt' },
    includePlaceholderStudentCount: { name: 'ipsc', method: 'readBool' },
    dateCountType: { name: 'dct', method: 'readInt' },
    startDate: { name: 'sd', method: 'readDate' },
    endDate: { name: 'ed', method: 'readDate' },
    categories: { name: 'cat', method: 'readArray' },
  },
  templates: [
    {
      name: 'Count rotation applications',
      color: 'purple',
      criteria: {
        countBy: CountBy.ApplicationsForRotations,
        dateCountType: DateCountType.CreatedBetween,
      },
    },
    {
      name: 'Count students who applied for rotations',
      color: 'purple',
      criteria: {
        countBy: CountBy.StudentsWhoHaveAppliedForRotations,
        dateCountType: DateCountType.CreatedBetween,
      },
    },
    {
      name: 'Count accepted rotations',
      color: 'green',
      criteria: {
        countBy: CountBy.AcceptedRotations,
      },
    },
    {
      name: 'Count students with accepted rotations',
      color: 'green',
      criteria: {
        countBy: CountBy.StudentsWhoHaveAcceptedRotations,
      },
    },
    {
      name: 'Count closed rotations',
      color: 'red',
      criteria: {
        countBy: CountBy.ClosedRotations,
        dateCountType: DateCountType.ClosedBetween,
      },
    },
    {
      name: 'Count students with closed rotations',
      color: 'red',
      criteria: {
        countBy: CountBy.StudentsWhoHaveClosedRotations,
        dateCountType: DateCountType.ClosedBetween,
      },
    },
    {
      name: 'Count hours',
      icon: 'time',
      criteria: {
        countBy: CountBy.StudentHours,
        dateCountType: DateCountType.HoursBetween,
      },
    },
    // {
    //   icon: 'time',
    //   name: 'Count procedures logged',
    //   criteria: {
    //     countBy: CountBy.ProceduresLogged,
    //     dateCountType: DateCountType.ScheduledBetween
    //     // todo add LoggedBetween options?
    //     // date logged vs date they're logging it for?
    //   }
    // }
  ],

  waitFor: { method: 'POST', route: /api\/reports\/rotation-breakdown/i },
  // testClick: 'run-report-btn',
  testFor: ['chart'],
}
