{#if matches}
  {#if matches.length}
    <div>
      {#each matches as m}
        <MatchCard match={m} {showHide} href="{baseUrl}/matches/{m.matchId}" />
      {/each}
    </div>
  {:else}
    <p class="em small">{emptyMsg}</p>
  {/if}
{/if}

<script>
  import MatchCard from 'components/MatchCard.svelte'

  export let matches
  export let showPlaceholderStudents = false
  export let showHide = {
    location: true,
    service: true,
    opportunity: true,
    students: true,
    faculty: true,
    preceptors: true,
    placeholderStudents: showPlaceholderStudents,
  }
  export let baseUrl
  export let view = null
  export let emptyMsg = 'None'

  $: if (view != null) baseUrl = `/dashboard/${view.temp ? 'temp/' : ''}${view.slug}`
</script>
