{#if blockedByHealthSystem || blockedBySchool}
  <div class="flex-row flex-align-center g05" use:tip={tooltip}>
    <Icon name="alert-triangle" class={className} />
    <span data-test={isOtherOrgUnavailable ? 'capacity-guest-unavailable-message' : 'capacity-guest-blocked-message'}>Scheduling unavailable</span>
  </div>

  <div class="hide">
    <div class="text-left" bind:this={tooltipElem}>
      {#if isOtherOrgUnavailable}
        This {otherOrgLabelSingular}’s current settings don’t allow students to request rotations. Please contact the {otherOrgLabelSingular} if you believe
        this is incorrect.
      {:else}
        This {otherOrgLabelSingular} has been effectively blocked on the
        {#if canViewBlockAndAllowOrgsSettings}
          <Link to="/settings/block-and-allow-orgs" class="nowrap">Block & allow organizations</Link>
        {:else}
          <strong class="strongish nowrap">Block & allow organizations</strong>
        {/if}
        page and won’t be able to use this opportunity.
      {/if}
    </div>
  </div>
{/if}

<script>
  import { Link } from 'svelte-routing'
  import { Permission, PersonaType } from 'config/enums.js'
  import Icon from 'components/Icon.svelte'
  import persona from 'stores/persona.js'
  import personaService from 'services/persona-service.js'
  import tip from 'decorators/tip.js'

  let className = 'text-danger'
  export { className as class }
  export let tipOptions = {}
  // TODO(OMP): Message temporary disabled
  export const allowedByHealthSystem = false
  export const allowedBySchool = false

  let tooltipElem = null

  $: isHealth = $persona.personaType === PersonaType.ProviderStaff
  $: isSchool = $persona.personaType === PersonaType.SchoolStaff

  $: otherOrgLabelSingular = isHealth ? 'school' : 'healthcare organization'
  // TODO(OMP): Message temporary disabled
  $: blockedByHealthSystem = false // allowedByHealthSystem === false
  $: blockedBySchool = false // allowedBySchool === false
  $: isOtherOrgUnavailable = (isHealth && blockedBySchool) || (isSchool && blockedByHealthSystem)
  $: canViewBlockAndAllowOrgsSettings = personaService.hasStaffPermission(Permission.ViewBlockAndAllowOrgsSettings, $persona.orgId)

  $: tooltip = tooltipElem
    ? {
        content: tooltipElem,
        options: {
          placement: 'left',
          maxWidth: isOtherOrgUnavailable ? (isHealth ? 280 : 335) : isHealth ? 300 : 305,
          theme: 'light-gray-scrollable',
          interactive: canViewBlockAndAllowOrgsSettings,
          ...tipOptions,
        },
      }
    : null
</script>
