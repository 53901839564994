<div class="match-card-row flex-align-start">
  <div class="flex-row flex-align-center g1">
    <PlaceholderStudentProfilePic sm />
    <strong>{pluralCount('Placeholder student', placeholderStudentCount)}</strong>
  </div>
</div>

<script>
  import { pluralCount } from 'services/string-utils'
  import PlaceholderStudentProfilePic from 'components/PlaceholderStudentProfilePic.svelte'

  export let placeholderStudentCount
</script>
