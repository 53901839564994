// THIS IS A GENERATED FILE - DO NOT EDIT MANUALLY!
import validator from 'services/validator.js'
import { ComparisonType } from 'config/enums.js'

function encodePathWithOptionalParts(countRequiredParts) {
  return function encodePath(parts, ...args) {
    let path = ''
    const end = parts.length - 1
    for (let i = 0; i < end; i++) {
      if (args[i] == null) {
        if (i < countRequiredParts) {
          throw new Error(`Invalid encodePath args: ${JSON.stringify({ parts, args, countRequiredParts })}`)
        } else {
          return path + parts[i].replace(/\/$/, '')
        }
      }
      path += parts[i] + encodeURIComponent(args[i])
    }
    return path + parts[end]
  }
}

const encodePath = encodePathWithOptionalParts(Infinity)
const encodeKeyValue = (key, value) => key + '=' + encodeURIComponent(value)

// TODO: This class should store its data in a ServiceWorker, wrapping the promise with its own promise.
export class ApiCache {
  #ttlSeconds = -1
  #resetOnPersonaChange = true
  #entries = []

  static #instances = []
  static #intervalId = setInterval(ApiCache.removeAllExpired, 60 * 1000)

  constructor(ttlSeconds, resetOnPersonaChange) {
    this.#ttlSeconds = ttlSeconds
    this.#resetOnPersonaChange = resetOnPersonaChange
    ApiCache.#instances.push(this)
  }

  static resetAllCaches() {
    for (const instance of ApiCache.#instances) {
      instance.reset()
    }
  }

  static resetPersonaSensitiveCaches() {
    for (const instance of ApiCache.#instances) {
      instance.resetIfPersonaSensitive()
    }
  }

  static removeAllExpired() {
    for (const instance of ApiCache.#instances) {
      instance.removeExpired()
    }
  }

  // TODO: Make private when supported by Safari. https://caniuse.com/mdn-javascript_classes_private_class_methods
  entryIsActive(entry) {
    return entry != null && entry.ttl > new Date()
  }

  // TODO: Make private when supported by Safari. https://caniuse.com/mdn-javascript_classes_private_class_methods
  entryIsExpired(entry) {
    return entry != null && entry.ttl <= new Date()
  }

  // TODO: Make private when supported by Safari. https://caniuse.com/mdn-javascript_classes_private_class_methods
  findEntryByParams(pathParams, body, overrides) {
    const entry = this.#entries.find(e => validator.equals(pathParams, e.pathParams) && validator.equals(body, e.body) && validator.equals(overrides, e.overrides))
    if (this.entryIsExpired(entry)) {
      this.removeByParams(pathParams, body, overrides)
    }
    return entry
  }

  add(pathParams, body, overrides, promise) {
    const entry = this.findEntryByParams(pathParams, body, overrides)
    if (this.entryIsActive(entry)) throw new Error('ApiCache entry already exists!')
    const ttl = new Date()
    ttl.setSeconds(ttl.getSeconds() + this.#ttlSeconds)
    this.#entries.push({ pathParams, body, overrides, promise, ttl })
  }

  reset() {
    this.#entries = []
  }

  resetIfPersonaSensitive() {
    if (this.#resetOnPersonaChange) this.#entries = []
  }

  removeWhere(predicate) {
    this.#entries = this.#entries.filter(e => !predicate(e))
  }

  removeByParams(pathParams, body, overrides) {
    this.removeWhere(e => validator.equals(pathParams, e.pathParams) && validator.equals(body, e.body) && validator.equals(overrides, e.overrides))
  }

  removeExpired() {
    this.removeWhere(this.entryIsExpired)
  }

  tryGet(pathParams, body, overrides) {
    const entry = this.findEntryByParams(pathParams, body, overrides)
    return this.entryIsExpired(entry) ? null : entry?.promise
  }
}


export class Api {
  fetch(uri, httpMethod = 'GET', query, body, overrides = {}) {
    const options = {
      method: httpMethod,
      mode: 'cors',
      credentials: 'include',
      ...overrides,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...overrides.headers
      },
    }
    if (options.isFileUpload) {
      delete options.isFileUpload
      delete options.headers['Content-Type'] // have to omit content-type for file uploads with fetch, browser will set it
      options.body = body
    } else if (httpMethod != 'GET') {
      options.body = JSON.stringify(body)
    }
    const url = this.buildUrl(uri, query)
    return this.fetcher ? this.fetcher(url, options) : fetch(url, options)
  }

  buildUrl(uri, query) {
    const result = this.baseUrl + uri
    if (query != null) {
      const keys = Object.keys(query).filter(p => query[p] != null)
      if (keys.length) {
        return result + '?' + keys.flatMap(key => {
          const value = query[key]
          return Array.isArray(value)
            ? value.map(arrayItem => encodeKeyValue(key, arrayItem))
            : encodeKeyValue(key, value)
        }).join('&')
      }
    }
    return result
  }

  executeClearApiCacheInstructions(message) {
    if (!Array.isArray(message?.instructions)) return
    for (const instruction of message.instructions) {
      const cache = _.get(this, `${instruction.controllerName}.${instruction.actionName}.cache`) ?? _.get(this, `${instruction.controllerName}.${instruction.actionName.replace(/async$/i, '')}.cache`)
      if (!cache) continue
      const predicates = instruction.andedPredicates
      if (predicates?.length)
        cache.removeWhere(e =>
          predicates.every(p => {
            const eValue = _.get(e, p.pathFromCacheEntry)
            switch (p.comparisonType) {
              case ComparisonType.Is:
                return eValue == p.value
              case ComparisonType.IsNot:
                return eValue != p.value
              case ComparisonType.Contains:
                return typeof eValue?.includes === 'function' && eValue.includes(p.value)
              case ComparisonType.DoesNotContain:
                return typeof eValue?.includes === 'function' && !eValue.includes(p.value)
              case ComparisonType.ContainsAny:
                return typeof eValue?.some === 'function' && eValue.some(eSubValue => p.value.includes(eSubValue))
              case ComparisonType.DoesNotContainAny:
                return typeof eValue?.some === 'function' && !eValue.some(eSubValue => p.value.includes(eSubValue))
              case ComparisonType.IsIncludedIn:
                return typeof p.value?.includes === 'function' && p.value.includes(eValue)
              case ComparisonType.IsNotIncludedIn:
                return typeof p.value?.includes === 'function' && !p.value.includes(eValue)
              case ComparisonType.GreaterThan:
                return eValue > p.value
              case ComparisonType.LessThan:
                return eValue < p.value
              case ComparisonType.IsBefore:
                return dayjs(eValue).isBefore(dayjs(p.value))
              case ComparisonType.IsAfter:
                return dayjs(eValue).isAfter(dayjs(p.value))
              case ComparisonType.IsNotEmpty:
                return !validator.empty(eValue)
              case ComparisonType.IsEmpty:
                return validator.empty(eValue)
              default:
                return true
            }
          })
        )
      else cache.reset()
    }
  }

  /**
   * @param {string} baseUrl - if a domain other than current, pass it here
   * @param {function} fetcher Optional - a custom method to be used in place of fetch(url, options)
   */
  constructor(baseUrl = '', fetcher = null) {
    this.baseUrl = baseUrl
    this.fetcher = fetcher
    this.noMonitor = Object.freeze({ monitor: false })
    const requestIds = new Int32Array(10)
    let requestIdIndex = requestIds.length
    this.generateRequestId = () => {
      if (requestIdIndex >= requestIds.length) {
        crypto.getRandomValues(requestIds)
        requestIdIndex = 0
      }
      return requestIds[requestIdIndex++]
    }

    this.account = {
      api: this,

      /**
       * account.addEmail
       * POST /api/account/emails
       * @param {object} body `AddEmailViewModel` Required
       * @param {string} body.email Required
       * @param {string} body.emailConfirm Optional
       * @param {string} body.password Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addEmail(body, options = {}) {
        const uri = '/api/account/emails'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * account.changeEmail
       * PUT /api/account/emails/{userEmailId}
       * @param {object} parameters
       * @param {number} parameters.userEmailId Required
       * @param {object} body `ChangeEmailViewModel` Required
       * @param {number} body.userEmailId Optional
       * @param {string} body.email Required
       * @param {string} body.emailConfirm Optional
       * @param {string} body.password Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      changeEmail({ userEmailId } = {}, body, options = {}) {
        const uri = encodePath`/api/account/emails/${userEmailId}`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * account.changePassword
       * PUT /api/account/password
       * @param {object} body `ChangePasswordViewModel` Required
       * @param {string} body.currentPassword Required
       * @param {string} body.newPassword Required
       * @param {string} body.newPasswordConfirm Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      changePassword(body, options = {}) {
        const uri = '/api/account/password'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * account.externalLoginCallback
       * GET /account/externallogin
       * @param {object} parameters
       * @param {string} parameters.provider Optional
       * @param {string} parameters.remoteError Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      externalLoginCallback({ provider, remoteError } = {}, options = {}) {
        const uri = '/account/externallogin'
        return this.api.fetch(uri, 'GET', { provider, remoteError }, null, options)
      },

      /**
       * account.forgotPassword
       * POST /api/account/forgot-password
       * @param {object} body `ForgotPasswordViewModel` Required
       * @param {string} body.email Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      forgotPassword(body, options = {}) {
        const uri = '/api/account/forgot-password'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * account.forgotPasswordByUserId
       * POST /api/account/forgot-password/by-user-id
       * @param {string} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      forgotPasswordByUserId(body, options = {}) {
        const uri = '/api/account/forgot-password/by-user-id'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * account.login
       * POST /api/account/login
       * @param {object} body `LoginViewModel` Required
       * @param {string} body.email Required
       * @param {string} body.password Required
       * @param {boolean} body.rememberMe Optional
       * @param {object} body.captchaResponse `UserCaptchaResponse` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      login(body, options = {}) {
        const uri = '/api/account/login'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * account.redirectToExternalLoginProvider
       * GET /api/account/start-sso
       * @param {object} parameters
       * @param {string} parameters.provider Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      redirectToExternalLoginProvider({ provider } = {}, options = {}) {
        const uri = '/api/account/start-sso'
        return this.api.fetch(uri, 'GET', { provider }, null, options)
      },

      /**
       * account.register
       * POST /api/account/register
       * @param {object} body `RegisterViewModel` Required
       * @param {string} body.password Required
       * @param {string} body.confirmPassword Optional
       * @param {object} body.captchaResponse `UserCaptchaResponse` Optional
       * @param {string} body.referralId Optional
       * @param {boolean} body.acceptEula Optional
       * @param {string} body.name Optional
       * @param {string} body.legalFirstName Required
       * @param {string} body.legalLastName Required
       * @param {string} body.email Required
       * @param {string} body.link Optional
       * @param {string} body.discipline Optional
       * @param {string} body.otherDiscipline Optional
       * @param {number} body.studentYear Optional
       * @param {string} body.studentGraduationDate Optional
       * @param {string} body.hearAboutUs Optional
       * @param {string} body.customMessage Optional
       * @param {string} body.orgName Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      register(body, options = {}) {
        const uri = '/api/account/register'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * account.removeEmail
       * DELETE /api/account/emails/{userEmailId}
       * @param {object} parameters
       * @param {number} parameters.userEmailId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      removeEmail({ userEmailId } = {}, options = {}) {
        const uri = encodePath`/api/account/emails/${userEmailId}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },

      /**
       * account.resendEmailConfirmation
       * POST /api/account/resend-email-confirmation
       * @param {string} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      resendEmailConfirmation(body, options = {}) {
        const uri = '/api/account/resend-email-confirmation'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * account.resendEmailConfirmationAnonymous
       * POST /api/account/resend-email-confirmation-anonymous
       * @param {string} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      resendEmailConfirmationAnonymous(body, options = {}) {
        const uri = '/api/account/resend-email-confirmation-anonymous'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * account.resendInvite
       * PUT /api/account/resend-invite
       * @param {string} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      resendInvite(body, options = {}) {
        const uri = '/api/account/resend-invite'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * account.resetPassword
       * POST /api/account/reset-password
       * @param {object} body `ResetPasswordViewModel` Required
       * @param {string} body.email Required
       * @param {string} body.password Required
       * @param {string} body.confirmPassword Optional
       * @param {string} body.token Optional
       * @param {string} body.resetReason Optional
       * @param {object} body.passwordSettings `PasswordSettings` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      resetPassword(body, options = {}) {
        const uri = '/api/account/reset-password'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * account.resetPasswordOnBehalf
       * PUT /api/account/{userId}/reset-password-on-behalf
       * @param {object} parameters
       * @param {string} parameters.userId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      resetPasswordOnBehalf({ userId } = {}, options = {}) {
        const uri = encodePath`/api/account/${userId}/reset-password-on-behalf`
        return this.api.fetch(uri, 'PUT', null, null, options)
      },

      /**
       * account.sendCodeNewPhone
       * POST /api/account/two-factor/sms
       * @param {object} body `AddPhoneSendTokenViewModel` Required
       * @param {string} body.phone Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      sendCodeNewPhone(body, options = {}) {
        const uri = '/api/account/two-factor/sms'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * account.setPrimaryEmail
       * PUT /api/account/emails/primary
       * @param {string} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      setPrimaryEmail(body, options = {}) {
        const uri = '/api/account/emails/primary'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * account.twoFactorIsEnabled
       * GET /api/account/two-factor-is-enabled/{email}
       * @param {object} parameters
       * @param {string} parameters.email Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      twoFactorIsEnabled({ email } = {}, options = {}) {
        const uri = encodePath`/api/account/two-factor-is-enabled/${email}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * account.verifyCode
       * POST /api/account/two-factor/verify
       * @param {object} body `VerifyTwoFactorViewModel` Required
       * @param {string} body.token Required
       * @param {object} body.twoFactorMethod `TwoFactorMethod` Optional
       * @param {boolean} body.rememberClient Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      verifyCode(body, options = {}) {
        const uri = '/api/account/two-factor/verify'
        return this.api.fetch(uri, 'POST', null, body, options)
      },
    }

    this.agreement = {
      api: this,

      /**
       * agreement.accept
       * PUT /api/agreements/{orgId}/{agreementId}/accept
       * @param {object} parameters
       * @param {number} parameters.agreementId Required
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      accept({ agreementId, orgId } = {}, options = {}) {
        const uri = encodePath`/api/agreements/${orgId}/${agreementId}/accept`
        return this.api.fetch(uri, 'PUT', null, null, options)
      },

      /**
       * agreement.add
       * POST /api/agreements
       * @param {object} body `SaveAgreementRequest` Required
       * @param {array} body.serviceIds Optional
       * @param {array} body.disciplines Optional
       * @param {number} body.agreementId Optional
       * @param {string} body.name Optional
       * @param {string} body.description Optional
       * @param {string} body.changeComment Optional
       * @param {string} body.startDate Optional
       * @param {string} body.endDate Optional
       * @param {number} body.orgId Optional
       * @param {number} body.teamId Optional
       * @param {number} body.schoolId Optional
       * @param {number} body.schoolTeamId Optional
       * @param {number} body.creatorOrgId Optional
       * @param {boolean} body.submitted Optional
       * @param {boolean} body.providerAccepted Optional
       * @param {boolean} body.schoolAccepted Optional
       * @param {boolean} body.providerClosed Optional
       * @param {boolean} body.schoolClosed Optional
       * @param {array} body.expirationReminders Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      add(body, options = {}) {
        const uri = '/api/agreements'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * agreement.addComment
       * POST /api/agreements/{agreementId}/add-comment
       * @param {object} parameters
       * @param {number} parameters.agreementId Required
       * @param {object} body `SaveCommentViewModel` Required
       * @param {string} body.message Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addComment({ agreementId } = {}, body, options = {}) {
        const uri = encodePath`/api/agreements/${agreementId}/add-comment`
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * agreement.addFiles
       * POST /api/agreements/{orgId}/{agreementId}/files
       * @param {object} parameters
       * @param {number} parameters.agreementId Required
       * @param {number} parameters.orgId Required
       * @param {object} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addFiles({ agreementId, orgId } = {}, body, options = {}) {
        const uri = encodePath`/api/agreements/${orgId}/${agreementId}/files`
        options = { ...options, isFileUpload: true }
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * agreement.change
       * PUT /api/agreements/{agreementId}
       * @param {object} parameters
       * @param {number} parameters.agreementId Required
       * @param {object} body `SaveAgreementRequest` Required
       * @param {array} body.serviceIds Optional
       * @param {array} body.disciplines Optional
       * @param {number} body.agreementId Optional
       * @param {string} body.name Optional
       * @param {string} body.description Optional
       * @param {string} body.changeComment Optional
       * @param {string} body.startDate Optional
       * @param {string} body.endDate Optional
       * @param {number} body.orgId Optional
       * @param {number} body.teamId Optional
       * @param {number} body.schoolId Optional
       * @param {number} body.schoolTeamId Optional
       * @param {number} body.creatorOrgId Optional
       * @param {boolean} body.submitted Optional
       * @param {boolean} body.providerAccepted Optional
       * @param {boolean} body.schoolAccepted Optional
       * @param {boolean} body.providerClosed Optional
       * @param {boolean} body.schoolClosed Optional
       * @param {array} body.expirationReminders Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      change({ agreementId } = {}, body, options = {}) {
        const uri = encodePath`/api/agreements/${agreementId}`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * agreement.close
       * PUT /api/agreements/{orgId}/{agreementId}/close
       * @param {object} parameters
       * @param {number} parameters.agreementId Required
       * @param {number} parameters.orgId Required
       * @param {object} body `CloseAgreementRequest` Required
       * @param {string} body.reason Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      close({ agreementId, orgId } = {}, body, options = {}) {
        const uri = encodePath`/api/agreements/${orgId}/${agreementId}/close`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * agreement.deleteFile
       * DELETE /api/agreements/{orgId}/{agreementId}/files/{agreementFileId}
       * @param {object} parameters
       * @param {number} parameters.agreementFileId Required
       * @param {number} parameters.agreementId Required
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      deleteFile({ agreementFileId, agreementId, orgId } = {}, options = {}) {
        const uri = encodePath`/api/agreements/${orgId}/${agreementId}/files/${agreementFileId}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },

      /**
       * agreement.export
       * POST /api/agreements/export
       * @param {object} parameters
       * @param {boolean} parameters.excludeTotalCount Optional
       * @param {array} parameters.filters `FilterParameter` Optional
       * @param {object} parameters.filters.type `FilterType` Required
       * @param {array} parameters.filters.config `JToken` Optional
       * @param {boolean} parameters.includeAddresses Optional
       * @param {boolean} parameters.includeCapacities Optional
       * @param {boolean} parameters.includeDisciplines Optional
       * @param {boolean} parameters.includeOrgAndTeam Optional
       * @param {boolean} parameters.includeServices Optional
       * @param {string} parameters.search Optional
       * @param {boolean} parameters.sortAscending Optional
       * @param {object} parameters.sortProperty `AgreementListProperty` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      export({ excludeTotalCount, filters, includeAddresses, includeCapacities, includeDisciplines, includeOrgAndTeam, includeServices, search, sortAscending, sortProperty } = {}, options = {}) {
        const uri = '/api/agreements/export'
        return this.api.fetch(uri, 'POST', { excludeTotalCount, filters, includeAddresses, includeCapacities, includeDisciplines, includeOrgAndTeam, includeServices, search, sortAscending, sortProperty }, null, options)
      },

      /**
       * agreement.get
       * GET /api/agreements/{orgId}/{agreementId}
       * @param {object} parameters
       * @param {number} parameters.agreementId Required
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      get({ agreementId, orgId } = {}, options = {}) {
        const uri = encodePath`/api/agreements/${orgId}/${agreementId}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * agreement.getActivityByAgreementId
       * GET /api/agreements/{agreementId}/activity
       * @param {object} parameters
       * @param {number} parameters.agreementId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getActivityByAgreementId({ agreementId } = {}, options = {}) {
        const uri = encodePath`/api/agreements/${agreementId}/activity`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * agreement.list
       * POST /api/agreements/list
       * @param {object} body `ListAgreementsRequestBody` Required
       * @param {number} body.orgId Optional
       * @param {boolean} body.includeAddresses Optional
       * @param {boolean} body.includeCapacities Optional
       * @param {boolean} body.includeOrgAndTeam Optional
       * @param {boolean} body.includeServices Optional
       * @param {boolean} body.includeDisciplines Optional
       * @param {boolean} body.excludeTotalCount Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {array} body.selectedAgreementIds Optional
       * @param {object} body.sortProperty `AgreementListProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {string} body.search Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      list(body, options = {}) {
        const uri = '/api/agreements/list'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * agreement.listCn
       * POST /api/agreements/list-cn
       * @param {object} body `ListAgreementsRequestBody` Required
       * @param {number} body.orgId Optional
       * @param {boolean} body.includeAddresses Optional
       * @param {boolean} body.includeCapacities Optional
       * @param {boolean} body.includeOrgAndTeam Optional
       * @param {boolean} body.includeServices Optional
       * @param {boolean} body.includeDisciplines Optional
       * @param {boolean} body.excludeTotalCount Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {array} body.selectedAgreementIds Optional
       * @param {object} body.sortProperty `AgreementListProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {string} body.search Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listCn(body, options = {}) {
        const uri = '/api/agreements/list-cn'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * agreement.listConnectedOrgs
       * GET /api/agreements/orgs
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listConnectedOrgs(options = {}) {
        const uri = '/api/agreements/orgs'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * agreement.listConnectedSchools
       * GET /api/agreements/schools
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listConnectedSchools(options = {}) {
        const uri = '/api/agreements/schools'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * agreement.proposeChanges
       * PUT /api/agreements/{agreementId}/propose-change
       * @param {object} parameters
       * @param {number} parameters.agreementId Required
       * @param {object} body `SaveAgreementRequest` Required
       * @param {array} body.serviceIds Optional
       * @param {array} body.disciplines Optional
       * @param {number} body.agreementId Optional
       * @param {string} body.name Optional
       * @param {string} body.description Optional
       * @param {string} body.changeComment Optional
       * @param {string} body.startDate Optional
       * @param {string} body.endDate Optional
       * @param {number} body.orgId Optional
       * @param {number} body.teamId Optional
       * @param {number} body.schoolId Optional
       * @param {number} body.schoolTeamId Optional
       * @param {number} body.creatorOrgId Optional
       * @param {boolean} body.submitted Optional
       * @param {boolean} body.providerAccepted Optional
       * @param {boolean} body.schoolAccepted Optional
       * @param {boolean} body.providerClosed Optional
       * @param {boolean} body.schoolClosed Optional
       * @param {array} body.expirationReminders Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      proposeChanges({ agreementId } = {}, body, options = {}) {
        const uri = encodePath`/api/agreements/${agreementId}/propose-change`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * agreement.submit
       * PUT /api/agreements/{orgId}/submit
       * @param {object} parameters
       * @param {number} parameters.agreementId Optional
       * @param {number} parameters.orgId Required
       * @param {object} body `SaveAgreementRequest` Required
       * @param {array} body.serviceIds Optional
       * @param {array} body.disciplines Optional
       * @param {number} body.agreementId Optional
       * @param {string} body.name Optional
       * @param {string} body.description Optional
       * @param {string} body.changeComment Optional
       * @param {string} body.startDate Optional
       * @param {string} body.endDate Optional
       * @param {number} body.orgId Optional
       * @param {number} body.teamId Optional
       * @param {number} body.schoolId Optional
       * @param {number} body.schoolTeamId Optional
       * @param {number} body.creatorOrgId Optional
       * @param {boolean} body.submitted Optional
       * @param {boolean} body.providerAccepted Optional
       * @param {boolean} body.schoolAccepted Optional
       * @param {boolean} body.providerClosed Optional
       * @param {boolean} body.schoolClosed Optional
       * @param {array} body.expirationReminders Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      submit({ agreementId, orgId } = {}, body, options = {}) {
        const uri = encodePath`/api/agreements/${orgId}/submit`
        return this.api.fetch(uri, 'PUT', { agreementId }, body, options)
      },
    }

    this.agreementFilterOptions = {
      api: this,

      /**
       * agreementFilterOptions.getDisciplines
       * GET /api/filter-options/for-agreements/disciplines
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getDisciplines(options = {}) {
        const uri = '/api/filter-options/for-agreements/disciplines'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * agreementFilterOptions.getOrgs
       * GET /api/filter-options/for-agreements/orgs
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getOrgs(options = {}) {
        const uri = '/api/filter-options/for-agreements/orgs'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * agreementFilterOptions.getSchools
       * GET /api/filter-options/for-agreements/schools
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getSchools(options = {}) {
        const uri = '/api/filter-options/for-agreements/schools'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * agreementFilterOptions.getTeams
       * GET /api/filter-options/for-agreements/teams
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getTeams(options = {}) {
        const uri = '/api/filter-options/for-agreements/teams'
        return this.api.fetch(uri, 'GET', null, null, options)
      },
    }

    this.apiMeta = {
      api: this,

      /**
       * apiMeta.getApiInformation
       * GET /api/meta/information
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getApiInformation(options = {}) {
        const uri = '/api/meta/information'
        return this.api.fetch(uri, 'GET', null, null, options)
      },
    }

    this.apiUser = {
      api: this,

      /**
       * apiUser.create
       * POST /api/api-users
       * @param {object} body `UpsertApiUserViewModel` Required
       * @param {string} body.name Required
       * @param {string} body.expireDate Required
       * @param {number} body.orgId Optional
       * @param {number} body.teamId Optional
       * @param {object} body.personaType `PersonaType` Optional
       * @param {array} body.staffRoles `StaffRole` Optional
       * @param {array} body.accessRights `Permission` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      create(body, options = {}) {
        const uri = '/api/api-users'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * apiUser.createApiKey
       * POST /api/api-users/create-apiKey
       * @param {object} parameters
       * @param {number} parameters.clientId Optional
       * @param {string} parameters.expireDate Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      createApiKey({ clientId, expireDate } = {}, options = {}) {
        const uri = '/api/api-users/create-apiKey'
        return this.api.fetch(uri, 'POST', { clientId, expireDate }, null, options)
      },

      /**
       * apiUser.expireApiKey
       * PUT /api/api-users/expire-apiKey
       * @param {object} parameters
       * @param {number} parameters.apiKeyId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      expireApiKey({ apiKeyId } = {}, options = {}) {
        const uri = '/api/api-users/expire-apiKey'
        return this.api.fetch(uri, 'PUT', { apiKeyId }, null, options)
      },

      /**
       * apiUser.getAll
       * GET /api/api-users
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getAll(options = {}) {
        const uri = '/api/api-users'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * apiUser.getAllowedPermissions
       * GET /api/api-users/allowed-permissions
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getAllowedPermissions(options = {}) {
        const uri = '/api/api-users/allowed-permissions'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * apiUser.softDelete
       * DELETE /api/api-users/{clientId}
       * @param {object} parameters
       * @param {number} parameters.clientId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      softDelete({ clientId } = {}, options = {}) {
        const uri = encodePath`/api/api-users/${clientId}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },

      /**
       * apiUser.update
       * PUT /api/api-users/{clientId}
       * @param {object} parameters
       * @param {number} parameters.clientId Required
       * @param {object} body `UpsertApiUserViewModel` Required
       * @param {string} body.name Required
       * @param {string} body.expireDate Required
       * @param {number} body.orgId Optional
       * @param {number} body.teamId Optional
       * @param {object} body.personaType `PersonaType` Optional
       * @param {array} body.staffRoles `StaffRole` Optional
       * @param {array} body.accessRights `Permission` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      update({ clientId } = {}, body, options = {}) {
        const uri = encodePath`/api/api-users/${clientId}`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.applicationInsights = {
      api: this,

      /**
       * applicationInsights.testInvalidException
       * GET /api/app-insights/invalid-exception
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      testInvalidException(options = {}) {
        const uri = '/api/app-insights/invalid-exception'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * applicationInsights.testSqlException
       * GET /api/app-insights/sql-exception/{invalidTableNameId}
       * @param {object} parameters
       * @param {number} parameters.id Optional
       * @param {number} parameters.invalidTableNameId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      testSqlException({ id, invalidTableNameId } = {}, options = {}) {
        const uri = encodePath`/api/app-insights/sql-exception/${invalidTableNameId}`
        return this.api.fetch(uri, 'GET', { id }, null, options)
      },

      /**
       * applicationInsights.testStatusCode
       * GET /api/app-insights/respond-with-{statusCode}
       * @param {object} parameters
       * @param {number} parameters.statusCode Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      testStatusCode({ statusCode } = {}, options = {}) {
        const uri = encodePath`/api/app-insights/respond-with-${statusCode}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * applicationInsights.testTimeout
       * GET /api/app-insights/timeout/{ms}
       * @param {object} parameters
       * @param {number} parameters.ms Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      testTimeout({ ms } = {}, options = {}) {
        const uri = encodePath`/api/app-insights/timeout/${ms}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * applicationInsights.testUnauthorized
       * GET /api/app-insights/unauthed
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      testUnauthorized(options = {}) {
        const uri = '/api/app-insights/unauthed'
        return this.api.fetch(uri, 'GET', null, null, options)
      },
    }

    this.bulkMatch = {
      api: this,

      /**
       * bulkMatch.changeCourse
       * PUT /api/matches/bulk/change-course
       * @param {object} body `BulkParametersMatch<ChangeCourseParameters>` Required
       * @param {object} body.filterParameters `SortableFilterablePaginationParameters<MatchListSortProperty>` Optional
       * @param {object} body.actionParameters `ChangeCourseParameters` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      changeCourse(body, options = {}) {
        const uri = '/api/matches/bulk/change-course'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * bulkMatch.changeDates
       * PUT /api/matches/bulk/change-dates
       * @param {object} body `BulkParametersMatch<ChangeDatesParameters>` Required
       * @param {object} body.filterParameters `SortableFilterablePaginationParameters<MatchListSortProperty>` Optional
       * @param {object} body.actionParameters `ChangeDatesParameters` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      changeDates(body, options = {}) {
        const uri = '/api/matches/bulk/change-dates'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * bulkMatch.close
       * PUT /api/matches/bulk/close
       * @param {object} body `BulkParametersMatch<CloseParameters>` Required
       * @param {object} body.filterParameters `SortableFilterablePaginationParameters<MatchListSortProperty>` Optional
       * @param {object} body.actionParameters `CloseParameters` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      close(body, options = {}) {
        const uri = '/api/matches/bulk/close'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * bulkMatch.comment
       * POST /api/matches/bulk/comment
       * @param {object} body `BulkParametersMatch<CommentParameters>` Required
       * @param {object} body.filterParameters `SortableFilterablePaginationParameters<MatchListSortProperty>` Optional
       * @param {object} body.actionParameters `CommentParameters` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      comment(body, options = {}) {
        const uri = '/api/matches/bulk/comment'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * bulkMatch.email
       * POST /api/matches/bulk/email
       * @param {object} body `BulkParametersMatch<EmailParametersMatch>` Required
       * @param {object} body.filterParameters `SortableFilterablePaginationParameters<MatchListSortProperty>` Optional
       * @param {object} body.actionParameters `EmailParametersMatch` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      email(body, options = {}) {
        const uri = '/api/matches/bulk/email'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * bulkMatch.emailReplacements
       * POST /api/matches/bulk/email-replacements
       * @param {object} body `BulkParametersMatch<EmailReplacementsParameters>` Required
       * @param {object} body.filterParameters `SortableFilterablePaginationParameters<MatchListSortProperty>` Optional
       * @param {object} body.actionParameters `EmailReplacementsParameters` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      emailReplacements(body, options = {}) {
        const uri = '/api/matches/bulk/email-replacements'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * bulkMatch.extendOnboardingDeadline
       * PUT /api/matches/bulk/extend-onboarding-deadline
       * @param {object} body `BulkParametersMatch<ExtendOnboardingDeadlineParameters>` Required
       * @param {object} body.filterParameters `SortableFilterablePaginationParameters<MatchListSortProperty>` Optional
       * @param {object} body.actionParameters `ExtendOnboardingDeadlineParameters` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      extendOnboardingDeadline(body, options = {}) {
        const uri = '/api/matches/bulk/extend-onboarding-deadline'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * bulkMatch.manageCustomTags
       * PUT /api/matches/bulk/manage-custom-tags
       * @param {object} body `BulkParametersMatch<ManageCustomTagsParameters>` Required
       * @param {object} body.filterParameters `SortableFilterablePaginationParameters<MatchListSortProperty>` Optional
       * @param {object} body.actionParameters `ManageCustomTagsParameters` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      manageCustomTags(body, options = {}) {
        const uri = '/api/matches/bulk/manage-custom-tags'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * bulkMatch.pause
       * PUT /api/matches/bulk/pause
       * @param {object} body `BulkParametersMatch<PauseParameters>` Required
       * @param {object} body.filterParameters `SortableFilterablePaginationParameters<MatchListSortProperty>` Optional
       * @param {object} body.actionParameters `PauseParameters` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      pause(body, options = {}) {
        const uri = '/api/matches/bulk/pause'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * bulkMatch.reopen
       * PUT /api/matches/bulk/reopen
       * @param {object} body `BulkParametersMatch<ReopenParameters>` Required
       * @param {object} body.filterParameters `SortableFilterablePaginationParameters<MatchListSortProperty>` Optional
       * @param {object} body.actionParameters `ReopenParameters` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      reopen(body, options = {}) {
        const uri = '/api/matches/bulk/reopen'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.cacheTask = {
      api: this,

      /**
       * cacheTask.clearErrors
       * PUT /api/cache-task/queue/clear-errors
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      clearErrors(options = {}) {
        const uri = '/api/cache-task/queue/clear-errors'
        return this.api.fetch(uri, 'PUT', null, null, options)
      },

      /**
       * cacheTask.getCacheTaskQueueStats
       * GET /api/cache-task/queue/stats
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getCacheTaskQueueStats(options = {}) {
        const uri = '/api/cache-task/queue/stats'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * cacheTask.getCacheTaskTypeConfigs
       * GET /api/cache-task/type-configs
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getCacheTaskTypeConfigs(options = {}) {
        const uri = '/api/cache-task/type-configs'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * cacheTask.removeDuplicates
       * PUT /api/cache-task/queue/remove-duplicates
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      removeDuplicates(options = {}) {
        const uri = '/api/cache-task/queue/remove-duplicates'
        return this.api.fetch(uri, 'PUT', null, null, options)
      },

      /**
       * cacheTask.resetAttempts
       * PUT /api/cache-task/queue/reset-attempts
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      resetAttempts(options = {}) {
        const uri = '/api/cache-task/queue/reset-attempts'
        return this.api.fetch(uri, 'PUT', null, null, options)
      },

      /**
       * cacheTask.saveCacheTaskTypeConfigs
       * PUT /api/cache-task/type-configs
       * @param {object} body `SaveCacheTaskTypeConfigsRequest` Required
       * @param {array} body.cacheTaskTypeConfigs `CacheTaskTypeConfig` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      saveCacheTaskTypeConfigs(body, options = {}) {
        const uri = '/api/cache-task/type-configs'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * cacheTask.setCacheTaskTypeConfigsShouldProcess
       * PUT /api/cache-task/type-configs/set-should-process
       * @param {object} parameters
       * @param {boolean} parameters.shouldProcess Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      setCacheTaskTypeConfigsShouldProcess({ shouldProcess } = {}, options = {}) {
        const uri = '/api/cache-task/type-configs/set-should-process'
        return this.api.fetch(uri, 'PUT', { shouldProcess }, null, options)
      },

      /**
       * cacheTask.setTaskQueueShouldSkip
       * PUT /api/cache-task/queue/set-should-skip
       * @param {object} parameters
       * @param {boolean} parameters.shouldSkip Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      setTaskQueueShouldSkip({ shouldSkip } = {}, options = {}) {
        const uri = '/api/cache-task/queue/set-should-skip'
        return this.api.fetch(uri, 'PUT', { shouldSkip }, null, options)
      },
    }

    this.capacity = {
      api: this,

      /**
       * capacity.add
       * POST /api/capacity
       * @param {object} body `AddCapacityRequest` Required
       * @param {number} body.orgId Optional
       * @param {array} body.shifts `SaveShiftRequest` Optional
       * @param {array} body.locationIds Optional
       * @param {array} body.guests `CapacityGuestRequest` Optional
       * @param {array} body.staff `CapacityStaffRequest` Optional
       * @param {array} body.dateExceptions Optional
       * @param {number} body.serviceId Optional
       * @param {string} body.name Optional
       * @param {string} body.description Optional
       * @param {number} body.teamId Optional
       * @param {boolean} body.postToSearchPage Optional
       * @param {boolean} body.allowCoordinatorScheduling Optional
       * @param {string} body.startDate Optional
       * @param {string} body.endDate Optional
       * @param {number} body.matchLeadTimeDays Optional
       * @param {boolean} body.openForScheduling Optional
       * @param {boolean} body.allowStudentScheduling Optional
       * @param {boolean} body.allowStudentChanges Optional
       * @param {boolean} body.allowGroups Optional
       * @param {number} body.maxMatches Optional
       * @param {array} body.rotationTypes Optional
       * @param {array} body.specialties Optional
       * @param {array} body.disciplines Optional
       * @param {array} body.studentYears Optional
       * @param {array} body.teamDisciplines Optional
       * @param {object} body.requestAvailabilityPolicy `RequestAvailabilityPolicy` Required
       * @param {boolean} body.allowHostCoordinatorScheduling Optional
       * @param {boolean} body.allowGuestCoordinatorScheduling Optional
       * @param {boolean} body.allowStudentsToLeaveGroupMatch Optional
       * @param {object} body.noNewMatchesForDayWhenOthersHaveBeen `MatchHasBeen` Optional
       * @param {string} body.dateCreated Required
       * @param {string} body.dateModified Required
       * @param {object} body.shouldSerialize `ShouldSerializeProps` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      add(body, options = {}) {
        const uri = '/api/capacity'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * capacity.addComment
       * POST /api/capacity/{capacityId}/comments
       * @param {object} parameters
       * @param {number} parameters.capacityId Required
       * @param {object} body `SaveCommentViewModel` Required
       * @param {string} body.message Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addComment({ capacityId } = {}, body, options = {}) {
        const uri = encodePath`/api/capacity/${capacityId}/comments`
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * capacity.canDelete
       * GET /api/capacity/{capacityId}/can-delete
       * @param {object} parameters
       * @param {number} parameters.capacityId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      canDelete({ capacityId } = {}, options = {}) {
        const uri = encodePath`/api/capacity/${capacityId}/can-delete`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * capacity.confirmMatches
       * PUT /api/capacity/{capacityId}/{guestOrgId}/confirm-matches
       * @param {object} parameters
       * @param {number} parameters.capacityId Required
       * @param {number} parameters.guestOrgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      confirmMatches({ capacityId, guestOrgId } = {}, options = {}) {
        const uri = encodePath`/api/capacity/${capacityId}/${guestOrgId}/confirm-matches`
        return this.api.fetch(uri, 'PUT', null, null, options)
      },

      /**
       * capacity.export
       * POST /api/capacity/export
       * @param {object} body `ExportCapacitiesRequestBody` Required
       * @param {number} body.capacityId Optional
       * @param {boolean} body.includeAddresses Optional
       * @param {boolean} body.includeAgreements Optional
       * @param {boolean} body.includeLocations Optional
       * @param {boolean} body.includeOrgAndTeam Optional
       * @param {boolean} body.includeServices Optional
       * @param {boolean} body.includeGuests Optional
       * @param {boolean} body.includeShifts Optional
       * @param {boolean} body.includeShiftsWithDeletedLocations Optional
       * @param {boolean} body.includeStatusCounts Optional
       * @param {boolean} body.includeTags Optional
       * @param {boolean} body.includeDateExceptions Optional
       * @param {boolean} body.includeValidationData Optional
       * @param {boolean} body.includeMatches Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `CapacityListProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      export(body, options = {}) {
        const uri = '/api/capacity/export'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * capacity.get
       * GET /api/capacity/{capacityId}
       * @param {object} parameters
       * @param {number} parameters.capacityId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      get({ capacityId } = {}, options = {}) {
        const uri = encodePath`/api/capacity/${capacityId}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * capacity.getAllocation
       * GET /api/capacity/{capacityId}/schedule
       * @param {object} parameters
       * @param {number} parameters.capacityId Required
       * @param {string} parameters.endDate Optional
       * @param {number} parameters.excludeMatchId Optional
       * @param {number} parameters.shiftId Optional
       * @param {string} parameters.startDate Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getAllocation({ capacityId, endDate, excludeMatchId, shiftId, startDate } = {}, options = {}) {
        const uri = encodePath`/api/capacity/${capacityId}/schedule`
        return this.api.fetch(uri, 'GET', { endDate, excludeMatchId, shiftId, startDate }, null, options)
      },

      /**
       * capacity.getDefaultPreceptorIds
       * GET /api/capacity/{capacityId}/default-preceptors
       * @param {object} parameters
       * @param {number} parameters.capacityId Required
       * @param {array} parameters.studentIds Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getDefaultPreceptorIds({ capacityId, studentIds } = {}, options = {}) {
        const uri = encodePath`/api/capacity/${capacityId}/default-preceptors`
        return this.api.fetch(uri, 'GET', { studentIds }, null, options)
      },

      /**
       * capacity.getUnreadActivityCount
       * GET /api/capacity/{capacityId}/activity/unread-count
       * @param {object} parameters
       * @param {number} parameters.capacityId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getUnreadActivityCount({ capacityId } = {}, options = {}) {
        const uri = encodePath`/api/capacity/${capacityId}/activity/unread-count`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * capacity.list
       * POST /api/capacity/list
       * @param {object} body `ListCapacityRequestBody` Required
       * @param {boolean} body.includeAddresses Optional
       * @param {boolean} body.includeAgreements Optional
       * @param {boolean} body.includeLocations Optional
       * @param {boolean} body.includeOrgAndTeam Optional
       * @param {boolean} body.includeServices Optional
       * @param {boolean} body.includeGuests Optional
       * @param {boolean} body.includeShifts Optional
       * @param {boolean} body.includeShiftsWithDeletedLocations Optional
       * @param {boolean} body.includeStatusCounts Optional
       * @param {boolean} body.includeTags Optional
       * @param {boolean} body.includeStaff Optional
       * @param {boolean} body.includeDateExceptions Optional
       * @param {boolean} body.includeValidationData Optional
       * @param {boolean} body.includeGuestOrgDescendantIds Optional
       * @param {boolean} body.includeMatches Optional
       * @param {boolean} body.includeMapClusters Optional
       * @param {boolean} body.excludeTotalCount Optional
       * @param {boolean} body.onlySearchPageData Optional
       * @param {boolean} body.includeCapacitiesOpenToAnyGuest Optional
       * @param {boolean} body.includeOrgMatchPreferences Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {array} body.selectedCapacityIds Optional
       * @param {object} body.mapBounds `MapBounds` Optional
       * @param {number} body.zoom Optional
       * @param {object} body.sortProperty `CapacityListProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      list(body, options = {}) {
        const uri = '/api/capacity/list'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * capacity.listActivity
       * GET /api/capacity/{capacityId}/activity
       * @param {object} parameters
       * @param {number} parameters.capacityId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listActivity({ capacityId } = {}, options = {}) {
        const uri = encodePath`/api/capacity/${capacityId}/activity`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * capacity.listCapacityStaff
       * GET /api/capacity/{capacityId}/staff
       * @param {object} parameters
       * @param {number} parameters.capacityId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listCapacityStaff({ capacityId } = {}, options = {}) {
        const uri = encodePath`/api/capacity/${capacityId}/staff`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * capacity.releaseMatches
       * PUT /api/capacity/{capacityId}/{guestOrgId}/release-matches
       * @param {object} parameters
       * @param {number} parameters.capacityId Required
       * @param {number} parameters.guestOrgId Required
       * @param {object} body `ReleaseMatchesRequest` Required
       * @param {number} body.newGuaranteedMatches Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      releaseMatches({ capacityId, guestOrgId } = {}, body, options = {}) {
        const uri = encodePath`/api/capacity/${capacityId}/${guestOrgId}/release-matches`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * capacity.softDelete
       * DELETE /api/capacity/{capacityId}
       * @param {object} parameters
       * @param {number} parameters.capacityId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      softDelete({ capacityId } = {}, options = {}) {
        const uri = encodePath`/api/capacity/${capacityId}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },

      /**
       * capacity.update
       * PUT /api/capacity/{capacityId}
       * @param {object} parameters
       * @param {number} parameters.capacityId Required
       * @param {object} body `UpdateCapacityRequest` Required
       * @param {number} body.capacityId Optional
       * @param {number} body.orgId Optional
       * @param {array} body.shifts `SaveShiftRequest` Optional
       * @param {array} body.locationIds Optional
       * @param {array} body.guests `CapacityGuestRequest` Optional
       * @param {array} body.staff `CapacityStaffRequest` Optional
       * @param {array} body.dateExceptions Optional
       * @param {number} body.serviceId Optional
       * @param {string} body.name Optional
       * @param {string} body.description Optional
       * @param {number} body.teamId Optional
       * @param {boolean} body.postToSearchPage Optional
       * @param {boolean} body.allowCoordinatorScheduling Optional
       * @param {string} body.startDate Optional
       * @param {string} body.endDate Optional
       * @param {number} body.matchLeadTimeDays Optional
       * @param {boolean} body.openForScheduling Optional
       * @param {boolean} body.allowStudentScheduling Optional
       * @param {boolean} body.allowStudentChanges Optional
       * @param {boolean} body.allowGroups Optional
       * @param {number} body.maxMatches Optional
       * @param {array} body.rotationTypes Optional
       * @param {array} body.specialties Optional
       * @param {array} body.disciplines Optional
       * @param {array} body.studentYears Optional
       * @param {array} body.teamDisciplines Optional
       * @param {object} body.requestAvailabilityPolicy `RequestAvailabilityPolicy` Required
       * @param {boolean} body.allowHostCoordinatorScheduling Optional
       * @param {boolean} body.allowGuestCoordinatorScheduling Optional
       * @param {boolean} body.allowStudentsToLeaveGroupMatch Optional
       * @param {object} body.noNewMatchesForDayWhenOthersHaveBeen `MatchHasBeen` Optional
       * @param {string} body.dateCreated Required
       * @param {string} body.dateModified Required
       * @param {object} body.shouldSerialize `ShouldSerializeProps` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      update({ capacityId } = {}, body, options = {}) {
        const uri = encodePath`/api/capacity/${capacityId}`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * capacity.updateDateTimeLastViewedActivity
       * PUT /api/capacity/{capacityId}/activity/update-date-time-last-viewed
       * @param {object} parameters
       * @param {number} parameters.capacityId Required
       * @param {string} parameters.dateTimeLastViewed Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      updateDateTimeLastViewedActivity({ capacityId, dateTimeLastViewed } = {}, options = {}) {
        const uri = encodePath`/api/capacity/${capacityId}/activity/update-date-time-last-viewed`
        return this.api.fetch(uri, 'PUT', { dateTimeLastViewed }, null, options)
      },
    }

    this.capacityCalendarReport = {
      api: this,

      /**
       * capacityCalendarReport.run
       * POST /api/reports/capacity-calendar/{orgId}
       * @param {object} parameters
       * @param {string} parameters.orgId Required
       * @param {object} body `CapacityCalendarReportCriteria` Required
       * @param {object} body.groupBy `GroupBy` Required
       * @param {string} body.zoom Optional
       * @param {number} body.serviceId Optional
       * @param {string} body.startDate Required
       * @param {string} body.endDate Required
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `MatchListSortProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      run({ orgId } = {}, body, options = {}) {
        const uri = encodePath`/api/reports/capacity-calendar/${orgId}`
        return this.api.fetch(uri, 'POST', null, body, options)
      },
    }

    this.capacityFilterOptions = {
      api: this,

      /**
       * capacityFilterOptions.getCapacityLocations
       * GET /api/filter-options/for-capacities/capacity-locations
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getCapacityLocations(options = {}) {
        const uri = '/api/filter-options/for-capacities/capacity-locations'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * capacityFilterOptions.getCapacityShiftLocations
       * GET /api/filter-options/for-capacities/capacity-shift-locations
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getCapacityShiftLocations(options = {}) {
        const uri = '/api/filter-options/for-capacities/capacity-shift-locations'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * capacityFilterOptions.getDisciplines
       * GET /api/filter-options/for-capacities/disciplines
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getDisciplines(options = {}) {
        const uri = '/api/filter-options/for-capacities/disciplines'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * capacityFilterOptions.getOrgs
       * GET /api/filter-options/for-capacities/orgs
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getOrgs(options = {}) {
        const uri = '/api/filter-options/for-capacities/orgs'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * capacityFilterOptions.getRotationTypes
       * GET /api/filter-options/for-capacities/rotation-types
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getRotationTypes(options = {}) {
        const uri = '/api/filter-options/for-capacities/rotation-types'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * capacityFilterOptions.getSchools
       * GET /api/filter-options/for-capacities/schools
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getSchools(options = {}) {
        const uri = '/api/filter-options/for-capacities/schools'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * capacityFilterOptions.getSpecialties
       * GET /api/filter-options/for-capacities/specialties
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getSpecialties(options = {}) {
        const uri = '/api/filter-options/for-capacities/specialties'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * capacityFilterOptions.getTeams
       * GET /api/filter-options/for-capacities/teams
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getTeams(options = {}) {
        const uri = '/api/filter-options/for-capacities/teams'
        return this.api.fetch(uri, 'GET', null, null, options)
      },
    }

    this.capacityToApiMetaDataJson = {
      api: this,

      /**
       * capacityToApiMetaDataJson.getCapacityToApiMetaDataJson
       * POST /api/capacityToApiMetaDataJson/get-capacityId-by-api-meta-data
       * @param {object} parameters
       * @param {number} parameters.capacityId Optional
       * @param {object} body `object` Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getCapacityToApiMetaDataJson({ capacityId } = {}, body, options = {}) {
        const uri = '/api/capacityToApiMetaDataJson/get-capacityId-by-api-meta-data'
        return this.api.fetch(uri, 'POST', { capacityId }, body, options)
      },

      /**
       * capacityToApiMetaDataJson.getMatchIdsByCapacityIdAndApiMetaData
       * GET /api/capacityToApiMetaDataJson/get-matchIds-by-capacityId-and-api-meta-data
       * @param {object} parameters
       * @param {number} parameters.matchId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getMatchIdsByCapacityIdAndApiMetaData({ matchId } = {}, options = {}) {
        const uri = '/api/capacityToApiMetaDataJson/get-matchIds-by-capacityId-and-api-meta-data'
        return this.api.fetch(uri, 'GET', { matchId }, null, options)
      },
    }

    this.captcha = {
      api: this,

      /**
       * captcha.generate
       * GET /api/captcha
       * @param {object} parameters
       * @param {number} parameters.skippedCaptchaId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      generate({ skippedCaptchaId } = {}, options = {}) {
        const uri = '/api/captcha'
        return this.api.fetch(uri, 'GET', { skippedCaptchaId }, null, options)
      },

      /**
       * captcha.submit
       * POST /api/captcha/{captchaId}
       * @param {object} parameters
       * @param {number} parameters.captchaId Required
       * @param {object} body `UserCaptchaSubmitRequestBody` Required
       * @param {string} body.securityCode Optional
       * @param {string} body.nonce Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      submit({ captchaId } = {}, body, options = {}) {
        const uri = encodePath`/api/captcha/${captchaId}`
        return this.api.fetch(uri, 'POST', null, body, options)
      },
    }

    this.cn = {
      api: this,

      /**
       * cn.addAllFeatures
       * GET /api/cn/add-all-features
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addAllFeatures(options = {}) {
        const uri = '/api/cn/add-all-features'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * cn.addFileTypes
       * GET /api/cn/add-file-types
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addFileTypes(options = {}) {
        const uri = '/api/cn/add-file-types'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * cn.alertCyberPolice
       * POST /api/cn/alert-cyber-police
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      alertCyberPolice(options = {}) {
        const uri = '/api/cn/alert-cyber-police'
        return this.api.fetch(uri, 'POST', null, null, options)
      },

      /**
       * cn.clearClientApiCache
       * POST /api/cn/clear-client-api-cache
       * @param {object} body `ClearClientApiCacheRequest` Required
       * @param {array} body.instructions `ClearClientApiCacheInstruction` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      clearClientApiCache(body, options = {}) {
        const uri = '/api/cn/clear-client-api-cache'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * cn.clearTimeServiceCache
       * GET /api/cn/time/clear-cache
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      clearTimeServiceCache(options = {}) {
        const uri = '/api/cn/time/clear-cache'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * cn.confirmAllEmailAddresses
       * GET /api/cn/confirm-all-email-addresses
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      confirmAllEmailAddresses(options = {}) {
        const uri = '/api/cn/confirm-all-email-addresses'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * cn.confirmEmail
       * PUT /api/cn/confirm-email
       * @param {object} parameters
       * @param {string} parameters.userId Optional
       * @param {string} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      confirmEmail({ userId } = {}, body, options = {}) {
        const uri = '/api/cn/confirm-email'
        return this.api.fetch(uri, 'PUT', { userId }, body, options)
      },

      /**
       * cn.dashboardHome
       * GET /api/cn/dashboard
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      dashboardHome(options = {}) {
        const uri = '/api/cn/dashboard'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * cn.dashboardRecentUsers
       * GET /api/cn/recent-users
       * @param {object} parameters
       * @param {number} parameters.days Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      dashboardRecentUsers({ days } = {}, options = {}) {
        const uri = '/api/cn/recent-users'
        return this.api.fetch(uri, 'GET', { days }, null, options)
      },

      /**
       * cn.encryptMatchComments
       * POST /api/cn/encrypt-match-comments
       * @param {number} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      encryptMatchComments(body, options = {}) {
        const uri = '/api/cn/encrypt-match-comments'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * cn.encryptMessages
       * POST /api/cn/encrypt-messages
       * @param {number} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      encryptMessages(body, options = {}) {
        const uri = '/api/cn/encrypt-messages'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * cn.exportBillingReport
       * POST /api/cn/billing-report/export
       * @param {object} body `BillingReportRequest` Required
       * @param {number} body.orgId Optional
       * @param {string} body.startDate Optional
       * @param {string} body.endDate Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      exportBillingReport(body, options = {}) {
        const uri = '/api/cn/billing-report/export'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * cn.geocodeMissing
       * GET /api/cn/geocode-missing
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      geocodeMissing(options = {}) {
        const uri = '/api/cn/geocode-missing'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * cn.getAppDiscriminator
       * GET /api/cn/get-app-dicriminator
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getAppDiscriminator(options = {}) {
        const uri = '/api/cn/get-app-dicriminator'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * cn.getBillingReport
       * POST /api/cn/billing-report
       * @param {object} body `BillingReportRequest` Required
       * @param {number} body.orgId Optional
       * @param {string} body.startDate Optional
       * @param {string} body.endDate Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getBillingReport(body, options = {}) {
        const uri = '/api/cn/billing-report'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * cn.getRelationsChart
       * GET /api/cn/relations
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getRelationsChart(options = {}) {
        const uri = '/api/cn/relations'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * cn.intentionallyThrowError
       * GET /api/cn/intentionally-throw-error
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      intentionallyThrowError(options = {}) {
        const uri = '/api/cn/intentionally-throw-error'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * cn.listOrphanPendingStaff
       * GET /api/cn/orgs/staff/orphans
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listOrphanPendingStaff(options = {}) {
        const uri = '/api/cn/orgs/staff/orphans'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * cn.listOrphans
       * GET /api/cn/orgs/orphans
       * @param {object} parameters
       * @param {boolean} parameters.includePageOfData Optional
       * @param {number} parameters.offset Optional
       * @param {number} parameters.pageSize Optional
       * @param {string} parameters.search Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listOrphans({ includePageOfData, offset, pageSize, search } = {}, options = {}) {
        const uri = '/api/cn/orgs/orphans'
        return this.api.fetch(uri, 'GET', { includePageOfData, offset, pageSize, search }, null, options)
      },

      /**
       * cn.listRegistered
       * GET /api/cn/orgs/registered
       * @param {object} parameters
       * @param {boolean} parameters.includePageOfData Optional
       * @param {number} parameters.offset Optional
       * @param {number} parameters.pageSize Optional
       * @param {string} parameters.search Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listRegistered({ includePageOfData, offset, pageSize, search } = {}, options = {}) {
        const uri = '/api/cn/orgs/registered'
        return this.api.fetch(uri, 'GET', { includePageOfData, offset, pageSize, search }, null, options)
      },

      /**
       * cn.listStaff
       * POST /api/cn/staff
       * @param {object} body `ListStaffRequestBody` Required
       * @param {number} body.orgId Optional
       * @param {number} body.staffId Optional
       * @param {boolean} body.includeAllEmails Optional
       * @param {boolean} body.includeCustomFields Optional
       * @param {boolean} body.includeChildren Optional
       * @param {boolean} body.includeEmail Optional
       * @param {boolean} body.includeParents Optional
       * @param {boolean} body.includeIsAllStaffCxFlag Optional
       * @param {boolean} body.includeLastActivityDate Optional
       * @param {boolean} body.excludeTotalCount Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {array} body.selectedStaffIds Optional
       * @param {array} body.selectedUserIds Optional
       * @param {object} body.sortProperty `StaffListProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {string} body.search Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listStaff(body, options = {}) {
        const uri = '/api/cn/staff'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * cn.mergeOrg
       * PUT /api/cn/orgs/merge
       * @param {object} parameters
       * @param {number} parameters.keepId Optional
       * @param {number} parameters.removeId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      mergeOrg({ keepId, removeId } = {}, options = {}) {
        const uri = '/api/cn/orgs/merge'
        return this.api.fetch(uri, 'PUT', { keepId, removeId }, null, options)
      },

      /**
       * cn.mergeUsers
       * PUT /api/cn/users/merge
       * @param {object} parameters
       * @param {string} parameters.keepId Optional
       * @param {string} parameters.removeId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      mergeUsers({ keepId, removeId } = {}, options = {}) {
        const uri = '/api/cn/users/merge'
        return this.api.fetch(uri, 'PUT', { keepId, removeId }, null, options)
      },

      /**
       * cn.rebuildAllMatchCache
       * GET /api/cn/rebuild-all-match-cache
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      rebuildAllMatchCache(options = {}) {
        const uri = '/api/cn/rebuild-all-match-cache'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * cn.refreshMatchStatus
       * GET /api/cn/refresh-match-status/{matchId}
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      refreshMatchStatus({ matchId } = {}, options = {}) {
        const uri = encodePath`/api/cn/refresh-match-status/${matchId}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * cn.resendInvite
       * PUT /api/cn/resend-invite
       * @param {string} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      resendInvite(body, options = {}) {
        const uri = '/api/cn/resend-invite'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * cn.setAllStudentsAs1stYearMd
       * GET /api/cn/set-all-students-as-1st-year-md
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      setAllStudentsAs1stYearMd(options = {}) {
        const uri = '/api/cn/set-all-students-as-1st-year-md'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * cn.setFieldValue
       * PUT /api/cn/field-values/{stepSubmissionId}/{fieldName}
       * @param {object} parameters
       * @param {string} parameters.fieldName Required
       * @param {string} parameters.fieldValue Optional
       * @param {number} parameters.stepSubmissionId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      setFieldValue({ fieldName, fieldValue, stepSubmissionId } = {}, options = {}) {
        const uri = encodePath`/api/cn/field-values/${stepSubmissionId}/${fieldName}`
        return this.api.fetch(uri, 'PUT', { fieldValue }, null, options)
      },

      /**
       * cn.suggestName
       * GET /api/cn/suggest-name
       * @param {object} parameters
       * @param {string} parameters.name Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      suggestName({ name } = {}, options = {}) {
        const uri = '/api/cn/suggest-name'
        return this.api.fetch(uri, 'GET', { name }, null, options)
      },

      /**
       * cn.syncAutomaticMatchUsers
       * GET /api/cn/sync-automatic-match-users/{search}
       * @param {object} parameters
       * @param {string} parameters.search Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      syncAutomaticMatchUsers({ search } = {}, options = {}) {
        const uri = encodePath`/api/cn/sync-automatic-match-users/${search}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * cn.syncTimeZonesInDb
       * GET /api/cn/time/sync-database
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      syncTimeZonesInDb(options = {}) {
        const uri = '/api/cn/time/sync-database'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * cn.unverifyOrg
       * PUT /api/cn/orgs/{orgId}/unverify
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      unverifyOrg({ orgId } = {}, options = {}) {
        const uri = encodePath`/api/cn/orgs/${orgId}/unverify`
        return this.api.fetch(uri, 'PUT', null, null, options)
      },

      /**
       * cn.verifyAllOrgs
       * GET /api/cn/verify-all-orgs
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      verifyAllOrgs(options = {}) {
        const uri = '/api/cn/verify-all-orgs'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * cn.verifyOrg
       * PUT /api/cn/orgs/{orgId}/verify
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      verifyOrg({ orgId } = {}, options = {}) {
        const uri = encodePath`/api/cn/orgs/${orgId}/verify`
        return this.api.fetch(uri, 'PUT', null, null, options)
      },
    }

    this.connections = {
      api: this,

      /**
       * connections.acceptConnectionFrom
       * PUT /api/connections/{userFromId}
       * @param {object} parameters
       * @param {string} parameters.userFromId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      acceptConnectionFrom({ userFromId } = {}, options = {}) {
        const uri = encodePath`/api/connections/${userFromId}`
        return this.api.fetch(uri, 'PUT', null, null, options)
      },

      /**
       * connections.connectTo
       * POST /api/connections/{userToId}
       * @param {object} parameters
       * @param {string} parameters.userToId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      connectTo({ userToId } = {}, options = {}) {
        const uri = encodePath`/api/connections/${userToId}`
        return this.api.fetch(uri, 'POST', null, null, options)
      },

      /**
       * connections.listConnections
       * GET /api/connections
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listConnections(options = {}) {
        const uri = '/api/connections'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * connections.removeConnectionFrom
       * DELETE /api/connections/{userFromId}
       * @param {object} parameters
       * @param {string} parameters.userFromId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      removeConnectionFrom({ userFromId } = {}, options = {}) {
        const uri = encodePath`/api/connections/${userFromId}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },
    }

    this.consortium = {
      api: this,

      /**
       * consortium.addMembers
       * POST /api/consortiums/{orgId}/members
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {array} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addMembers({ orgId } = {}, body, options = {}) {
        const uri = encodePath`/api/consortiums/${orgId}/members`
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * consortium.getRelations
       * GET /api/consortiums/{orgId}/relations
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getRelations({ orgId } = {}, options = {}) {
        const uri = encodePath`/api/consortiums/${orgId}/relations`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * consortium.getRotations
       * GET /api/consortiums/{orgId}/rotations
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getRotations({ orgId } = {}, options = {}) {
        const uri = encodePath`/api/consortiums/${orgId}/rotations`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * consortium.listMembers
       * GET /api/consortiums/{orgId}/members
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listMembers({ orgId } = {}, options = {}) {
        const uri = encodePath`/api/consortiums/${orgId}/members`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * consortium.removeMember
       * DELETE /api/consortiums/{orgId}/members/{memberOrgId}
       * @param {object} parameters
       * @param {number} parameters.memberOrgId Required
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      removeMember({ memberOrgId, orgId } = {}, options = {}) {
        const uri = encodePath`/api/consortiums/${orgId}/members/${memberOrgId}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },
    }

    this.course = {
      api: this,

      /**
       * course.add
       * POST /api/courses
       * @param {object} body `AddCourseRequest` Required
       * @param {string} body.name Optional
       * @param {string} body.description Optional
       * @param {string} body.startDate Required
       * @param {string} body.endDate Required
       * @param {number} body.hoursRequired Optional
       * @param {number} body.studentCountGoal Optional
       * @param {number} body.orgId Optional
       * @param {number} body.teamId Optional
       * @param {array} body.rotationTypes Optional
       * @param {array} body.studentGroups `CourseStudentGroupTvp` Optional
       * @param {array} body.students `CourseStudentTvp` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      add(body, options = {}) {
        const uri = '/api/courses'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * course.canDelete
       * GET /api/courses/{courseId}/can-delete
       * @param {object} parameters
       * @param {number} parameters.courseId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      canDelete({ courseId } = {}, options = {}) {
        const uri = encodePath`/api/courses/${courseId}/can-delete`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * course.export
       * POST /api/courses/export
       * @param {object} body `ExportCoursesRequest` Required
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `CourseListProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      export(body, options = {}) {
        const uri = '/api/courses/export'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * course.get
       * GET /api/courses/{courseId}
       * @param {object} parameters
       * @param {number} parameters.courseId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      get({ courseId } = {}, options = {}) {
        const uri = encodePath`/api/courses/${courseId}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * course.list
       * POST /api/courses/list
       * @param {object} body `ListCoursesRequestBody` Required
       * @param {boolean} body.excludeTotalCount Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {array} body.selectedCourseIds Optional
       * @param {object} body.sortProperty `CourseListProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      list(body, options = {}) {
        const uri = '/api/courses/list'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * course.listStudentGroups
       * POST /api/courses/list-student-groups
       * @param {object} body `ListCourseStudentGroups` Required
       * @param {number} body.courseId Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `StudentListProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listStudentGroups(body, options = {}) {
        const uri = '/api/courses/list-student-groups'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * course.softDelete
       * DELETE /api/courses/{courseId}
       * @param {object} parameters
       * @param {number} parameters.courseId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      softDelete({ courseId } = {}, options = {}) {
        const uri = encodePath`/api/courses/${courseId}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },

      /**
       * course.update
       * PUT /api/courses/{courseId}
       * @param {object} parameters
       * @param {number} parameters.courseId Required
       * @param {object} body `UpdateCourseRequest` Required
       * @param {number} body.courseId Optional
       * @param {string} body.name Optional
       * @param {string} body.description Optional
       * @param {string} body.startDate Required
       * @param {string} body.endDate Required
       * @param {number} body.hoursRequired Optional
       * @param {number} body.studentCountGoal Optional
       * @param {number} body.orgId Optional
       * @param {number} body.teamId Optional
       * @param {array} body.rotationTypes Optional
       * @param {array} body.studentGroups `CourseStudentGroupTvp` Optional
       * @param {array} body.students `CourseStudentTvp` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      update({ courseId } = {}, body, options = {}) {
        const uri = encodePath`/api/courses/${courseId}`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.courseFilterOptions = {
      api: this,

      /**
       * courseFilterOptions.getRotationTypes
       * GET /api/filter-options/for-courses/rotation-types
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getRotationTypes(options = {}) {
        const uri = '/api/filter-options/for-courses/rotation-types'
        return this.api.fetch(uri, 'GET', null, null, options)
      },
    }

    this.customTag = {
      api: this,

      /**
       * customTag.addCustomTag
       * POST /api/orgs/{orgId}/custom-tags
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} body `AddCustomTagRequestBody` Required
       * @param {number} body.teamId Optional
       * @param {string} body.name Required
       * @param {string} body.descriptionMarkdown Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addCustomTag({ orgId } = {}, body, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/custom-tags`
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * customTag.canDeleteCustomTag
       * GET /api/orgs/{orgId}/custom-tags/{customTagId}/can-delete
       * @param {object} parameters
       * @param {number} parameters.customTagId Required
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      canDeleteCustomTag({ customTagId, orgId } = {}, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/custom-tags/${customTagId}/can-delete`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * customTag.deleteCustomTag
       * DELETE /api/orgs/{orgId}/custom-tags/{customTagId}
       * @param {object} parameters
       * @param {number} parameters.customTagId Required
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      deleteCustomTag({ customTagId, orgId } = {}, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/custom-tags/${customTagId}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },

      /**
       * customTag.getCustomTagById
       * GET /api/orgs/custom-tags/{customTagId}
       * @param {object} parameters
       * @param {number} parameters.customTagId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getCustomTagById({ customTagId } = {}, options = {}) {
        const uri = encodePath`/api/orgs/custom-tags/${customTagId}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * customTag.getCustomTags
       * GET /api/orgs/{orgId}/custom-tags
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {boolean} parameters.showEditableOnly Optional
       * @param {number} parameters.teamId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getCustomTags({ orgId, showEditableOnly, teamId } = {}, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/custom-tags`
        return this.api.fetch(uri, 'GET', { showEditableOnly, teamId }, null, options)
      },

      /**
       * customTag.updateCustomTag
       * PUT /api/orgs/{orgId}/custom-tags/{customTagId}
       * @param {object} parameters
       * @param {number} parameters.customTagId Required
       * @param {number} parameters.orgId Required
       * @param {object} body `SaveCustomTagRequestBody` Required
       * @param {string} body.name Required
       * @param {string} body.descriptionMarkdown Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      updateCustomTag({ customTagId, orgId } = {}, body, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/custom-tags/${customTagId}`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.discipline = {
      api: this,

      /**
       * discipline.listDisciplines
       * GET /api/disciplines
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listDisciplines(options = {}) {
        const { cache } = this.listDisciplines
        const cachePromise = cache.tryGet(null, null, options)
        if (cachePromise) return cachePromise
        const uri = '/api/disciplines'
        const promise = this.api.fetch(uri, 'GET', null, null, options)
        cache.add(null, null, options, promise)
        return promise
      },
    }

    this.discipline.listDisciplines.cache = new ApiCache(1800, false)

    this.document = {
      api: this,

      /**
       * document.downloadAgreementFile
       * GET /api/docs/agreements/{agreementId}/{agreementFileId}/{name}
       * @param {object} parameters
       * @param {number} parameters.agreementFileId Required
       * @param {number} parameters.agreementId Required
       * @param {string} parameters.name Required
       * @param {boolean} parameters.sendAsDownloadable Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      downloadAgreementFile({ agreementFileId, agreementId, name, sendAsDownloadable } = {}, options = {}) {
        const uri = encodePath`/api/docs/agreements/${agreementId}/${agreementFileId}/${name}`
        return this.api.fetch(uri, 'GET', { sendAsDownloadable }, null, options)
      },

      /**
       * document.downloadStepFile
       * GET /api/docs/steps/{stepId}/{stepFileId}/{name}
       * @param {object} parameters
       * @param {string} parameters.name Required
       * @param {boolean} parameters.sendAsDownloadable Optional
       * @param {number} parameters.stepFileId Required
       * @param {string} parameters.stepId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      downloadStepFile({ name, sendAsDownloadable, stepFileId, stepId } = {}, options = {}) {
        const uri = encodePath`/api/docs/steps/${stepId}/${stepFileId}/${name}`
        return this.api.fetch(uri, 'GET', { sendAsDownloadable }, null, options)
      },

      /**
       * document.downloadStepSubmissionFile
       * GET /api/docs/step-submissions/{stepSubmissionId}/{stepSubmissionFileId}/{name}
       * @param {object} parameters
       * @param {string} parameters.name Required
       * @param {boolean} parameters.sendAsDownloadable Optional
       * @param {number} parameters.stepSubmissionFileId Required
       * @param {string} parameters.stepSubmissionId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      downloadStepSubmissionFile({ name, sendAsDownloadable, stepSubmissionFileId, stepSubmissionId } = {}, options = {}) {
        const uri = encodePath`/api/docs/step-submissions/${stepSubmissionId}/${stepSubmissionFileId}/${name}`
        return this.api.fetch(uri, 'GET', { sendAsDownloadable }, null, options)
      },

      /**
       * document.downloadUserDocumentFile
       * GET /api/docs/user-documents/{userDocumentId}
       * @param {object} parameters
       * @param {boolean} parameters.sendAsDownloadable Optional
       * @param {number} parameters.userDocumentId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      downloadUserDocumentFile({ sendAsDownloadable, userDocumentId } = {}, options = {}) {
        const uri = encodePath`/api/docs/user-documents/${userDocumentId}`
        return this.api.fetch(uri, 'GET', { sendAsDownloadable }, null, options)
      },

      /**
       * document.getMissingFiles
       * GET /api/docs/missing
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getMissingFiles(options = {}) {
        const uri = '/api/docs/missing'
        return this.api.fetch(uri, 'GET', null, null, options)
      },
    }

    this.endorsements = {
      api: this,

      /**
       * endorsements.approveEndorsement
       * PUT /api/endorsements/{endorsementId}/approve
       * @param {object} parameters
       * @param {number} parameters.endorsementId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      approveEndorsement({ endorsementId } = {}, options = {}) {
        const uri = encodePath`/api/endorsements/${endorsementId}/approve`
        return this.api.fetch(uri, 'PUT', null, null, options)
      },

      /**
       * endorsements.endorseUser
       * POST /api/endorsements
       * @param {object} body `EndorseUserViewModel` Required
       * @param {string} body.endorsedId Required
       * @param {object} body.relationship `UserRelationship` Required
       * @param {string} body.endorsement Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      endorseUser(body, options = {}) {
        const uri = '/api/endorsements'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * endorsements.rejectEndorsement
       * PUT /api/endorsements/{endorsementId}/reject
       * @param {object} parameters
       * @param {number} parameters.endorsementId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      rejectEndorsement({ endorsementId } = {}, options = {}) {
        const uri = encodePath`/api/endorsements/${endorsementId}/reject`
        return this.api.fetch(uri, 'PUT', null, null, options)
      },
    }

    this.eula = {
      api: this,

      /**
       * eula.accept
       * PUT /api/eula/accept-latest
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      accept(options = {}) {
        const uri = '/api/eula/accept-latest'
        return this.api.fetch(uri, 'PUT', null, null, options)
      },

      /**
       * eula.getLatest
       * GET /api/eula/latest
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getLatest(options = {}) {
        const uri = '/api/eula/latest'
        return this.api.fetch(uri, 'GET', null, null, options)
      },
    }

    this.evaluations = {
      api: this,

      /**
       * evaluations.exportReport
       * POST /api/reports/evaluations/export
       * @param {object} body `EvaluationStepsReportCriteria` Required
       * @param {array} body.stepIdsToShow Optional
       * @param {array} body.stepIdsToExpand Optional
       * @param {string} body.startDate Required
       * @param {string} body.endDate Required
       * @param {array} body.groupBy `EvaluationReportGroupBy` Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      exportReport(body, options = {}) {
        const uri = '/api/reports/evaluations/export'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * evaluations.getAllEvaluationSteps
       * GET /api/reports/evaluations
       * @param {object} parameters
       * @param {string} parameters.endDate Optional
       * @param {string} parameters.startDate Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getAllEvaluationSteps({ endDate, startDate } = {}, options = {}) {
        const uri = '/api/reports/evaluations'
        return this.api.fetch(uri, 'GET', { endDate, startDate }, null, options)
      },

      /**
       * evaluations.getDetails
       * POST /api/reports/evaluations/details
       * @param {object} body `EvaluationStepsReportCriteria` Required
       * @param {array} body.stepIdsToShow Optional
       * @param {array} body.stepIdsToExpand Optional
       * @param {string} body.startDate Required
       * @param {string} body.endDate Required
       * @param {array} body.groupBy `EvaluationReportGroupBy` Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getDetails(body, options = {}) {
        const uri = '/api/reports/evaluations/details'
        return this.api.fetch(uri, 'POST', null, body, options)
      },
    }

    this.exportJob = {
      api: this,

      /**
       * exportJob.cancelExportJob
       * POST /api/export-jobs/cancel
       * @param {object} parameters
       * @param {number} parameters.exportJobId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      cancelExportJob({ exportJobId } = {}, options = {}) {
        const uri = '/api/export-jobs/cancel'
        return this.api.fetch(uri, 'POST', { exportJobId }, null, options)
      },

      /**
       * exportJob.downloadExportedReport
       * POST /api/export-jobs/download
       * @param {object} parameters
       * @param {number} parameters.exportJobId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      downloadExportedReport({ exportJobId } = {}, options = {}) {
        const uri = '/api/export-jobs/download'
        return this.api.fetch(uri, 'POST', { exportJobId }, null, options)
      },

      /**
       * exportJob.getActiveExportJobsByUserId
       * GET /api/export-jobs/active
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getActiveExportJobsByUserId(options = {}) {
        const uri = '/api/export-jobs/active'
        return this.api.fetch(uri, 'GET', null, null, options)
      },
    }

    this.fairview = {
      api: this,

      /**
       * fairview.getStudentInformation
       * GET /api/custom-integrations/fairview/student-information
       * @param {object} parameters
       * @param {string} parameters.earliestGraduationDate Optional
       * @param {string} parameters.email Optional
       * @param {boolean} parameters.excludeDeleted Optional
       * @param {boolean} parameters.excludeUnConfirmed Optional
       * @param {string} parameters.lastModifiedAfter Optional
       * @param {string} parameters.latestGraduationDate Optional
       * @param {number} parameters.offset Optional
       * @param {number} parameters.pageSize Optional
       * @param {string} parameters.userId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getStudentInformation({ earliestGraduationDate, email, excludeDeleted, excludeUnConfirmed, lastModifiedAfter, latestGraduationDate, offset, pageSize, userId } = {}, options = {}) {
        const uri = '/api/custom-integrations/fairview/student-information'
        return this.api.fetch(uri, 'GET', { earliestGraduationDate, email, excludeDeleted, excludeUnConfirmed, lastModifiedAfter, latestGraduationDate, offset, pageSize, userId }, null, options)
      },
    }

    this.featurePackage = {
      api: this,

      /**
       * featurePackage.list
       * GET /api/feature-packages
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      list(options = {}) {
        const uri = '/api/feature-packages'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * featurePackage.listDetailed
       * GET /api/feature-packages/detailed
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listDetailed(options = {}) {
        const uri = '/api/feature-packages/detailed'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * featurePackage.listFriendlyFeatures
       * GET /api/feature-packages/friendly-features
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listFriendlyFeatures(options = {}) {
        const uri = '/api/feature-packages/friendly-features'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * featurePackage.listFriendlyFeaturesHtmlOnly
       * GET /api/feature-packages/friendly-features/html-only
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listFriendlyFeaturesHtmlOnly(options = {}) {
        const uri = '/api/feature-packages/friendly-features/html-only'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * featurePackage.listPublic
       * GET /api/feature-packages/public
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listPublic(options = {}) {
        const uri = '/api/feature-packages/public'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * featurePackage.previewMarkdown
       * POST /api/feature-packages/preview-markdown
       * @param {string} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      previewMarkdown(body, options = {}) {
        const { cache } = this.previewMarkdown
        const cachePromise = cache.tryGet(null, body, options)
        if (cachePromise) return cachePromise
        const uri = '/api/feature-packages/preview-markdown'
        const promise = this.api.fetch(uri, 'POST', null, body, options)
        cache.add(null, _.cloneDeep(body), options, promise)
        return promise
      },

      /**
       * featurePackage.save
       * PUT /api/feature-packages
       * @param {object} body `SaveFeaturePackageRequest` Required
       * @param {number} body.featurePackageId Optional
       * @param {number} body.featurePackageVersionId Optional
       * @param {boolean} body.isAddOn Optional
       * @param {string} body.name Required
       * @param {string} body.description Optional
       * @param {boolean} body.isPublic Optional
       * @param {string} body.disclaimer Optional
       * @param {array} body.friendlyFeatures `NewOrPotentiallyUpdatedFriendlyFeature` Optional
       * @param {string} body.stripeProductId Optional
       * @param {string} body.annualStripePriceId Optional
       * @param {number} body.annualPrice Optional
       * @param {number} body.annualRotationMax Optional
       * @param {number} body.annualRotationMaxPrice Optional
       * @param {string} body.monthlyStripePriceId Optional
       * @param {number} body.monthlyPrice Optional
       * @param {number} body.monthlyRotationMax Optional
       * @param {number} body.monthlyRotationMaxPrice Optional
       * @param {array} body.featureTypes `FeatureType` Optional
       * @param {array} body.addOnFeaturePackageVersionIds Optional
       * @param {array} body.basePackageFeaturePackageVersionIds Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      save(body, options = {}) {
        const uri = '/api/feature-packages'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * featurePackage.saveFriendlyFeature
       * PUT /api/feature-packages/friendly-features/{friendlyFeatureId}
       * @param {object} parameters
       * @param {number} parameters.friendlyFeatureId Optional
       * @param {object} body `SaveFriendlyFeatureRequestBody` Required
       * @param {string} body.textMarkdown Optional
       * @param {string} body.tooltipMarkdown Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      saveFriendlyFeature({ friendlyFeatureId } = {}, body, options = {}) {
        const uri = encodePathWithOptionalParts(0)`/api/feature-packages/friendly-features/${friendlyFeatureId}`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.featurePackage.previewMarkdown.cache = new ApiCache(604800, true)

    this.fileType = {
      api: this,

      /**
       * fileType.listAvailableFileTypes
       * GET /api/file-types
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listAvailableFileTypes(options = {}) {
        const { cache } = this.listAvailableFileTypes
        const cachePromise = cache.tryGet(null, null, options)
        if (cachePromise) return cachePromise
        const uri = '/api/file-types'
        const promise = this.api.fetch(uri, 'GET', null, null, options)
        cache.add(null, null, options, promise)
        return promise
      },
    }

    this.fileType.listAvailableFileTypes.cache = new ApiCache(1800, false)

    this.form = {
      api: this,

      /**
       * form.getFieldTypes
       * GET /api/forms/field-types
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getFieldTypes(options = {}) {
        const { cache } = this.getFieldTypes
        const cachePromise = cache.tryGet(null, null, options)
        if (cachePromise) return cachePromise
        const uri = '/api/forms/field-types'
        const promise = this.api.fetch(uri, 'GET', null, null, options)
        cache.add(null, null, options, promise)
        return promise
      },
    }

    this.form.getFieldTypes.cache = new ApiCache(604800, false)

    this.formTemplate = {
      api: this,

      /**
       * formTemplate.delete
       * DELETE /api/form-templates/{id}
       * @param {object} parameters
       * @param {number} parameters.id Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      delete({ id } = {}, options = {}) {
        const uri = encodePath`/api/form-templates/${id}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },

      /**
       * formTemplate.get
       * GET /api/form-templates/{id}
       * @param {object} parameters
       * @param {number} parameters.id Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      get({ id } = {}, options = {}) {
        const uri = encodePath`/api/form-templates/${id}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * formTemplate.insert
       * POST /api/form-templates
       * @param {object} body `FormTemplate` Required
       * @param {number} body.formTemplateId Optional
       * @param {number} body.stepId Optional
       * @param {string} body.name Required
       * @param {string} body.configJson Required
       * @param {string} body.dateCreated Required
       * @param {string} body.dateModified Required
       * @param {string} body.userCreated Optional
       * @param {string} body.userModified Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      insert(body, options = {}) {
        const uri = '/api/form-templates'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * formTemplate.list
       * GET /api/form-templates
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      list(options = {}) {
        const { cache } = this.list
        const cachePromise = cache.tryGet(null, null, options)
        if (cachePromise) return cachePromise
        const uri = '/api/form-templates'
        const promise = this.api.fetch(uri, 'GET', null, null, options)
        cache.add(null, null, options, promise)
        return promise
      },

      /**
       * formTemplate.listFieldTemplates
       * GET /api/form-templates/field-templates
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listFieldTemplates(options = {}) {
        const uri = '/api/form-templates/field-templates'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * formTemplate.listOrgForms
       * GET /api/form-templates/forms/{orgId}
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listOrgForms({ orgId } = {}, options = {}) {
        const uri = encodePath`/api/form-templates/forms/${orgId}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * formTemplate.update
       * PUT /api/form-templates/{id}
       * @param {object} parameters
       * @param {number} parameters.id Required
       * @param {object} body `FormTemplate` Required
       * @param {number} body.formTemplateId Optional
       * @param {number} body.stepId Optional
       * @param {string} body.name Required
       * @param {string} body.configJson Required
       * @param {string} body.dateCreated Required
       * @param {string} body.dateModified Required
       * @param {string} body.userCreated Optional
       * @param {string} body.userModified Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      update({ id } = {}, body, options = {}) {
        const uri = encodePath`/api/form-templates/${id}`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.formTemplate.list.cache = new ApiCache(604800, false)

    this.geography = {
      api: this,

      /**
       * geography.listAddressesFromInputString
       * GET /api/geo
       * @param {object} parameters
       * @param {string} parameters.addressText Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listAddressesFromInputString({ addressText } = {}, options = {}) {
        const uri = '/api/geo'
        return this.api.fetch(uri, 'GET', { addressText }, null, options)
      },
    }

    this.home = {
      api: this,

      /**
       * home.completeSignup
       * GET /api/home/complete-signup
       * @param {object} parameters
       * @param {string} parameters.code Optional
       * @param {string} parameters.email Optional
       * @param {string} parameters.token Optional
       * @param {string} parameters.userId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      completeSignup({ code, email, token, userId } = {}, options = {}) {
        const uri = '/api/home/complete-signup'
        return this.api.fetch(uri, 'GET', { code, email, token, userId }, null, options)
      },

      /**
       * home.confirmEmail
       * GET /api/home/confirm-email
       * @param {object} parameters
       * @param {string} parameters.code Optional
       * @param {string} parameters.email Optional
       * @param {string} parameters.token Optional
       * @param {string} parameters.userId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      confirmEmail({ code, email, token, userId } = {}, options = {}) {
        const uri = '/api/home/confirm-email'
        return this.api.fetch(uri, 'GET', { code, email, token, userId }, null, options)
      },

      /**
       * home.logOut
       * GET /api/home/logout
       * @param {object} parameters
       * @param {string} parameters.redirect Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      logOut({ redirect } = {}, options = {}) {
        const uri = '/api/home/logout'
        return this.api.fetch(uri, 'GET', { redirect }, null, options)
      },

      /**
       * home.reactivateAccount
       * GET /api/home/reactivate-account
       * @param {object} parameters
       * @param {string} parameters.token Optional
       * @param {string} parameters.userId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      reactivateAccount({ token, userId } = {}, options = {}) {
        const uri = '/api/home/reactivate-account'
        return this.api.fetch(uri, 'GET', { token, userId }, null, options)
      },

      /**
       * home.resetPassword
       * GET /api/home/reset-password
       * @param {object} parameters
       * @param {string} parameters.code Optional
       * @param {string} parameters.token Optional
       * @param {string} parameters.userId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      resetPassword({ code, token, userId } = {}, options = {}) {
        const uri = '/api/home/reset-password'
        return this.api.fetch(uri, 'GET', { code, token, userId }, null, options)
      },
    }

    this.hourLog = {
      api: this,

      /**
       * hourLog.add
       * POST /api/hourlogs/{matchId}
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {array} body `HourLog` Required
       * @param {number} body.hourLogId Optional
       * @param {number} body.orgId Optional
       * @param {number} body.matchId Optional
       * @param {string} body.userId Required
       * @param {string} body.fromDate Required
       * @param {string} body.toDate Required
       * @param {string} body.note Optional
       * @param {number} body.studentYear Optional
       * @param {object} body.type `HourType` Required
       * @param {string} body.verifiedUserId Optional
       * @param {string} body.verifiedDate Optional
       * @param {string} body.locationName Optional
       * @param {array} body.supervisorUserIds Optional
       * @param {string} body.dateCreated Required
       * @param {string} body.dateModified Required
       * @param {string} body.userCreated Optional
       * @param {string} body.userModified Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      add({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/hourlogs/${matchId}`
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * hourLog.delete
       * DELETE /api/hourlogs/{matchId}/{hourLogId}
       * @param {object} parameters
       * @param {number} parameters.hourLogId Required
       * @param {number} parameters.matchId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      delete({ hourLogId, matchId } = {}, options = {}) {
        const uri = encodePath`/api/hourlogs/${matchId}/${hourLogId}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },

      /**
       * hourLog.getHoursToVerify
       * GET /api/hourlogs/hoursToVerify
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getHoursToVerify(options = {}) {
        const uri = '/api/hourlogs/hoursToVerify'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * hourLog.list
       * GET /api/hourlogs/{matchId}
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {string} parameters.userId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      list({ matchId, userId } = {}, options = {}) {
        const uri = encodePath`/api/hourlogs/${matchId}`
        return this.api.fetch(uri, 'GET', { userId }, null, options)
      },

      /**
       * hourLog.setExpectedHours
       * PUT /api/hourlogs/{matchId}/set-expected-hours
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `SetExpectedHoursRequest` Required
       * @param {number} body.matchId Optional
       * @param {array} body.userIds Optional
       * @param {number} body.totalExpectedHours Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      setExpectedHours({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/hourlogs/${matchId}/set-expected-hours`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * hourLog.update
       * PUT /api/hourlogs/{matchId}/{hourLogId}
       * @param {object} parameters
       * @param {number} parameters.hourLogId Required
       * @param {number} parameters.matchId Required
       * @param {object} body `HourLog` Required
       * @param {number} body.hourLogId Optional
       * @param {number} body.orgId Optional
       * @param {number} body.matchId Optional
       * @param {string} body.userId Required
       * @param {string} body.fromDate Required
       * @param {string} body.toDate Required
       * @param {string} body.note Optional
       * @param {number} body.studentYear Optional
       * @param {object} body.type `HourType` Required
       * @param {string} body.verifiedUserId Optional
       * @param {string} body.verifiedDate Optional
       * @param {string} body.locationName Optional
       * @param {array} body.supervisorUserIds Optional
       * @param {string} body.dateCreated Required
       * @param {string} body.dateModified Required
       * @param {string} body.userCreated Optional
       * @param {string} body.userModified Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      update({ hourLogId, matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/hourlogs/${matchId}/${hourLogId}`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * hourLog.verify
       * PUT /api/hourlogs/{matchId}/verify/{hourLogId}/{verified}
       * @param {object} parameters
       * @param {number} parameters.hourLogId Required
       * @param {number} parameters.matchId Required
       * @param {boolean} parameters.verified Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      verify({ hourLogId, matchId, verified } = {}, options = {}) {
        const uri = encodePath`/api/hourlogs/${matchId}/verify/${hourLogId}/${verified}`
        return this.api.fetch(uri, 'PUT', null, null, options)
      },
    }

    this.hourLogReport = {
      api: this,

      /**
       * hourLogReport.exportReport
       * POST /api/reports/hour-logs/export
       * @param {object} body `HourLogCriteria` Required
       * @param {string} body.keyword Optional
       * @param {object} body.type `HourType` Optional
       * @param {string} body.startDate Required
       * @param {string} body.endDate Required
       * @param {array} body.filters `FilterParameter` Optional
       * @param {string} body.sortProperty Optional
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      exportReport(body, options = {}) {
        const uri = '/api/reports/hour-logs/export'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * hourLogReport.run
       * POST /api/reports/hour-logs
       * @param {object} body `HourLogCriteria` Required
       * @param {string} body.keyword Optional
       * @param {object} body.type `HourType` Optional
       * @param {string} body.startDate Required
       * @param {string} body.endDate Required
       * @param {array} body.filters `FilterParameter` Optional
       * @param {string} body.sortProperty Optional
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      run(body, options = {}) {
        const uri = '/api/reports/hour-logs'
        return this.api.fetch(uri, 'POST', null, body, options)
      },
    }

    this.identity = {
      api: this,

      /**
       * identity.get
       * GET /api/identity
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      get(options = {}) {
        const uri = '/api/identity'
        return this.api.fetch(uri, 'GET', null, null, options)
      },
    }

    this.image = {
      api: this,

      /**
       * image.upload
       * POST /api/images/{prefix}/upload
       * @param {object} parameters
       * @param {string} parameters.prefix Required
       * @param {object} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      upload({ prefix } = {}, body, options = {}) {
        const uri = encodePath`/api/images/${prefix}/upload`
        options = { ...options, isFileUpload: true }
        return this.api.fetch(uri, 'POST', null, body, options)
      },
    }

    this.impersonation = {
      api: this,

      /**
       * impersonation.startImpersonating
       * PUT /api/impersonation
       * @param {object} parameters
       * @param {string} parameters.userId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      startImpersonating({ userId } = {}, options = {}) {
        const uri = '/api/impersonation'
        return this.api.fetch(uri, 'PUT', { userId }, null, options)
      },

      /**
       * impersonation.stopImpersonating
       * DELETE /api/impersonation
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      stopImpersonating(options = {}) {
        const uri = '/api/impersonation'
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },
    }

    this.importFile = {
      api: this,

      /**
       * importFile.canDelete
       * GET /api/import-files/{fileId}/can-delete
       * @param {object} parameters
       * @param {number} parameters.fileId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      canDelete({ fileId } = {}, options = {}) {
        const uri = encodePath`/api/import-files/${fileId}/can-delete`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * importFile.export
       * POST /api/import-files/export
       * @param {object} body `ExportImportFilesRequest` Required
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `ImportFileListProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      export(body, options = {}) {
        const uri = '/api/import-files/export'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * importFile.get
       * GET /api/import-files/{fileUploadId}
       * @param {object} parameters
       * @param {number} parameters.fileUploadId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      get({ fileUploadId } = {}, options = {}) {
        const uri = encodePath`/api/import-files/${fileUploadId}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * importFile.getEmptyStaffSheet
       * GET /api/import-files/empty/staff
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getEmptyStaffSheet(options = {}) {
        const uri = '/api/import-files/empty/staff'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * importFile.list
       * POST /api/import-files/list
       * @param {object} body `ListImportFilesRequestBody` Required
       * @param {boolean} body.excludeTotalCount Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {array} body.selectedFileUploadIds Optional
       * @param {object} body.sortProperty `ImportFileListProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      list(body, options = {}) {
        const uri = '/api/import-files/list'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * importFile.softDelete
       * DELETE /api/import-files/{fileId}
       * @param {object} parameters
       * @param {number} parameters.fileId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      softDelete({ fileId } = {}, options = {}) {
        const uri = encodePath`/api/import-files/${fileId}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },
    }

    this.importFileFilterOptions = {
      api: this,

      /**
       * importFileFilterOptions.getOwnerOrgs
       * GET /api/filter-options/for-import-files/owner-orgs
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getOwnerOrgs(options = {}) {
        const uri = '/api/filter-options/for-import-files/owner-orgs'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * importFileFilterOptions.getOwnerUsers
       * GET /api/filter-options/for-import-files/owner-users
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getOwnerUsers(options = {}) {
        const uri = '/api/filter-options/for-import-files/owner-users'
        return this.api.fetch(uri, 'GET', null, null, options)
      },
    }

    this.importStaff = {
      api: this,

      /**
       * importStaff.addImportStaff
       * POST /api/import-staff/{fileUploadId}/add
       * @param {object} parameters
       * @param {number} parameters.fileUploadId Required
       * @param {array} body `AddOrgStaffRequest` Required
       * @param {number} body.rowNumber Optional
       * @param {string} body.email Optional
       * @param {number} body.importStaffId Optional
       * @param {string} body.legalFirstName Optional
       * @param {string} body.legalLastName Optional
       * @param {string} body.customMessage Optional
       * @param {string} body.orgName Optional
       * @param {number} body.staffId Optional
       * @param {number} body.orgId Optional
       * @param {string} body.userId Required
       * @param {string} body.title Optional
       * @param {string} body.externalId Optional
       * @param {array} body.orgStaffRoles `UpsertStaffRoleRequest` Optional
       * @param {string} body.customFieldValuesJson Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addImportStaff({ fileUploadId } = {}, body, options = {}) {
        const uri = encodePath`/api/import-staff/${fileUploadId}/add`
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * importStaff.download
       * GET /api/import-staff/{fileUploadId}/download
       * @param {object} parameters
       * @param {number} parameters.fileUploadId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      download({ fileUploadId } = {}, options = {}) {
        const uri = encodePath`/api/import-staff/${fileUploadId}/download`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * importStaff.export
       * POST /api/import-staff/export
       * @param {object} body `ExportImportStaffRequest` Required
       * @param {number} body.fileUploadId Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `ImportStaffListProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      export(body, options = {}) {
        const uri = '/api/import-staff/export'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * importStaff.importStaff
       * POST /api/import-staff
       * @param {object} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      importStaff(body, options = {}) {
        const uri = '/api/import-staff'
        options = { ...options, isFileUpload: true }
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * importStaff.list
       * POST /api/import-staff/list
       * @param {object} body `ListImportStaffRequestBody` Required
       * @param {number} body.fileUploadId Optional
       * @param {boolean} body.excludeTotalCount Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {array} body.selectedFileUploadId Optional
       * @param {object} body.sortProperty `ImportStaffListProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      list(body, options = {}) {
        const uri = '/api/import-staff/list'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * importStaff.softDelete
       * DELETE /api/import-staff/{fileUploadId}/delete
       * @param {object} parameters
       * @param {number} parameters.fileUploadId Required
       * @param {array} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      softDelete({ fileUploadId } = {}, body, options = {}) {
        const uri = encodePath`/api/import-staff/${fileUploadId}/delete`
        return this.api.fetch(uri, 'DELETE', null, body, options)
      },

      /**
       * importStaff.undoSoftDelete
       * PUT /api/import-staff/{fileUploadId}/undelete
       * @param {object} parameters
       * @param {number} parameters.fileUploadId Required
       * @param {array} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      undoSoftDelete({ fileUploadId } = {}, body, options = {}) {
        const uri = encodePath`/api/import-staff/${fileUploadId}/undelete`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.match = {
      api: this,

      /**
       * match.addComment
       * POST /api/matches/{matchId}/comment
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `SaveMatchCommentViewModel` Required
       * @param {array} body.matchRoles `MatchRole` Optional
       * @param {array} body.userIds Optional
       * @param {string} body.message Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addComment({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/${matchId}/comment`
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * match.addMatches
       * POST /api/matches
       * @param {object} body `AddMatchesApiViewModelRequest` Required
       * @param {boolean} body.skipMatchUsageValidation Optional
       * @param {boolean} body.ignoreOrgMatchPreferences Optional
       * @param {array} body.matches `AddMatchApiViewModelRequest` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addMatches(body, options = {}) {
        const uri = '/api/matches'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * match.changeMatch
       * PUT /api/matches/{matchId}
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `UpdateMatchApiViewModelRequest` Required
       * @param {number} body.requestId Optional
       * @param {number} body.matchId Optional
       * @param {number} body.matchChangeDetailId Optional
       * @param {string} body.comment Optional
       * @param {string} body.dueDate Optional
       * @param {object} body.changes `MatchFormChangesViewModel` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      changeMatch({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/${matchId}`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * match.closeMatch
       * DELETE /api/matches/{matchId}
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `CloseMatchApiViewModelRequest` Required
       * @param {object} body.closedReason `CloseReason` Optional
       * @param {string} body.comment Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      closeMatch({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/${matchId}`
        return this.api.fetch(uri, 'DELETE', null, body, options)
      },

      /**
       * match.getMatchDetails
       * GET /api/matches/{matchId}
       * @param {object} parameters
       * @param {string} parameters.apiVersion Optional
       * @param {number} parameters.matchId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getMatchDetails({ apiVersion, matchId } = {}, options = {}) {
        const uri = encodePath`/api/matches/${matchId}`
        return this.api.fetch(uri, 'GET', { apiVersion }, null, options)
      },

      /**
       * match.listMatches
       * POST /api/matches/list
       * @param {object} body `ListMatchesRequest` Required
       * @param {string} body.apiVersion Optional
       * @param {number} body.matchId Optional
       * @param {boolean} body.includeCustomFields Optional
       * @param {boolean} body.includeAgreements Optional
       * @param {boolean} body.includeCustomTags Optional
       * @param {boolean} body.includeMatchUsers Optional
       * @param {boolean} body.includeUsers Optional
       * @param {boolean} body.includeHourLogs Optional
       * @param {boolean} body.includeSteps Optional
       * @param {boolean} body.includeStepSubmissions Optional
       * @param {boolean} body.includeShiftsWithDeletedLocations Optional
       * @param {boolean} body.excludeTotalCount Optional
       * @param {array} body.selectedMatchIds Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `MatchListSortProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listMatches(body, options = {}) {
        const uri = '/api/matches/list'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * match.switchCapacity
       * PUT /api/matches/switchCapacity
       * @param {object} body `SwitchMatchCapacityRequest` Required
       * @param {number} body.capacityId Optional
       * @param {array} body.matchIds Optional
       * @param {boolean} body.autoCategorize Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      switchCapacity(body, options = {}) {
        const uri = '/api/matches/switchCapacity'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.matchFilterOptions = {
      api: this,

      /**
       * matchFilterOptions.getClinicCoordinatorUsers
       * GET /api/filter-options/for-matches/clinic-coordinator-users
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getClinicCoordinatorUsers(options = {}) {
        const uri = '/api/filter-options/for-matches/clinic-coordinator-users'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * matchFilterOptions.getCustomTags
       * GET /api/filter-options/for-matches/custom-tags
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getCustomTags(options = {}) {
        const uri = '/api/filter-options/for-matches/custom-tags'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * matchFilterOptions.getDisciplines
       * GET /api/filter-options/for-matches/disciplines
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getDisciplines(options = {}) {
        const uri = '/api/filter-options/for-matches/disciplines'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * matchFilterOptions.getOrgs
       * GET /api/filter-options/for-matches/orgs
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getOrgs(options = {}) {
        const uri = '/api/filter-options/for-matches/orgs'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * matchFilterOptions.getPreceptorUsers
       * GET /api/filter-options/for-matches/preceptor-users
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getPreceptorUsers(options = {}) {
        const uri = '/api/filter-options/for-matches/preceptor-users'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * matchFilterOptions.getRotationTypes
       * GET /api/filter-options/for-matches/rotation-types
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getRotationTypes(options = {}) {
        const uri = '/api/filter-options/for-matches/rotation-types'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * matchFilterOptions.getSchoolCoordinatorUsers
       * GET /api/filter-options/for-matches/school-coordinator-users
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getSchoolCoordinatorUsers(options = {}) {
        const uri = '/api/filter-options/for-matches/school-coordinator-users'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * matchFilterOptions.getSchoolFacultyUsers
       * GET /api/filter-options/for-matches/school-faculty-users
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getSchoolFacultyUsers(options = {}) {
        const uri = '/api/filter-options/for-matches/school-faculty-users'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * matchFilterOptions.getSchools
       * GET /api/filter-options/for-matches/schools
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getSchools(options = {}) {
        const uri = '/api/filter-options/for-matches/schools'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * matchFilterOptions.getSteps
       * GET /api/filter-options/for-matches/steps
       * @param {object} parameters
       * @param {boolean} parameters.onlyOurOrgSteps Optional
       * @param {object} parameters.stepInputType `StepInputType` Optional
       * @param {object} parameters.stepType `StepType` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getSteps({ onlyOurOrgSteps, stepInputType, stepType } = {}, options = {}) {
        const uri = '/api/filter-options/for-matches/steps'
        return this.api.fetch(uri, 'GET', { onlyOurOrgSteps, stepInputType, stepType }, null, options)
      },

      /**
       * matchFilterOptions.getStepSubmissionTargetUsers
       * GET /api/filter-options/for-matches/step-submission-target-users
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getStepSubmissionTargetUsers(options = {}) {
        const uri = '/api/filter-options/for-matches/step-submission-target-users'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * matchFilterOptions.getTeams
       * GET /api/filter-options/for-matches/teams
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getTeams(options = {}) {
        const uri = '/api/filter-options/for-matches/teams'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * matchFilterOptions.getWaitlistedByUsers
       * GET /api/filter-options/for-matches/waitlisted-by-users
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getWaitlistedByUsers(options = {}) {
        const uri = '/api/filter-options/for-matches/waitlisted-by-users'
        return this.api.fetch(uri, 'GET', null, null, options)
      },
    }

    this.matchPreference = {
      api: this,

      /**
       * matchPreference.getMatchPreferences
       * GET /api/matchpreferences/inspect
       * @param {object} parameters
       * @param {number} parameters.orgId1 Optional
       * @param {number} parameters.orgId2 Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getMatchPreferences({ orgId1, orgId2 } = {}, options = {}) {
        const uri = '/api/matchpreferences/inspect'
        return this.api.fetch(uri, 'GET', { orgId1, orgId2 }, null, options)
      },

      /**
       * matchPreference.getPreferences
       * GET /api/matchpreferences/{orgId}
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getPreferences({ orgId } = {}, options = {}) {
        const uri = encodePath`/api/matchpreferences/${orgId}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * matchPreference.updatePreferences
       * PUT /api/matchpreferences/{orgId}
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} body `MatchPreferenceUpdateVM` Required
       * @param {array} body.preferences `MatchPreferenceUpdateViewModel` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      updatePreferences({ orgId } = {}, body, options = {}) {
        const uri = encodePath`/api/matchpreferences/${orgId}`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.matchProvider = {
      api: this,

      /**
       * matchProvider.acceptChangeMatch
       * PUT /api/matches/provider/{matchId}/acceptChange
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `UpdateMatchApiViewModelRequest` Required
       * @param {number} body.requestId Optional
       * @param {number} body.matchId Optional
       * @param {number} body.matchChangeDetailId Optional
       * @param {string} body.comment Optional
       * @param {string} body.dueDate Optional
       * @param {object} body.changes `MatchFormChangesViewModel` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      acceptChangeMatch({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/provider/${matchId}/acceptChange`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * matchProvider.acceptMatch
       * PUT /api/matches/provider/{matchId}/accept
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `ProviderAcceptMatchViewModel` Required
       * @param {boolean} body.skipMatchUsageValidation Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      acceptMatch({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/provider/${matchId}/accept`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * matchProvider.addComment
       * POST /api/matches/provider/{matchId}/activity
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `SaveMatchCommentViewModel` Required
       * @param {array} body.matchRoles `MatchRole` Optional
       * @param {array} body.userIds Optional
       * @param {string} body.message Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addComment({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/provider/${matchId}/activity`
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchProvider.addRotation
       * POST /api/matches/provider
       * @param {object} body `AddRotationViewModel` Required
       * @param {array} body.studentIds Optional
       * @param {number} body.placeholderStudentCount Optional
       * @param {number} body.courseId Optional
       * @param {number} body.shiftId Optional
       * @param {array} body.schoolFacultyIds Optional
       * @param {array} body.preceptorIds Optional
       * @param {number} body.capacityId Required
       * @param {number} body.guestOrgId Optional
       * @param {string} body.startDate Required
       * @param {string} body.endDate Required
       * @param {array} body.matchDays `MatchDayViewModel` Optional
       * @param {array} body.matchDayConfigs `MatchDayConfigRequest` Optional
       * @param {string} body.additionalComments Optional
       * @param {string} body.syllabus Optional
       * @param {boolean} body.createGroupRotation Optional
       * @param {boolean} body.skipMatchUsageValidation Optional
       * @param {string} body.displayName Optional
       * @param {string} body.onboardingDeadline Optional
       * @param {array} body.customTagNames Optional
       * @param {array} body.customTagIds Optional
       * @param {boolean} body.ignoreOrgMatchPreferences Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addRotation(body, options = {}) {
        const uri = '/api/matches/provider'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchProvider.addToWaitlist
       * PUT /api/matches/provider/{matchId}/waitlist
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `WaitlistMatchViewModel` Required
       * @param {number} body.matchId Optional
       * @param {number} body.waitlistExpireWithinDays Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addToWaitlist({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/provider/${matchId}/waitlist`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * matchProvider.changeMatch
       * PUT /api/matches/provider/{matchId}
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `UpdateMatchApiViewModelRequest` Required
       * @param {number} body.requestId Optional
       * @param {number} body.matchId Optional
       * @param {number} body.matchChangeDetailId Optional
       * @param {string} body.comment Optional
       * @param {string} body.dueDate Optional
       * @param {object} body.changes `MatchFormChangesViewModel` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      changeMatch({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/provider/${matchId}`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * matchProvider.closeMatch
       * PUT /api/matches/provider/{matchId}/close
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `CloseMatchViewModel` Required
       * @param {number} body.matchId Optional
       * @param {object} body.closedReason `CloseReason` Optional
       * @param {string} body.otherClosedReason Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      closeMatch({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/provider/${matchId}/close`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * matchProvider.deleteChangeMatch
       * DELETE /api/matches/provider/{matchId}/change/{matchChangeDetailId}
       * @param {object} parameters
       * @param {number} parameters.matchChangeDetailId Required
       * @param {number} parameters.matchId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      deleteChangeMatch({ matchChangeDetailId, matchId } = {}, options = {}) {
        const uri = encodePath`/api/matches/provider/${matchId}/change/${matchChangeDetailId}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },

      /**
       * matchProvider.editComment
       * POST /api/matches/provider/{matchId}/activity/edit/{matchCommentId}
       * @param {object} parameters
       * @param {number} parameters.matchCommentId Required
       * @param {number} parameters.matchId Required
       * @param {object} body `SaveMatchCommentViewModel` Required
       * @param {array} body.matchRoles `MatchRole` Optional
       * @param {array} body.userIds Optional
       * @param {string} body.message Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      editComment({ matchCommentId, matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/provider/${matchId}/activity/edit/${matchCommentId}`
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchProvider.exportCalendar
       * POST /api/matches/provider/calendar/export
       * @param {object} body `MatchFilterParametersCalendar` Required
       * @param {string} body.startDate Required
       * @param {string} body.endDate Required
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `MatchListSortProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      exportCalendar(body, options = {}) {
        const uri = '/api/matches/provider/calendar/export'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchProvider.exportGroups
       * POST /api/matches/provider/groups/export
       * @param {object} body `SortableFilterablePaginationParameters<MatchListSortProperty>` Required
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `MatchListSortProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      exportGroups(body, options = {}) {
        const uri = '/api/matches/provider/groups/export'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchProvider.get
       * GET /api/matches/provider/{matchId}
       * @param {object} parameters
       * @param {string} parameters.endDate Optional
       * @param {number} parameters.matchId Required
       * @param {string} parameters.startDate Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      get({ endDate, matchId, startDate } = {}, options = {}) {
        const uri = encodePath`/api/matches/provider/${matchId}`
        return this.api.fetch(uri, 'GET', { endDate, startDate }, null, options)
      },

      /**
       * matchProvider.getCalendar
       * POST /api/matches/provider/calendar
       * @param {object} body `MatchFilterParametersCalendar` Required
       * @param {string} body.startDate Required
       * @param {string} body.endDate Required
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `MatchListSortProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getCalendar(body, options = {}) {
        const uri = '/api/matches/provider/calendar'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchProvider.getChangeDetail
       * GET /api/matches/provider/{matchId}/change-detail/{matchChangeDetailId}
       * @param {object} parameters
       * @param {number} parameters.matchChangeDetailId Required
       * @param {string} parameters.matchId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getChangeDetail({ matchChangeDetailId, matchId } = {}, options = {}) {
        const uri = encodePath`/api/matches/provider/${matchId}/change-detail/${matchChangeDetailId}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * matchProvider.getGroups
       * POST /api/matches/provider/groups
       * @param {object} body `ListMatchesRequest` Required
       * @param {string} body.apiVersion Optional
       * @param {number} body.matchId Optional
       * @param {boolean} body.includeCustomFields Optional
       * @param {boolean} body.includeAgreements Optional
       * @param {boolean} body.includeCustomTags Optional
       * @param {boolean} body.includeMatchUsers Optional
       * @param {boolean} body.includeUsers Optional
       * @param {boolean} body.includeHourLogs Optional
       * @param {boolean} body.includeSteps Optional
       * @param {boolean} body.includeStepSubmissions Optional
       * @param {boolean} body.includeShiftsWithDeletedLocations Optional
       * @param {boolean} body.excludeTotalCount Optional
       * @param {array} body.selectedMatchIds Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `MatchListSortProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getGroups(body, options = {}) {
        const uri = '/api/matches/provider/groups'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchProvider.getGroupsPage
       * POST /api/matches/provider/groups/{status}
       * @param {object} parameters
       * @param {object} parameters.status `MatchStatus` Required
       * @param {object} body `ListMatchesRequest` Required
       * @param {string} body.apiVersion Optional
       * @param {number} body.matchId Optional
       * @param {boolean} body.includeCustomFields Optional
       * @param {boolean} body.includeAgreements Optional
       * @param {boolean} body.includeCustomTags Optional
       * @param {boolean} body.includeMatchUsers Optional
       * @param {boolean} body.includeUsers Optional
       * @param {boolean} body.includeHourLogs Optional
       * @param {boolean} body.includeSteps Optional
       * @param {boolean} body.includeStepSubmissions Optional
       * @param {boolean} body.includeShiftsWithDeletedLocations Optional
       * @param {boolean} body.excludeTotalCount Optional
       * @param {array} body.selectedMatchIds Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `MatchListSortProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getGroupsPage({ status } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/provider/groups/${status}`
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchProvider.getListPage
       * POST /api/matches/provider/list
       * @param {object} body `ListMatchesRequest` Required
       * @param {string} body.apiVersion Optional
       * @param {number} body.matchId Optional
       * @param {boolean} body.includeCustomFields Optional
       * @param {boolean} body.includeAgreements Optional
       * @param {boolean} body.includeCustomTags Optional
       * @param {boolean} body.includeMatchUsers Optional
       * @param {boolean} body.includeUsers Optional
       * @param {boolean} body.includeHourLogs Optional
       * @param {boolean} body.includeSteps Optional
       * @param {boolean} body.includeStepSubmissions Optional
       * @param {boolean} body.includeShiftsWithDeletedLocations Optional
       * @param {boolean} body.excludeTotalCount Optional
       * @param {array} body.selectedMatchIds Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `MatchListSortProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getListPage(body, options = {}) {
        const uri = '/api/matches/provider/list'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchProvider.listActivity
       * GET /api/matches/provider/{matchId}/activity
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listActivity({ matchId } = {}, options = {}) {
        const uri = encodePath`/api/matches/provider/${matchId}/activity`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * matchProvider.listAllSubmissionsByStepAndUser
       * GET /api/matches/provider/submissionsByUserAndStep
       * @param {object} parameters
       * @param {number} parameters.matchId Optional
       * @param {number} parameters.stepId Optional
       * @param {string} parameters.targetUserId Optional
       * @param {string} parameters.userId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listAllSubmissionsByStepAndUser({ matchId, stepId, targetUserId, userId } = {}, options = {}) {
        const uri = '/api/matches/provider/submissionsByUserAndStep'
        return this.api.fetch(uri, 'GET', { matchId, stepId, targetUserId, userId }, null, options)
      },

      /**
       * matchProvider.pause
       * PUT /api/matches/provider/{matchId}/pause
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `PauseMatchViewModel` Required
       * @param {number} body.matchId Optional
       * @param {string} body.reason Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      pause({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/provider/${matchId}/pause`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * matchProvider.proposeChangeMatch
       * PUT /api/matches/provider/{matchId}/change
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `UpdateMatchApiViewModelRequest` Required
       * @param {number} body.requestId Optional
       * @param {number} body.matchId Optional
       * @param {number} body.matchChangeDetailId Optional
       * @param {string} body.comment Optional
       * @param {string} body.dueDate Optional
       * @param {object} body.changes `MatchFormChangesViewModel` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      proposeChangeMatch({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/provider/${matchId}/change`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * matchProvider.rejectChangeMatch
       * PUT /api/matches/provider/{matchId}/rejectChange
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `RejectMatchChange` Required
       * @param {number} body.matchChangeDetailId Optional
       * @param {string} body.rejectReason Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      rejectChangeMatch({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/provider/${matchId}/rejectChange`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * matchProvider.removeSelf
       * PUT /api/matches/provider/{matchId}/removeSelf/{role}
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} parameters.role `MatchRole` Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      removeSelf({ matchId, role } = {}, options = {}) {
        const uri = encodePath`/api/matches/provider/${matchId}/removeSelf/${role}`
        return this.api.fetch(uri, 'PUT', null, null, options)
      },

      /**
       * matchProvider.reopenMatch
       * PUT /api/matches/provider/{matchId}/reopen
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `ReopenMatchViewModel` Required
       * @param {string} body.startDate Optional
       * @param {string} body.endDate Optional
       * @param {number} body.extendByDays Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      reopenMatch({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/provider/${matchId}/reopen`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * matchProvider.unpause
       * PUT /api/matches/provider/{matchId}/unpause
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `UnpauseMatchViewModel` Required
       * @param {string} body.reason Optional
       * @param {boolean} body.completing Optional
       * @param {string} body.startDate Optional
       * @param {string} body.endDate Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      unpause({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/provider/${matchId}/unpause`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.matchSchool = {
      api: this,

      /**
       * matchSchool.acceptChangeMatch
       * PUT /api/matches/school/{matchId}/acceptChange
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `UpdateMatchApiViewModelRequest` Required
       * @param {number} body.requestId Optional
       * @param {number} body.matchId Optional
       * @param {number} body.matchChangeDetailId Optional
       * @param {string} body.comment Optional
       * @param {string} body.dueDate Optional
       * @param {object} body.changes `MatchFormChangesViewModel` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      acceptChangeMatch({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/school/${matchId}/acceptChange`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * matchSchool.acceptMatch
       * PUT /api/matches/school/{matchId}/accept
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      acceptMatch({ matchId } = {}, options = {}) {
        const uri = encodePath`/api/matches/school/${matchId}/accept`
        return this.api.fetch(uri, 'PUT', null, null, options)
      },

      /**
       * matchSchool.addComment
       * POST /api/matches/school/{matchId}/activity
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `SaveMatchCommentViewModel` Required
       * @param {array} body.matchRoles `MatchRole` Optional
       * @param {array} body.userIds Optional
       * @param {string} body.message Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addComment({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/school/${matchId}/activity`
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchSchool.addRotation
       * POST /api/matches/school/school
       * @param {object} body `AddRotationViewModel` Required
       * @param {array} body.studentIds Optional
       * @param {number} body.placeholderStudentCount Optional
       * @param {number} body.courseId Optional
       * @param {number} body.shiftId Optional
       * @param {array} body.schoolFacultyIds Optional
       * @param {array} body.preceptorIds Optional
       * @param {number} body.capacityId Required
       * @param {number} body.guestOrgId Optional
       * @param {string} body.startDate Required
       * @param {string} body.endDate Required
       * @param {array} body.matchDays `MatchDayViewModel` Optional
       * @param {array} body.matchDayConfigs `MatchDayConfigRequest` Optional
       * @param {string} body.additionalComments Optional
       * @param {string} body.syllabus Optional
       * @param {boolean} body.createGroupRotation Optional
       * @param {boolean} body.skipMatchUsageValidation Optional
       * @param {string} body.displayName Optional
       * @param {string} body.onboardingDeadline Optional
       * @param {array} body.customTagNames Optional
       * @param {array} body.customTagIds Optional
       * @param {boolean} body.ignoreOrgMatchPreferences Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addRotation(body, options = {}) {
        const uri = '/api/matches/school/school'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchSchool.changeMatch
       * PUT /api/matches/school/{matchId}
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `UpdateMatchApiViewModelRequest` Required
       * @param {number} body.requestId Optional
       * @param {number} body.matchId Optional
       * @param {number} body.matchChangeDetailId Optional
       * @param {string} body.comment Optional
       * @param {string} body.dueDate Optional
       * @param {object} body.changes `MatchFormChangesViewModel` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      changeMatch({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/school/${matchId}`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * matchSchool.closeMatch
       * PUT /api/matches/school/{matchId}/close
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `CloseMatchViewModel` Required
       * @param {number} body.matchId Optional
       * @param {object} body.closedReason `CloseReason` Optional
       * @param {string} body.otherClosedReason Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      closeMatch({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/school/${matchId}/close`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * matchSchool.deleteChangeMatch
       * DELETE /api/matches/school/{matchId}/change/{matchChangeDetailId}
       * @param {object} parameters
       * @param {number} parameters.matchChangeDetailId Required
       * @param {number} parameters.matchId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      deleteChangeMatch({ matchChangeDetailId, matchId } = {}, options = {}) {
        const uri = encodePath`/api/matches/school/${matchId}/change/${matchChangeDetailId}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },

      /**
       * matchSchool.editComment
       * POST /api/matches/school/{matchId}/activity/edit/{matchCommentId}
       * @param {object} parameters
       * @param {number} parameters.matchCommentId Required
       * @param {number} parameters.matchId Required
       * @param {object} body `SaveMatchCommentViewModel` Required
       * @param {array} body.matchRoles `MatchRole` Optional
       * @param {array} body.userIds Optional
       * @param {string} body.message Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      editComment({ matchCommentId, matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/school/${matchId}/activity/edit/${matchCommentId}`
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchSchool.exportCalendar
       * POST /api/matches/school/calendar/export
       * @param {object} body `MatchFilterParametersCalendar` Required
       * @param {string} body.startDate Required
       * @param {string} body.endDate Required
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `MatchListSortProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      exportCalendar(body, options = {}) {
        const uri = '/api/matches/school/calendar/export'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchSchool.exportGroups
       * POST /api/matches/school/groups/export
       * @param {object} body `SortableFilterablePaginationParameters<MatchListSortProperty>` Required
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `MatchListSortProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      exportGroups(body, options = {}) {
        const uri = '/api/matches/school/groups/export'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchSchool.get
       * GET /api/matches/school/{matchId}
       * @param {object} parameters
       * @param {string} parameters.endDate Optional
       * @param {number} parameters.matchId Required
       * @param {string} parameters.startDate Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      get({ endDate, matchId, startDate } = {}, options = {}) {
        const uri = encodePath`/api/matches/school/${matchId}`
        return this.api.fetch(uri, 'GET', { endDate, startDate }, null, options)
      },

      /**
       * matchSchool.getCalendar
       * POST /api/matches/school/calendar
       * @param {object} body `MatchFilterParametersCalendar` Required
       * @param {string} body.startDate Required
       * @param {string} body.endDate Required
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `MatchListSortProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getCalendar(body, options = {}) {
        const uri = '/api/matches/school/calendar'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchSchool.getChangeDetail
       * GET /api/matches/school/{matchId}/change-detail/{matchChangeDetailId}
       * @param {object} parameters
       * @param {number} parameters.matchChangeDetailId Required
       * @param {string} parameters.matchId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getChangeDetail({ matchChangeDetailId, matchId } = {}, options = {}) {
        const uri = encodePath`/api/matches/school/${matchId}/change-detail/${matchChangeDetailId}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * matchSchool.getGroups
       * POST /api/matches/school/groups
       * @param {object} body `ListMatchesRequest` Required
       * @param {string} body.apiVersion Optional
       * @param {number} body.matchId Optional
       * @param {boolean} body.includeCustomFields Optional
       * @param {boolean} body.includeAgreements Optional
       * @param {boolean} body.includeCustomTags Optional
       * @param {boolean} body.includeMatchUsers Optional
       * @param {boolean} body.includeUsers Optional
       * @param {boolean} body.includeHourLogs Optional
       * @param {boolean} body.includeSteps Optional
       * @param {boolean} body.includeStepSubmissions Optional
       * @param {boolean} body.includeShiftsWithDeletedLocations Optional
       * @param {boolean} body.excludeTotalCount Optional
       * @param {array} body.selectedMatchIds Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `MatchListSortProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getGroups(body, options = {}) {
        const uri = '/api/matches/school/groups'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchSchool.getGroupsPage
       * POST /api/matches/school/groups/{status}
       * @param {object} parameters
       * @param {object} parameters.status `MatchStatus` Required
       * @param {object} body `ListMatchesRequest` Required
       * @param {string} body.apiVersion Optional
       * @param {number} body.matchId Optional
       * @param {boolean} body.includeCustomFields Optional
       * @param {boolean} body.includeAgreements Optional
       * @param {boolean} body.includeCustomTags Optional
       * @param {boolean} body.includeMatchUsers Optional
       * @param {boolean} body.includeUsers Optional
       * @param {boolean} body.includeHourLogs Optional
       * @param {boolean} body.includeSteps Optional
       * @param {boolean} body.includeStepSubmissions Optional
       * @param {boolean} body.includeShiftsWithDeletedLocations Optional
       * @param {boolean} body.excludeTotalCount Optional
       * @param {array} body.selectedMatchIds Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `MatchListSortProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getGroupsPage({ status } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/school/groups/${status}`
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchSchool.getListPage
       * POST /api/matches/school/list
       * @param {object} body `ListMatchesRequest` Required
       * @param {string} body.apiVersion Optional
       * @param {number} body.matchId Optional
       * @param {boolean} body.includeCustomFields Optional
       * @param {boolean} body.includeAgreements Optional
       * @param {boolean} body.includeCustomTags Optional
       * @param {boolean} body.includeMatchUsers Optional
       * @param {boolean} body.includeUsers Optional
       * @param {boolean} body.includeHourLogs Optional
       * @param {boolean} body.includeSteps Optional
       * @param {boolean} body.includeStepSubmissions Optional
       * @param {boolean} body.includeShiftsWithDeletedLocations Optional
       * @param {boolean} body.excludeTotalCount Optional
       * @param {array} body.selectedMatchIds Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `MatchListSortProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getListPage(body, options = {}) {
        const uri = '/api/matches/school/list'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchSchool.listActivity
       * GET /api/matches/school/{matchId}/activity
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listActivity({ matchId } = {}, options = {}) {
        const uri = encodePath`/api/matches/school/${matchId}/activity`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * matchSchool.listAllSubmissionsByStepAndUser
       * GET /api/matches/school/submissionsByUserAndStep
       * @param {object} parameters
       * @param {number} parameters.matchId Optional
       * @param {number} parameters.stepId Optional
       * @param {string} parameters.targetUserId Optional
       * @param {string} parameters.userId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listAllSubmissionsByStepAndUser({ matchId, stepId, targetUserId, userId } = {}, options = {}) {
        const uri = '/api/matches/school/submissionsByUserAndStep'
        return this.api.fetch(uri, 'GET', { matchId, stepId, targetUserId, userId }, null, options)
      },

      /**
       * matchSchool.pause
       * PUT /api/matches/school/{matchId}/pause
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `PauseMatchViewModel` Required
       * @param {number} body.matchId Optional
       * @param {string} body.reason Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      pause({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/school/${matchId}/pause`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * matchSchool.proposeChangeMatch
       * PUT /api/matches/school/{matchId}/change
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `UpdateMatchApiViewModelRequest` Required
       * @param {number} body.requestId Optional
       * @param {number} body.matchId Optional
       * @param {number} body.matchChangeDetailId Optional
       * @param {string} body.comment Optional
       * @param {string} body.dueDate Optional
       * @param {object} body.changes `MatchFormChangesViewModel` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      proposeChangeMatch({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/school/${matchId}/change`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * matchSchool.rejectChangeMatch
       * PUT /api/matches/school/{matchId}/rejectChange
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `RejectMatchChange` Required
       * @param {number} body.matchChangeDetailId Optional
       * @param {string} body.rejectReason Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      rejectChangeMatch({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/school/${matchId}/rejectChange`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * matchSchool.removeSelf
       * PUT /api/matches/school/{matchId}/removeSelf/{role}
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} parameters.role `MatchRole` Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      removeSelf({ matchId, role } = {}, options = {}) {
        const uri = encodePath`/api/matches/school/${matchId}/removeSelf/${role}`
        return this.api.fetch(uri, 'PUT', null, null, options)
      },

      /**
       * matchSchool.reopenMatch
       * PUT /api/matches/school/{matchId}/reopen
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `ReopenMatchViewModel` Required
       * @param {string} body.startDate Optional
       * @param {string} body.endDate Optional
       * @param {number} body.extendByDays Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      reopenMatch({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/school/${matchId}/reopen`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * matchSchool.submitMatchRequest
       * PUT /api/matches/school/{matchId}/submit
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `UpdateMatchApiViewModelRequest` Required
       * @param {number} body.requestId Optional
       * @param {number} body.matchId Optional
       * @param {number} body.matchChangeDetailId Optional
       * @param {string} body.comment Optional
       * @param {string} body.dueDate Optional
       * @param {object} body.changes `MatchFormChangesViewModel` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      submitMatchRequest({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/school/${matchId}/submit`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * matchSchool.unpause
       * PUT /api/matches/school/{matchId}/unpause
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `UnpauseMatchViewModel` Required
       * @param {string} body.reason Optional
       * @param {boolean} body.completing Optional
       * @param {string} body.startDate Optional
       * @param {string} body.endDate Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      unpause({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/school/${matchId}/unpause`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.matchStudent = {
      api: this,

      /**
       * matchStudent.acceptChangeMatch
       * PUT /api/matches/student/{matchId}/acceptChange
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `UpdateMatchApiViewModelRequest` Required
       * @param {number} body.requestId Optional
       * @param {number} body.matchId Optional
       * @param {number} body.matchChangeDetailId Optional
       * @param {string} body.comment Optional
       * @param {string} body.dueDate Optional
       * @param {object} body.changes `MatchFormChangesViewModel` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      acceptChangeMatch({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/student/${matchId}/acceptChange`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * matchStudent.acceptMatch
       * PUT /api/matches/student/{matchId}/accept
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      acceptMatch({ matchId } = {}, options = {}) {
        const uri = encodePath`/api/matches/student/${matchId}/accept`
        return this.api.fetch(uri, 'PUT', null, null, options)
      },

      /**
       * matchStudent.addComment
       * POST /api/matches/student/{matchId}/activity
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `SaveMatchCommentViewModel` Required
       * @param {array} body.matchRoles `MatchRole` Optional
       * @param {array} body.userIds Optional
       * @param {string} body.message Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addComment({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/student/${matchId}/activity`
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchStudent.beginMatchRequest
       * POST /api/matches/student
       * @param {object} body `BeginMatchRequestViewModel` Required
       * @param {number} body.capacityId Optional
       * @param {boolean} body.skipMatchUsageValidation Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      beginMatchRequest(body, options = {}) {
        const uri = '/api/matches/student'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchStudent.changeMatch
       * PUT /api/matches/student/{matchId}
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `UpdateMatchApiViewModelRequest` Required
       * @param {number} body.requestId Optional
       * @param {number} body.matchId Optional
       * @param {number} body.matchChangeDetailId Optional
       * @param {string} body.comment Optional
       * @param {string} body.dueDate Optional
       * @param {object} body.changes `MatchFormChangesViewModel` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      changeMatch({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/student/${matchId}`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * matchStudent.closeMatch
       * PUT /api/matches/student/{matchId}/close
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `CloseMatchViewModel` Required
       * @param {number} body.matchId Optional
       * @param {object} body.closedReason `CloseReason` Optional
       * @param {string} body.otherClosedReason Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      closeMatch({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/student/${matchId}/close`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * matchStudent.deleteChangeMatch
       * DELETE /api/matches/student/{matchId}/change/{matchChangeDetailId}
       * @param {object} parameters
       * @param {number} parameters.matchChangeDetailId Required
       * @param {number} parameters.matchId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      deleteChangeMatch({ matchChangeDetailId, matchId } = {}, options = {}) {
        const uri = encodePath`/api/matches/student/${matchId}/change/${matchChangeDetailId}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },

      /**
       * matchStudent.editComment
       * POST /api/matches/student/{matchId}/activity/edit/{matchCommentId}
       * @param {object} parameters
       * @param {number} parameters.matchCommentId Required
       * @param {number} parameters.matchId Required
       * @param {object} body `SaveMatchCommentViewModel` Required
       * @param {array} body.matchRoles `MatchRole` Optional
       * @param {array} body.userIds Optional
       * @param {string} body.message Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      editComment({ matchCommentId, matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/student/${matchId}/activity/edit/${matchCommentId}`
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchStudent.exportCalendar
       * POST /api/matches/student/calendar/export
       * @param {object} body `MatchFilterParametersCalendar` Required
       * @param {string} body.startDate Required
       * @param {string} body.endDate Required
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `MatchListSortProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      exportCalendar(body, options = {}) {
        const uri = '/api/matches/student/calendar/export'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchStudent.exportGroups
       * POST /api/matches/student/groups/export
       * @param {object} body `SortableFilterablePaginationParameters<MatchListSortProperty>` Required
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `MatchListSortProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      exportGroups(body, options = {}) {
        const uri = '/api/matches/student/groups/export'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchStudent.get
       * GET /api/matches/student/{matchId}
       * @param {object} parameters
       * @param {string} parameters.endDate Optional
       * @param {number} parameters.matchId Required
       * @param {string} parameters.startDate Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      get({ endDate, matchId, startDate } = {}, options = {}) {
        const uri = encodePath`/api/matches/student/${matchId}`
        return this.api.fetch(uri, 'GET', { endDate, startDate }, null, options)
      },

      /**
       * matchStudent.getActiveMatches
       * GET /api/matches/student/active
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getActiveMatches(options = {}) {
        const uri = '/api/matches/student/active'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * matchStudent.getCalendar
       * POST /api/matches/student/calendar
       * @param {object} body `MatchFilterParametersCalendar` Required
       * @param {string} body.startDate Required
       * @param {string} body.endDate Required
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `MatchListSortProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getCalendar(body, options = {}) {
        const uri = '/api/matches/student/calendar'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchStudent.getChangeDetail
       * GET /api/matches/student/{matchId}/change-detail/{matchChangeDetailId}
       * @param {object} parameters
       * @param {number} parameters.matchChangeDetailId Required
       * @param {string} parameters.matchId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getChangeDetail({ matchChangeDetailId, matchId } = {}, options = {}) {
        const uri = encodePath`/api/matches/student/${matchId}/change-detail/${matchChangeDetailId}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * matchStudent.getGroups
       * POST /api/matches/student/groups
       * @param {object} body `ListMatchesRequest` Required
       * @param {string} body.apiVersion Optional
       * @param {number} body.matchId Optional
       * @param {boolean} body.includeCustomFields Optional
       * @param {boolean} body.includeAgreements Optional
       * @param {boolean} body.includeCustomTags Optional
       * @param {boolean} body.includeMatchUsers Optional
       * @param {boolean} body.includeUsers Optional
       * @param {boolean} body.includeHourLogs Optional
       * @param {boolean} body.includeSteps Optional
       * @param {boolean} body.includeStepSubmissions Optional
       * @param {boolean} body.includeShiftsWithDeletedLocations Optional
       * @param {boolean} body.excludeTotalCount Optional
       * @param {array} body.selectedMatchIds Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `MatchListSortProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getGroups(body, options = {}) {
        const uri = '/api/matches/student/groups'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchStudent.getGroupsPage
       * POST /api/matches/student/groups/{status}
       * @param {object} parameters
       * @param {object} parameters.status `MatchStatus` Required
       * @param {object} body `ListMatchesRequest` Required
       * @param {string} body.apiVersion Optional
       * @param {number} body.matchId Optional
       * @param {boolean} body.includeCustomFields Optional
       * @param {boolean} body.includeAgreements Optional
       * @param {boolean} body.includeCustomTags Optional
       * @param {boolean} body.includeMatchUsers Optional
       * @param {boolean} body.includeUsers Optional
       * @param {boolean} body.includeHourLogs Optional
       * @param {boolean} body.includeSteps Optional
       * @param {boolean} body.includeStepSubmissions Optional
       * @param {boolean} body.includeShiftsWithDeletedLocations Optional
       * @param {boolean} body.excludeTotalCount Optional
       * @param {array} body.selectedMatchIds Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `MatchListSortProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getGroupsPage({ status } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/student/groups/${status}`
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchStudent.getListPage
       * POST /api/matches/student/list
       * @param {object} body `ListMatchesRequest` Required
       * @param {string} body.apiVersion Optional
       * @param {number} body.matchId Optional
       * @param {boolean} body.includeCustomFields Optional
       * @param {boolean} body.includeAgreements Optional
       * @param {boolean} body.includeCustomTags Optional
       * @param {boolean} body.includeMatchUsers Optional
       * @param {boolean} body.includeUsers Optional
       * @param {boolean} body.includeHourLogs Optional
       * @param {boolean} body.includeSteps Optional
       * @param {boolean} body.includeStepSubmissions Optional
       * @param {boolean} body.includeShiftsWithDeletedLocations Optional
       * @param {boolean} body.excludeTotalCount Optional
       * @param {array} body.selectedMatchIds Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `MatchListSortProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getListPage(body, options = {}) {
        const uri = '/api/matches/student/list'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * matchStudent.listActivity
       * GET /api/matches/student/{matchId}/activity
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listActivity({ matchId } = {}, options = {}) {
        const uri = encodePath`/api/matches/student/${matchId}/activity`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * matchStudent.listAllSubmissionsByStepAndUser
       * GET /api/matches/student/submissionsByUserAndStep
       * @param {object} parameters
       * @param {number} parameters.matchId Optional
       * @param {number} parameters.stepId Optional
       * @param {string} parameters.targetUserId Optional
       * @param {string} parameters.userId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listAllSubmissionsByStepAndUser({ matchId, stepId, targetUserId, userId } = {}, options = {}) {
        const uri = '/api/matches/student/submissionsByUserAndStep'
        return this.api.fetch(uri, 'GET', { matchId, stepId, targetUserId, userId }, null, options)
      },

      /**
       * matchStudent.proposeChangeMatch
       * PUT /api/matches/student/{matchId}/change
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `UpdateMatchApiViewModelRequest` Required
       * @param {number} body.requestId Optional
       * @param {number} body.matchId Optional
       * @param {number} body.matchChangeDetailId Optional
       * @param {string} body.comment Optional
       * @param {string} body.dueDate Optional
       * @param {object} body.changes `MatchFormChangesViewModel` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      proposeChangeMatch({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/student/${matchId}/change`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * matchStudent.rejectChangeMatch
       * PUT /api/matches/student/{matchId}/rejectChange
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `RejectMatchChange` Required
       * @param {number} body.matchChangeDetailId Optional
       * @param {string} body.rejectReason Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      rejectChangeMatch({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/student/${matchId}/rejectChange`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * matchStudent.removeSelf
       * PUT /api/matches/student/{matchId}/removeSelf/{role}
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} parameters.role `MatchRole` Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      removeSelf({ matchId, role } = {}, options = {}) {
        const uri = encodePath`/api/matches/student/${matchId}/removeSelf/${role}`
        return this.api.fetch(uri, 'PUT', null, null, options)
      },

      /**
       * matchStudent.reopenMatch
       * PUT /api/matches/student/{matchId}/reopen
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `ReopenMatchViewModel` Required
       * @param {string} body.startDate Optional
       * @param {string} body.endDate Optional
       * @param {number} body.extendByDays Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      reopenMatch({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/student/${matchId}/reopen`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * matchStudent.submitMatchRequest
       * PUT /api/matches/student/{matchId}/submit
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `UpdateMatchApiViewModelRequest` Required
       * @param {number} body.requestId Optional
       * @param {number} body.matchId Optional
       * @param {number} body.matchChangeDetailId Optional
       * @param {string} body.comment Optional
       * @param {string} body.dueDate Optional
       * @param {object} body.changes `MatchFormChangesViewModel` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      submitMatchRequest({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/matches/student/${matchId}/submit`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.messenger = {
      api: this,

      /**
       * messenger.getConversation
       * GET /api/messenger/conversation/{contactUsername}
       * @param {object} parameters
       * @param {string} parameters.contactUsername Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getConversation({ contactUsername } = {}, options = {}) {
        const uri = encodePath`/api/messenger/conversation/${contactUsername}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * messenger.getUnreadCounts
       * GET /api/messenger/unreadCounts
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getUnreadCounts(options = {}) {
        const uri = '/api/messenger/unreadCounts'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * messenger.listContacts
       * GET /api/messenger/contacts
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listContacts(options = {}) {
        const uri = '/api/messenger/contacts'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * messenger.sendIM
       * POST /api/messenger/conversation/{contactUsername}
       * @param {object} parameters
       * @param {string} parameters.contactUsername Required
       * @param {string} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      sendIM({ contactUsername } = {}, body, options = {}) {
        const uri = encodePath`/api/messenger/conversation/${contactUsername}`
        return this.api.fetch(uri, 'POST', null, body, options)
      },
    }

    this.monitor = {
      api: this,

      /**
       * monitor.getStats
       * GET /api/monitor/stats
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getStats(options = {}) {
        const uri = '/api/monitor/stats'
        return this.api.fetch(uri, 'GET', null, null, options)
      },
    }

    this.nameSuggestion = {
      api: this,

      /**
       * nameSuggestion.canDelete
       * GET /api/name-suggestions/{nameSuggestionId}/can-delete
       * @param {object} parameters
       * @param {number} parameters.nameSuggestionId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      canDelete({ nameSuggestionId } = {}, options = {}) {
        const uri = encodePath`/api/name-suggestions/${nameSuggestionId}/can-delete`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * nameSuggestion.export
       * POST /api/name-suggestions/export
       * @param {object} body `ExportNameSuggestionsRequest` Required
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `NameSuggestionListProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      export(body, options = {}) {
        const uri = '/api/name-suggestions/export'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * nameSuggestion.get
       * GET /api/name-suggestions/{nameSuggestionId}
       * @param {object} parameters
       * @param {number} parameters.nameSuggestionId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      get({ nameSuggestionId } = {}, options = {}) {
        const uri = encodePath`/api/name-suggestions/${nameSuggestionId}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * nameSuggestion.hardDelete
       * DELETE /api/name-suggestions/{nameSuggestionId}
       * @param {object} parameters
       * @param {number} parameters.nameSuggestionId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      hardDelete({ nameSuggestionId } = {}, options = {}) {
        const uri = encodePath`/api/name-suggestions/${nameSuggestionId}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },

      /**
       * nameSuggestion.list
       * POST /api/name-suggestions/list
       * @param {object} body `ListNameSuggestionsRequestBody` Required
       * @param {boolean} body.excludeTotalCount Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {array} body.selectedNameSuggestionIds Optional
       * @param {object} body.sortProperty `NameSuggestionListProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      list(body, options = {}) {
        const uri = '/api/name-suggestions/list'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * nameSuggestion.update
       * PUT /api/name-suggestions/{nameSuggestionId}
       * @param {object} parameters
       * @param {number} parameters.nameSuggestionId Required
       * @param {object} body `UpdateNameSuggestionRequest` Required
       * @param {number} body.nameSuggestionId Optional
       * @param {string} body.namePrefix Optional
       * @param {string} body.firstName Optional
       * @param {string} body.preferredName Optional
       * @param {string} body.middleName Optional
       * @param {string} body.lastName Optional
       * @param {string} body.nameSuffix Optional
       * @param {string} body.credentials Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      update({ nameSuggestionId } = {}, body, options = {}) {
        const uri = encodePath`/api/name-suggestions/${nameSuggestionId}`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.notifications = {
      api: this,

      /**
       * notifications.getUnreadCount
       * GET /api/notifications/unread-count
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getUnreadCount(options = {}) {
        const uri = '/api/notifications/unread-count'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * notifications.listNotifications
       * GET /api/notifications/{offset}/{pageSize}
       * @param {object} parameters
       * @param {number} parameters.offset Required
       * @param {number} parameters.pageSize Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listNotifications({ offset, pageSize } = {}, options = {}) {
        const uri = encodePath`/api/notifications/${offset}/${pageSize}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * notifications.markClicked
       * POST /api/notifications/{notificationId}/click
       * @param {object} parameters
       * @param {string} parameters.notificationId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      markClicked({ notificationId } = {}, options = {}) {
        const uri = encodePath`/api/notifications/${notificationId}/click`
        return this.api.fetch(uri, 'POST', null, null, options)
      },

      /**
       * notifications.markRead
       * PUT /api/notifications/mark-read
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      markRead(options = {}) {
        const uri = '/api/notifications/mark-read'
        return this.api.fetch(uri, 'PUT', null, null, options)
      },
    }

    this.notificationSettings = {
      api: this,

      /**
       * notificationSettings.get
       * GET /api/notificationSettings
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      get(options = {}) {
        const uri = '/api/notificationSettings'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * notificationSettings.save
       * PUT /api/notificationSettings
       * @param {object} body `NotificationSettingsViewModel` Required
       * @param {number} body.emailWaitMinutes Optional
       * @param {array} body.groups `NotificationSettingGroupViewModel` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      save(body, options = {}) {
        const uri = '/api/notificationSettings'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.org = {
      api: this,

      /**
       * org.addChildOrg
       * POST /api/orgs/{orgId}/children
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} body `OrgProfileViewModel` Required
       * @param {string} body.name Required
       * @param {string} body.explicitProfilePicture Optional
       * @param {string} body.description Optional
       * @param {string} body.formattedAddress Optional
       * @param {string} body.phoneNumber Optional
       * @param {string} body.emailPublic Optional
       * @param {string} body.nationalProviderId Optional
       * @param {string} body.patientPopulation Optional
       * @param {number} body.parentOrgId Optional
       * @param {array} body.orgTagNames Optional
       * @param {array} body.locationIds Optional
       * @param {array} body.featureTypes `FeatureType` Optional
       * @param {object} body.customFieldValues Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addChildOrg({ orgId } = {}, body, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/children`
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * org.addDomain
       * POST /api/orgs/{orgId}/domains
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} body `AddDomainRequestBody` Required
       * @param {string} body.domain Optional
       * @param {object} body.status `OrgDomainStatus` Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addDomain({ orgId } = {}, body, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/domains`
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * org.addStaff
       * POST /api/orgs/{orgId}/staff
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} body `AddOrgStaffRequest` Required
       * @param {number} body.rowNumber Optional
       * @param {string} body.email Optional
       * @param {number} body.importStaffId Optional
       * @param {string} body.legalFirstName Optional
       * @param {string} body.legalLastName Optional
       * @param {string} body.customMessage Optional
       * @param {string} body.orgName Optional
       * @param {number} body.staffId Optional
       * @param {number} body.orgId Optional
       * @param {string} body.userId Required
       * @param {string} body.title Optional
       * @param {string} body.externalId Optional
       * @param {array} body.orgStaffRoles `UpsertStaffRoleRequest` Optional
       * @param {string} body.customFieldValuesJson Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addStaff({ orgId } = {}, body, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/staff`
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * org.addTeam
       * POST /api/orgs/{orgId}/teams
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} body `SaveTeamRequestBody` Required
       * @param {string} body.name Required
       * @param {string} body.description Optional
       * @param {array} body.disciplines Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addTeam({ orgId } = {}, body, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/teams`
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * org.allowDomain
       * GET /api/orgs/{orgId}/domains/{domainId}/allow
       * @param {object} parameters
       * @param {number} parameters.domainId Required
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      allowDomain({ domainId, orgId } = {}, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/domains/${domainId}/allow`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * org.canDeleteOrg
       * GET /api/orgs/{orgId}/can-delete
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      canDeleteOrg({ orgId } = {}, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/can-delete`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * org.canDeleteTeam
       * GET /api/orgs/{orgId}/teams/{teamId}/can-delete
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {number} parameters.teamId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      canDeleteTeam({ orgId, teamId } = {}, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/teams/${teamId}/can-delete`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * org.confirmStaff
       * PUT /api/orgs/{orgId}/staff/{userId}/confirm
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {string} parameters.userId Required
       * @param {object} body `ConfirmOrgStaffViewModel` Required
       * @param {number} body.staffId Optional
       * @param {string} body.userId Required
       * @param {number} body.orgId Optional
       * @param {array} body.roles `StaffRole` Optional
       * @param {string} body.allowEmailOrgDomain Optional
       * @param {boolean} body.updateStaffEmailAndDomain Optional
       * @param {number} body.updateEmailId Optional
       * @param {number} body.updateDomainId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      confirmStaff({ orgId, userId } = {}, body, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/staff/${userId}/confirm`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * org.deleteOrg
       * DELETE /api/orgs/{orgId}
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      deleteOrg({ orgId } = {}, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },

      /**
       * org.deleteTeam
       * DELETE /api/orgs/{orgId}/teams/{teamId}
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {number} parameters.teamId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      deleteTeam({ orgId, teamId } = {}, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/teams/${teamId}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },

      /**
       * org.get
       * GET /api/orgs/{orgId}
       * @param {object} parameters
       * @param {boolean} parameters.includeCustomFields Optional
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      get({ includeCustomFields, orgId } = {}, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}`
        return this.api.fetch(uri, 'GET', { includeCustomFields }, null, options)
      },

      /**
       * org.getChildren
       * GET /api/orgs/{orgId}/children
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getChildren({ orgId } = {}, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/children`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * org.getDomains
       * POST /api/orgs/{orgId}/domains/list
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} body `GetDomainsRequestBody` Required
       * @param {object} body.sortProperty `OrgDomainListProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {boolean} body.includeDeleted Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getDomains({ orgId } = {}, body, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/domains/list`
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * org.getOrgCustomFieldsConfig
       * GET /api/orgs/{orgId}/org-custom-fields
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getOrgCustomFieldsConfig({ orgId } = {}, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/org-custom-fields`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * org.getOrgCustomFieldValues
       * GET /api/orgs/{orgId}/org-custom-field-values
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getOrgCustomFieldValues({ orgId } = {}, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/org-custom-field-values`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * org.getPendingConfigCounts
       * GET /api/orgs/{orgId}/pending-config-counts
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getPendingConfigCounts({ orgId } = {}, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/pending-config-counts`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * org.getSettings
       * GET /api/orgs/{orgId}/settings
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getSettings({ orgId } = {}, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/settings`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * org.getStaffCustomFieldsConfig
       * GET /api/orgs/{orgId}/staff-custom-fields
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getStaffCustomFieldsConfig({ orgId } = {}, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/staff-custom-fields`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * org.getStudentCustomFieldsConfig
       * GET /api/orgs/{orgId}/student-custom-fields
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getStudentCustomFieldsConfig({ orgId } = {}, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/student-custom-fields`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * org.getTeamById
       * GET /api/orgs/teams/{teamId}
       * @param {object} parameters
       * @param {number} parameters.teamId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getTeamById({ teamId } = {}, options = {}) {
        const uri = encodePath`/api/orgs/teams/${teamId}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * org.getTeams
       * GET /api/orgs/{orgId}/teams
       * @param {object} parameters
       * @param {boolean} parameters.filterToUser Optional
       * @param {number} parameters.orgId Required
       * @param {boolean} parameters.showEditableOnly Optional
       * @param {array} parameters.teamIds Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getTeams({ filterToUser, orgId, showEditableOnly, teamIds } = {}, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/teams`
        return this.api.fetch(uri, 'GET', { filterToUser, showEditableOnly, teamIds }, null, options)
      },

      /**
       * org.list
       * POST /api/orgs/list
       * @param {object} body `ListOrgsRequest` Required
       * @param {number} body.orgId Optional
       * @param {array} body.selectedOrgIds Optional
       * @param {object} body.bounds `MapBounds` Optional
       * @param {boolean} body.includeChildrenOrgIds Optional
       * @param {boolean} body.includeAncestorOrgIds Optional
       * @param {boolean} body.includeAddresses Optional
       * @param {boolean} body.includeDescription Optional
       * @param {boolean} body.includeContactInfo Optional
       * @param {boolean} body.includeTags Optional
       * @param {boolean} body.includeAgreementCount Optional
       * @param {boolean} body.includeActiveCapacityCount Optional
       * @param {boolean} body.includeStudentCount Optional
       * @param {boolean} body.includeStaffInviteOnly Optional
       * @param {boolean} body.excludeTotalCount Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {number} body.capacityOwnerOrgId Optional
       * @param {object} body.sortProperty `OrgListProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      list(body, options = {}) {
        const { cache } = this.list
        const cachePromise = cache.tryGet(null, body, options)
        if (cachePromise) return cachePromise
        const uri = '/api/orgs/list'
        const promise = this.api.fetch(uri, 'POST', null, body, options)
        cache.add(null, _.cloneDeep(body), options, promise)
        return promise
      },

      /**
       * org.listOrgDomainPeople
       * POST /api/orgs/{orgId}/people/pending/{domainId}
       * @param {object} parameters
       * @param {number} parameters.domainId Required
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listOrgDomainPeople({ domainId, orgId } = {}, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/people/pending/${domainId}`
        return this.api.fetch(uri, 'POST', null, null, options)
      },

      /**
       * org.listTags
       * GET /api/orgs/tags
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listTags(options = {}) {
        const { cache } = this.listTags
        const cachePromise = cache.tryGet(null, null, options)
        if (cachePromise) return cachePromise
        const uri = '/api/orgs/tags'
        const promise = this.api.fetch(uri, 'GET', null, null, options)
        cache.add(null, null, options, promise)
        return promise
      },

      /**
       * org.rejectPeopleAndSetDomain
       * POST /api/orgs/{orgId}/domains/{domainId}/reject-and-set
       * @param {object} parameters
       * @param {number} parameters.domainId Required
       * @param {number} parameters.orgId Required
       * @param {object} body `BlockDomainRequestBody` Required
       * @param {number} body.expectedStaffCount Optional
       * @param {number} body.expectedStudentCount Optional
       * @param {object} body.setStatusTo `OrgDomainStatus` Required
       * @param {boolean} body.blockSingleUser Optional
       * @param {string} body.singlePendingStaffUserId Optional
       * @param {string} body.singlePendingStudentUserId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      rejectPeopleAndSetDomain({ domainId, orgId } = {}, body, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/domains/${domainId}/reject-and-set`
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * org.rejectStaff
       * PUT /api/orgs/{orgId}/staff/{userId}/reject
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {number} parameters.staffId Optional
       * @param {string} parameters.userId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      rejectStaff({ orgId, staffId, userId } = {}, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/staff/${userId}/reject`
        return this.api.fetch(uri, 'PUT', { staffId }, null, options)
      },

      /**
       * org.removeStaff
       * DELETE /api/orgs/{orgId}/staff/{userId}
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {number} parameters.staffId Optional
       * @param {string} parameters.userId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      removeStaff({ orgId, staffId, userId } = {}, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/staff/${userId}`
        return this.api.fetch(uri, 'DELETE', { staffId }, null, options)
      },

      /**
       * org.resendInvite
       * PUT /api/orgs/{orgId}/staff/{userId}/resendinvite
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {string} parameters.userId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      resendInvite({ orgId, userId } = {}, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/staff/${userId}/resendinvite`
        return this.api.fetch(uri, 'PUT', null, null, options)
      },

      /**
       * org.saveAdvancedSettings
       * PUT /api/orgs/{orgId}/advanced-settings
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} body `SaveAdvancedSettingsRequestBody` Required
       * @param {boolean} body.hourLoggingEnabled Optional
       * @param {boolean} body.hourLogsMustBeVerified Optional
       * @param {array} body.hourLoggingDisciplines Optional
       * @param {object} body.serviceNameStrategy `ServiceNameStrategy` Required
       * @param {array} body.serviceNames Optional
       * @param {object} body.navigationStrategy `OrgNavigationStrategy` Required
       * @param {number} body.serviceNamesInheritedFromOrgId Optional
       * @param {object} body.orgCustomFieldsConfig `FormConfig` Optional
       * @param {object} body.staffCustomFieldsConfig `FormConfig` Optional
       * @param {object} body.studentCustomFieldsConfig `FormConfig` Optional
       * @param {boolean} body.staffInviteOnly Optional
       * @param {boolean} body.singleOrgCapacityGuestAgreementIsOptional Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      saveAdvancedSettings({ orgId } = {}, body, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/advanced-settings`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * org.saveSecuritySettings
       * PUT /api/orgs/{orgId}/security-settings
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} body `SaveSecuritySettingsRequestBody` Required
       * @param {boolean} body.twoFactorEnabled Optional
       * @param {object} body.password `PasswordSettings` Optional
       * @param {array} body.iPWhiteList `OrgIpRange` Optional
       * @param {array} body.iPBlackList `OrgIpRange` Optional
       * @param {number} body.idleTimeout Optional
       * @param {boolean} body.ssoEnabled Optional
       * @param {boolean} body.ssoAllowStudents Optional
       * @param {string} body.ssoSchemeName Optional
       * @param {string} body.ssoDisplayName Optional
       * @param {array} body.ssoEmailDomainsToExclude Optional
       * @param {object} body.ssoConfig `SsoConfig` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      saveSecuritySettings({ orgId } = {}, body, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/security-settings`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * org.updateOrg
       * PUT /api/orgs/{orgId}
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} body `OrgProfileViewModel` Required
       * @param {string} body.name Required
       * @param {string} body.explicitProfilePicture Optional
       * @param {string} body.description Optional
       * @param {string} body.formattedAddress Optional
       * @param {string} body.phoneNumber Optional
       * @param {string} body.emailPublic Optional
       * @param {string} body.nationalProviderId Optional
       * @param {string} body.patientPopulation Optional
       * @param {number} body.parentOrgId Optional
       * @param {array} body.orgTagNames Optional
       * @param {array} body.locationIds Optional
       * @param {array} body.featureTypes `FeatureType` Optional
       * @param {object} body.customFieldValues Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      updateOrg({ orgId } = {}, body, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * org.updateStaff
       * PUT /api/orgs/{orgId}/staff/{userId}
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {string} parameters.userId Required
       * @param {object} body `UpsertStaffWebRequest` Required
       * @param {number} body.staffId Optional
       * @param {number} body.orgId Optional
       * @param {string} body.userId Required
       * @param {string} body.title Optional
       * @param {string} body.externalId Optional
       * @param {array} body.orgStaffRoles `UpsertStaffRoleRequest` Optional
       * @param {string} body.customFieldValuesJson Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      updateStaff({ orgId, userId } = {}, body, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/staff/${userId}`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * org.updateTeam
       * PUT /api/orgs/{orgId}/teams/{teamId}
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {number} parameters.teamId Required
       * @param {object} body `SaveTeamRequestBody` Required
       * @param {string} body.name Required
       * @param {string} body.description Optional
       * @param {array} body.disciplines Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      updateTeam({ orgId, teamId } = {}, body, options = {}) {
        const uri = encodePath`/api/orgs/${orgId}/teams/${teamId}`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.org.list.cache = new ApiCache(3, true)
    this.org.listTags.cache = new ApiCache(604800, false)

    this.orgFilterOptions = {
      api: this,

      /**
       * orgFilterOptions.getOrgTags
       * GET /api/filter-options/for-orgs/tags
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getOrgTags(options = {}) {
        const uri = '/api/filter-options/for-orgs/tags'
        return this.api.fetch(uri, 'GET', null, null, options)
      },
    }

    this.orgRelationship = {
      api: this,

      /**
       * orgRelationship.add
       * POST /api/org-relationships
       * @param {object} body `AddOrgRelationshipRequest` Required
       * @param {boolean} body.allowCoordinatorScheduling Optional
       * @param {boolean} body.allowStudentScheduling Optional
       * @param {number} body.ownerOrgId Optional
       * @param {number} body.targetOrgId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      add(body, options = {}) {
        const uri = '/api/org-relationships'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * orgRelationship.canDelete
       * GET /api/org-relationships/{orgRelationshipId}/can-delete
       * @param {object} parameters
       * @param {number} parameters.orgRelationshipId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      canDelete({ orgRelationshipId } = {}, options = {}) {
        const uri = encodePath`/api/org-relationships/${orgRelationshipId}/can-delete`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * orgRelationship.export
       * POST /api/org-relationships/export
       * @param {object} body `ExportOrgRelationshipsRequest` Required
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `OrgRelationshipListProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      export(body, options = {}) {
        const uri = '/api/org-relationships/export'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * orgRelationship.get
       * GET /api/org-relationships/{orgRelationshipId}
       * @param {object} parameters
       * @param {number} parameters.orgRelationshipId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      get({ orgRelationshipId } = {}, options = {}) {
        const uri = encodePath`/api/org-relationships/${orgRelationshipId}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * orgRelationship.list
       * POST /api/org-relationships/list
       * @param {object} body `ListOrgRelationshipsRequestBody` Required
       * @param {boolean} body.excludeTotalCount Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {array} body.selectedIds `TargetOrgRelationshipId` Optional
       * @param {object} body.sortProperty `OrgRelationshipListProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      list(body, options = {}) {
        const uri = '/api/org-relationships/list'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * orgRelationship.softDelete
       * DELETE /api/org-relationships/{orgRelationshipId}
       * @param {object} parameters
       * @param {number} parameters.orgRelationshipId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      softDelete({ orgRelationshipId } = {}, options = {}) {
        const uri = encodePath`/api/org-relationships/${orgRelationshipId}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },

      /**
       * orgRelationship.update
       * PUT /api/org-relationships/{orgRelationshipId}
       * @param {object} parameters
       * @param {number} parameters.orgRelationshipId Required
       * @param {object} body `UpdateOrgRelationshipRequest` Required
       * @param {number} body.orgRelationshipId Optional
       * @param {boolean} body.allowCoordinatorScheduling Optional
       * @param {boolean} body.allowStudentScheduling Optional
       * @param {number} body.ownerOrgId Optional
       * @param {number} body.targetOrgId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      update({ orgRelationshipId } = {}, body, options = {}) {
        const uri = encodePath`/api/org-relationships/${orgRelationshipId}`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.orgRelationshipFilterOptions = {
      api: this,

      /**
       * orgRelationshipFilterOptions.getOwnerOrgs
       * GET /api/filter-options/for-related-orgs/owner-orgs
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getOwnerOrgs(options = {}) {
        const uri = '/api/filter-options/for-related-orgs/owner-orgs'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * orgRelationshipFilterOptions.getTargetOrgs
       * GET /api/filter-options/for-related-orgs/target-orgs
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getTargetOrgs(options = {}) {
        const uri = '/api/filter-options/for-related-orgs/target-orgs'
        return this.api.fetch(uri, 'GET', null, null, options)
      },
    }

    this.preCheck = {
      api: this,

      /**
       * preCheck.callback
       * POST /api/precheck/callback
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      callback(options = {}) {
        const uri = '/api/precheck/callback'
        return this.api.fetch(uri, 'POST', null, null, options)
      },

      /**
       * preCheck.simulateCallback
       * POST /api/precheck/callback/cn
       * @param {object} body `CallbackPayload` Required
       * @param {number} body.orderNumber Optional
       * @param {string} body.clientApplicationNumber Optional
       * @param {object} body.error `PreCheckError` Optional
       * @param {object} body.backgroundCheckResult `BackgroundCheckResult` Optional
       * @param {object} body.drugTestResult `DrugTestResult` Optional
       * @param {object} body.immunizationResult `ImmunizationResult` Optional
       * @param {array} body.clinicalRotation `CallbackClinicalRotation` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      simulateCallback(body, options = {}) {
        const uri = '/api/precheck/callback/cn'
        return this.api.fetch(uri, 'POST', null, body, options)
      },
    }

    this.preCheckLink = {
      api: this,

      /**
       * preCheckLink.clinicRedirect
       * GET /api/precheck/link/clinic/{clientId}
       * @param {object} parameters
       * @param {number} parameters.clientId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      clinicRedirect({ clientId } = {}, options = {}) {
        const uri = encodePath`/api/precheck/link/clinic/${clientId}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * preCheckLink.schoolRedirect
       * GET /api/precheck/link/school/{clientId}
       * @param {object} parameters
       * @param {number} parameters.clientId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      schoolRedirect({ clientId } = {}, options = {}) {
        const uri = encodePath`/api/precheck/link/school/${clientId}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * preCheckLink.studentLink
       * GET /api/precheck/link/student
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      studentLink(options = {}) {
        const uri = '/api/precheck/link/student'
        return this.api.fetch(uri, 'GET', null, null, options)
      },
    }

    this.procedureLog = {
      api: this,

      /**
       * procedureLog.add
       * POST /api/procedurelogs/{matchId}
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} body `ProcedureLogViewModel` Required
       * @param {number} body.procedureLogId Optional
       * @param {number} body.matchId Optional
       * @param {string} body.userId Optional
       * @param {string} body.procedure Required
       * @param {string} body.notes Optional
       * @param {string} body.date Required
       * @param {string} body.dateCreated Optional
       * @param {string} body.dateModified Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      add({ matchId } = {}, body, options = {}) {
        const uri = encodePath`/api/procedurelogs/${matchId}`
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * procedureLog.deleteProcedureLogEntry
       * DELETE /api/procedurelogs/{matchId}/{procedureLogId}
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {number} parameters.procedureLogId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      deleteProcedureLogEntry({ matchId, procedureLogId } = {}, options = {}) {
        const uri = encodePath`/api/procedurelogs/${matchId}/${procedureLogId}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },

      /**
       * procedureLog.list
       * GET /api/procedurelogs/{matchId}
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      list({ matchId } = {}, options = {}) {
        const uri = encodePath`/api/procedurelogs/${matchId}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * procedureLog.updateProcedureLogEntry
       * PUT /api/procedurelogs/{matchId}/{procedureLogId}
       * @param {object} parameters
       * @param {number} parameters.matchId Required
       * @param {number} parameters.procedureLogId Required
       * @param {object} body `ProcedureLogViewModel` Required
       * @param {number} body.procedureLogId Optional
       * @param {number} body.matchId Optional
       * @param {string} body.userId Optional
       * @param {string} body.procedure Required
       * @param {string} body.notes Optional
       * @param {string} body.date Required
       * @param {string} body.dateCreated Optional
       * @param {string} body.dateModified Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      updateProcedureLogEntry({ matchId, procedureLogId } = {}, body, options = {}) {
        const uri = encodePath`/api/procedurelogs/${matchId}/${procedureLogId}`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.profile = {
      api: this,

      /**
       * profile.changeName
       * PUT /api/profile/name
       * @param {object} body `ChangeNameViewModel` Required
       * @param {string} body.legalFirstName Required
       * @param {string} body.legalLastName Required
       * @param {string} body.middleName Optional
       * @param {string} body.preferredName Optional
       * @param {string} body.namePrefix Optional
       * @param {string} body.nameSuffix Optional
       * @param {string} body.credentials Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      changeName(body, options = {}) {
        const uri = '/api/profile/name'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * profile.changePhonePublic
       * PUT /api/profile/phone-public
       * @param {string} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      changePhonePublic(body, options = {}) {
        const uri = '/api/profile/phone-public'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * profile.delete
       * DELETE /api/profile
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      delete(options = {}) {
        const uri = '/api/profile'
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },

      /**
       * profile.disableTwoFactor
       * DELETE /api/profile/twofactor
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      disableTwoFactor(options = {}) {
        const uri = '/api/profile/twofactor'
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },

      /**
       * profile.enableTwoFactorApp
       * PUT /api/profile/twofactor/app
       * @param {object} body `EnableAuthenticatorViewModel` Required
       * @param {string} body.token Required
       * @param {string} body.password Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      enableTwoFactorApp(body, options = {}) {
        const uri = '/api/profile/twofactor/app'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * profile.enableTwoFactorSms
       * PUT /api/profile/twofactor/sms
       * @param {object} body `ChangePhoneConfirmViewModel` Required
       * @param {string} body.phone Required
       * @param {string} body.token Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      enableTwoFactorSms(body, options = {}) {
        const uri = '/api/profile/twofactor/sms'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * profile.getTwoFactorInfo
       * GET /api/profile/twoFactor
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getTwoFactorInfo(options = {}) {
        const uri = '/api/profile/twoFactor'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * profile.getUserProfile
       * GET /api/profile
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getUserProfile(options = {}) {
        const uri = '/api/profile'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * profile.saveAddress
       * PUT /api/profile/address
       * @param {object} body `Address` Required
       * @param {number} body.addressId Optional
       * @param {number} body.lat Optional
       * @param {number} body.long Optional
       * @param {string} body.formattedAddress Optional
       * @param {string} body.placeId Optional
       * @param {string} body.streetNumber Optional
       * @param {string} body.route Optional
       * @param {string} body.subPremise Optional
       * @param {string} body.city Optional
       * @param {string} body.postalCode Optional
       * @param {string} body.postalCodeSuffix Optional
       * @param {string} body.county Optional
       * @param {string} body.state Optional
       * @param {string} body.country Optional
       * @param {string} body.longStreetNumber Optional
       * @param {string} body.longRoute Optional
       * @param {string} body.longSubPremise Optional
       * @param {string} body.longCity Optional
       * @param {string} body.longPostalCode Optional
       * @param {string} body.longPostalCodeSuffix Optional
       * @param {string} body.longCounty Optional
       * @param {string} body.longState Optional
       * @param {string} body.longCountry Optional
       * @param {string} body.mapboxId Optional
       * @param {string} body.dateCreated Required
       * @param {string} body.dateModified Required
       * @param {string} body.userCreated Optional
       * @param {string} body.userModified Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      saveAddress(body, options = {}) {
        const uri = '/api/profile/address'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * profile.saveProfile
       * PUT /api/profile
       * @param {object} body `ProfileViewModel` Required
       * @param {number} body.pronounSetId Optional
       * @param {string} body.nationalProviderId Optional
       * @param {string} body.aboutMe Optional
       * @param {number} body.studentYear Optional
       * @param {string} body.discipline Optional
       * @param {string} body.otherDiscipline Optional
       * @param {string} body.studentGraduationDate Optional
       * @param {array} body.clinicalInterests Optional
       * @param {array} body.interests Optional
       * @param {array} body.languages Optional
       * @param {array} body.staffCustomFields `ProfileCustomFieldsViewModel` Optional
       * @param {array} body.studentCustomFields `ProfileCustomFieldsViewModel` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      saveProfile(body, options = {}) {
        const uri = '/api/profile'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * profile.saveProfilePicture
       * POST /api/profile/picture
       * @param {object} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      saveProfilePicture(body, options = {}) {
        const uri = '/api/profile/picture'
        options = { ...options, isFileUpload: true }
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * profile.sendConfirmPhoneToken
       * POST /api/profile/twofactor/sms
       * @param {object} body `ChangePhoneSendTokenViewModel` Required
       * @param {string} body.phone Required
       * @param {string} body.phoneConfirm Optional
       * @param {string} body.password Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      sendConfirmPhoneToken(body, options = {}) {
        const uri = '/api/profile/twofactor/sms'
        return this.api.fetch(uri, 'POST', null, body, options)
      },
    }

    this.profileEducation = {
      api: this,

      /**
       * profileEducation.addEducation
       * POST /api/profile/educations
       * @param {object} body `EducationViewModel` Required
       * @param {number} body.educationId Optional
       * @param {object} body.educationType `EducationType` Required
       * @param {string} body.userId Required
       * @param {object} body.user `UserViewModel` Optional
       * @param {string} body.schoolName Required
       * @param {number} body.startDate Required
       * @param {number} body.endDate Optional
       * @param {boolean} body.graduated Optional
       * @param {string} body.description Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addEducation(body, options = {}) {
        const uri = '/api/profile/educations'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * profileEducation.deleteEducation
       * DELETE /api/profile/educations/{educationId}
       * @param {object} parameters
       * @param {number} parameters.educationId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      deleteEducation({ educationId } = {}, options = {}) {
        const uri = encodePath`/api/profile/educations/${educationId}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },

      /**
       * profileEducation.updateEducation
       * PUT /api/profile/educations/{educationId}
       * @param {object} parameters
       * @param {number} parameters.educationId Required
       * @param {object} body `EducationViewModel` Required
       * @param {number} body.educationId Optional
       * @param {object} body.educationType `EducationType` Required
       * @param {string} body.userId Required
       * @param {object} body.user `UserViewModel` Optional
       * @param {string} body.schoolName Required
       * @param {number} body.startDate Required
       * @param {number} body.endDate Optional
       * @param {boolean} body.graduated Optional
       * @param {string} body.description Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      updateEducation({ educationId } = {}, body, options = {}) {
        const uri = encodePath`/api/profile/educations/${educationId}`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.profileExperience = {
      api: this,

      /**
       * profileExperience.addExperience
       * POST /api/profile/experiences
       * @param {object} body `ExperienceViewModel` Required
       * @param {number} body.experienceId Optional
       * @param {string} body.userId Required
       * @param {object} body.user `UserViewModel` Optional
       * @param {string} body.company Required
       * @param {string} body.position Required
       * @param {string} body.description Optional
       * @param {string} body.startDate Required
       * @param {string} body.endDate Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addExperience(body, options = {}) {
        const uri = '/api/profile/experiences'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * profileExperience.deleteExperience
       * DELETE /api/profile/experiences/{experienceId}
       * @param {object} parameters
       * @param {number} parameters.experienceId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      deleteExperience({ experienceId } = {}, options = {}) {
        const uri = encodePath`/api/profile/experiences/${experienceId}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },

      /**
       * profileExperience.updateExperience
       * PUT /api/profile/experiences/{experienceId}
       * @param {object} parameters
       * @param {number} parameters.experienceId Required
       * @param {object} body `ExperienceViewModel` Required
       * @param {number} body.experienceId Optional
       * @param {string} body.userId Required
       * @param {object} body.user `UserViewModel` Optional
       * @param {string} body.company Required
       * @param {string} body.position Required
       * @param {string} body.description Optional
       * @param {string} body.startDate Required
       * @param {string} body.endDate Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      updateExperience({ experienceId } = {}, body, options = {}) {
        const uri = encodePath`/api/profile/experiences/${experienceId}`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.pronounSet = {
      api: this,

      /**
       * pronounSet.list
       * GET /api/pronoun-sets
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      list(options = {}) {
        const { cache } = this.list
        const cachePromise = cache.tryGet(null, null, options)
        if (cachePromise) return cachePromise
        const uri = '/api/pronoun-sets'
        const promise = this.api.fetch(uri, 'GET', null, null, options)
        cache.add(null, null, options, promise)
        return promise
      },
    }

    this.pronounSet.list.cache = new ApiCache(1800, false)

    this.referral = {
      api: this,

      /**
       * referral.addReferral
       * POST /api/referrals
       * @param {object} body `ReferralViewModel` Required
       * @param {string} body.referralId Required
       * @param {string} body.userFromName Optional
       * @param {string} body.userFromEmail Optional
       * @param {string} body.toEmail Required
       * @param {string} body.message Optional
       * @param {string} body.dateSent Required
       * @param {string} body.dateClicked Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addReferral(body, options = {}) {
        const uri = '/api/referrals'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * referral.clicked
       * GET /api/referrals/{referralId}/clicked
       * @param {object} parameters
       * @param {string} parameters.referralId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      clicked({ referralId } = {}, options = {}) {
        const uri = encodePath`/api/referrals/${referralId}/clicked`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * referral.listAllReferrals
       * GET /api/referrals/all
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listAllReferrals(options = {}) {
        const uri = '/api/referrals/all'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * referral.listReferrals
       * GET /api/referrals
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listReferrals(options = {}) {
        const uri = '/api/referrals'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * referral.resendEmail
       * PUT /api/referrals/{referralId}/resendEmail
       * @param {object} parameters
       * @param {string} parameters.referralId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      resendEmail({ referralId } = {}, options = {}) {
        const uri = encodePath`/api/referrals/${referralId}/resendEmail`
        return this.api.fetch(uri, 'PUT', null, null, options)
      },
    }

    this.robots = {
      api: this,

      /**
       * robots.robotsTxt
       * GET /robots.txt
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      robotsTxt(options = {}) {
        const uri = '/robots.txt'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * robots.sitemapXml
       * GET /sitemap.xml
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      sitemapXml(options = {}) {
        const uri = '/sitemap.xml'
        return this.api.fetch(uri, 'GET', null, null, options)
      },
    }

    this.role = {
      api: this,

      /**
       * role.checkUserEmailsForOrg
       * GET /api/role/org/{orgId}/email-check/{userId}/{proposedOrgId}
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {number} parameters.proposedOrgId Optional
       * @param {string} parameters.userId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      checkUserEmailsForOrg({ orgId, proposedOrgId, userId } = {}, options = {}) {
        const uri = encodePathWithOptionalParts(1)`/api/role/org/${orgId}/email-check/${userId}/${proposedOrgId}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * role.claimNotEnrolledStudent
       * POST /api/role/org/student/not-enrolled
       * @param {object} body `NewNotEnrolledStudentViewModel` Required
       * @param {string} body.discipline Optional
       * @param {string} body.otherDiscipline Optional
       * @param {number} body.studentYear Optional
       * @param {string} body.studentGraduationDate Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      claimNotEnrolledStudent(body, options = {}) {
        const uri = '/api/role/org/student/not-enrolled'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * role.claimStaffAtNewConsortium
       * POST /api/role/org/new/consortium
       * @param {object} body `NewRoleViewModel` Required
       * @param {object} body.featureType `FeatureType` Required
       * @param {string} body.name Required
       * @param {string} body.websiteUrl Required
       * @param {string} body.address Required
       * @param {string} body.title Optional
       * @param {number} body.selectedFeaturePackageVersionId Optional
       * @param {string} body.promotionCode Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      claimStaffAtNewConsortium(body, options = {}) {
        const uri = '/api/role/org/new/consortium'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * role.claimStaffAtNewOrg
       * POST /api/role/org/new
       * @param {object} body `NewRoleViewModel` Required
       * @param {object} body.featureType `FeatureType` Required
       * @param {string} body.name Required
       * @param {string} body.websiteUrl Required
       * @param {string} body.address Required
       * @param {string} body.title Optional
       * @param {number} body.selectedFeaturePackageVersionId Optional
       * @param {string} body.promotionCode Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      claimStaffAtNewOrg(body, options = {}) {
        const uri = '/api/role/org/new'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * role.claimStaffAtOrg
       * POST /api/role/org
       * @param {object} body `ClaimStaffAtOrgRequest` Required
       * @param {number} body.orgId Optional
       * @param {string} body.proposeEmailForOrgDomain Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      claimStaffAtOrg(body, options = {}) {
        const uri = '/api/role/org'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * role.claimStudent
       * POST /api/role/org/student
       * @param {object} body `NewStudentWithExistingSchoolViewModel` Required
       * @param {number} body.orgId Optional
       * @param {string} body.proposeEmailForOrgDomain Optional
       * @param {number} body.programOrgId Optional
       * @param {string} body.newProgramName Optional
       * @param {string} body.discipline Optional
       * @param {string} body.otherDiscipline Optional
       * @param {number} body.studentYear Optional
       * @param {string} body.studentGraduationDate Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      claimStudent(body, options = {}) {
        const uri = '/api/role/org/student'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * role.claimStudentAtNewOrg
       * POST /api/role/org/new/student
       * @param {object} body `NewStudentWithNewSchoolViewModel` Required
       * @param {string} body.name Required
       * @param {string} body.address Required
       * @param {string} body.websiteUrl Required
       * @param {number} body.programOrgId Optional
       * @param {string} body.newProgramName Optional
       * @param {string} body.discipline Optional
       * @param {string} body.otherDiscipline Optional
       * @param {number} body.studentYear Optional
       * @param {string} body.studentGraduationDate Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      claimStudentAtNewOrg(body, options = {}) {
        const uri = '/api/role/org/new/student'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * role.getStaffRoleOptions
       * POST /api/role/org/{orgId}/role-options
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} body `StaffRoleOptionsRequest` Required
       * @param {array} body.orgIds Optional
       * @param {array} body.teamIds Optional
       * @param {boolean} body.hasHealthFeature Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getStaffRoleOptions({ orgId } = {}, body, options = {}) {
        const { cache } = this.getStaffRoleOptions
        const pathParams = { orgId }
        body = _.cloneDeep(body)
        body.orgIds.sort((a, b) => a - b)
        body.teamIds.sort((a, b) => a - b)
        const cachePromise = cache.tryGet(pathParams, body, options)
        if (cachePromise) return cachePromise
        const uri = encodePath`/api/role/org/${orgId}/role-options`
        const promise = this.api.fetch(uri, 'POST', null, body, options)
        cache.add(_.cloneDeep(pathParams), body, options, promise)
        return promise
      },

      /**
       * role.removeSelfFromOrg
       * DELETE /api/role/org/{orgId}
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {number} parameters.staffId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      removeSelfFromOrg({ orgId, staffId } = {}, options = {}) {
        const uri = encodePath`/api/role/org/${orgId}`
        return this.api.fetch(uri, 'DELETE', { staffId }, null, options)
      },

      /**
       * role.removeSelfFromOrgAsStudent
       * DELETE /api/role/org/{orgId}/student/{schoolStudentId}
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {number} parameters.schoolStudentId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      removeSelfFromOrgAsStudent({ orgId, schoolStudentId } = {}, options = {}) {
        const uri = encodePath`/api/role/org/${orgId}/student/${schoolStudentId}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },

      /**
       * role.saveAddRoleFormState
       * PUT /api/role/save-add-role-form-state
       * @param {string} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      saveAddRoleFormState(body, options = {}) {
        const uri = '/api/role/save-add-role-form-state'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.role.getStaffRoleOptions.cache = new ApiCache(15, true)

    this.rotationBreakdownReport = {
      api: this,

      /**
       * rotationBreakdownReport.exportReport
       * POST /api/reports/rotation-breakdown/export
       * @param {object} body `ReportCriteria` Required
       * @param {string} body.startDate Optional
       * @param {string} body.endDate Optional
       * @param {object} body.status `MatchStatus` Required
       * @param {string} body.zoom Optional
       * @param {object} body.countBy `CountBy` Required
       * @param {boolean} body.includePlaceholderStudentCount Optional
       * @param {object} body.dateCountType `DateCountType` Required
       * @param {array} body.categories Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {string} body.sortProperty Optional
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      exportReport(body, options = {}) {
        const uri = '/api/reports/rotation-breakdown/export'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * rotationBreakdownReport.run
       * POST /api/reports/rotation-breakdown
       * @param {object} body `ReportCriteria` Required
       * @param {string} body.startDate Optional
       * @param {string} body.endDate Optional
       * @param {object} body.status `MatchStatus` Required
       * @param {string} body.zoom Optional
       * @param {object} body.countBy `CountBy` Required
       * @param {boolean} body.includePlaceholderStudentCount Optional
       * @param {object} body.dateCountType `DateCountType` Required
       * @param {array} body.categories Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {string} body.sortProperty Optional
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      run(body, options = {}) {
        const uri = '/api/reports/rotation-breakdown'
        return this.api.fetch(uri, 'POST', null, body, options)
      },
    }

    this.rotationStatusHistoryReport = {
      api: this,

      /**
       * rotationStatusHistoryReport.listMatches
       * POST /api/reports/rotation-status-history/list
       * @param {object} body `ReportCriteria` Required
       * @param {string} body.startDate Optional
       * @param {string} body.endDate Optional
       * @param {object} body.status `MatchStatus` Required
       * @param {string} body.zoom Optional
       * @param {object} body.countBy `CountBy` Required
       * @param {boolean} body.includePlaceholderStudentCount Optional
       * @param {object} body.dateCountType `DateCountType` Required
       * @param {array} body.categories Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {string} body.sortProperty Optional
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listMatches(body, options = {}) {
        const uri = '/api/reports/rotation-status-history/list'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * rotationStatusHistoryReport.run
       * POST /api/reports/rotation-status-history
       * @param {object} body `ReportCriteria` Required
       * @param {string} body.startDate Optional
       * @param {string} body.endDate Optional
       * @param {object} body.status `MatchStatus` Required
       * @param {string} body.zoom Optional
       * @param {object} body.countBy `CountBy` Required
       * @param {boolean} body.includePlaceholderStudentCount Optional
       * @param {object} body.dateCountType `DateCountType` Required
       * @param {array} body.categories Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {string} body.sortProperty Optional
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      run(body, options = {}) {
        const uri = '/api/reports/rotation-status-history'
        return this.api.fetch(uri, 'POST', null, body, options)
      },
    }

    this.savedSearch = {
      api: this,

      /**
       * savedSearch.create
       * POST /api/saved-search
       * @param {object} body `SavedSearch` Required
       * @param {number} body.savedSearchId Optional
       * @param {object} body.savedSearchType `SavedSearchType` Required
       * @param {string} body.userId Required
       * @param {object} body.personaType `PersonaType` Required
       * @param {string} body.name Required
       * @param {string} body.slug Optional
       * @param {string} body.color Optional
       * @param {string} body.icon Optional
       * @param {string} body.description Optional
       * @param {string} body.search Optional
       * @param {boolean} body.isOwner Optional
       * @param {string} body.ownerName Optional
       * @param {string} body.ownerUserName Optional
       * @param {array} body.savedSearchOrgs `SavedSearchOrg` Optional
       * @param {array} body.sharedOrgIds Optional
       * @param {object} body.savedSearchSetting `SavedSearchSetting` Optional
       * @param {string} body.dateCreated Required
       * @param {string} body.dateModified Required
       * @param {string} body.userCreated Optional
       * @param {string} body.userModified Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      create(body, options = {}) {
        const uri = '/api/saved-search'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * savedSearch.delete
       * DELETE /api/saved-search/{savedSearchId}
       * @param {object} parameters
       * @param {number} parameters.savedSearchId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      delete({ savedSearchId } = {}, options = {}) {
        const uri = encodePath`/api/saved-search/${savedSearchId}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },

      /**
       * savedSearch.list
       * GET /api/saved-search
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      list(options = {}) {
        const uri = '/api/saved-search'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * savedSearch.listSharedOrgs
       * GET /api/saved-search/{savedSearchId}/sharing
       * @param {object} parameters
       * @param {number} parameters.savedSearchId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listSharedOrgs({ savedSearchId } = {}, options = {}) {
        const uri = encodePath`/api/saved-search/${savedSearchId}/sharing`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * savedSearch.saveFavOrder
       * PUT /api/saved-search/save-fav-order
       * @param {array} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      saveFavOrder(body, options = {}) {
        const uri = '/api/saved-search/save-fav-order'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * savedSearch.saveOrder
       * PUT /api/saved-search/save-order
       * @param {array} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      saveOrder(body, options = {}) {
        const uri = '/api/saved-search/save-order'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * savedSearch.saveSharing
       * PUT /api/saved-search/{savedSearchId}/sharing
       * @param {object} parameters
       * @param {number} parameters.savedSearchId Required
       * @param {array} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      saveSharing({ savedSearchId } = {}, body, options = {}) {
        const uri = encodePath`/api/saved-search/${savedSearchId}/sharing`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * savedSearch.setFavorite
       * PUT /api/saved-search/{savedSearchId}/favorite/{favorite}
       * @param {object} parameters
       * @param {boolean} parameters.favorite Required
       * @param {number} parameters.savedSearchId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      setFavorite({ favorite, savedSearchId } = {}, options = {}) {
        const uri = encodePath`/api/saved-search/${savedSearchId}/favorite/${favorite}`
        return this.api.fetch(uri, 'PUT', null, null, options)
      },

      /**
       * savedSearch.setVisible
       * PUT /api/saved-search/{savedSearchId}/visible/{visible}
       * @param {object} parameters
       * @param {number} parameters.savedSearchId Required
       * @param {boolean} parameters.visible Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      setVisible({ savedSearchId, visible } = {}, options = {}) {
        const uri = encodePath`/api/saved-search/${savedSearchId}/visible/${visible}`
        return this.api.fetch(uri, 'PUT', null, null, options)
      },

      /**
       * savedSearch.update
       * PUT /api/saved-search
       * @param {object} body `SavedSearch` Required
       * @param {number} body.savedSearchId Optional
       * @param {object} body.savedSearchType `SavedSearchType` Required
       * @param {string} body.userId Required
       * @param {object} body.personaType `PersonaType` Required
       * @param {string} body.name Required
       * @param {string} body.slug Optional
       * @param {string} body.color Optional
       * @param {string} body.icon Optional
       * @param {string} body.description Optional
       * @param {string} body.search Optional
       * @param {boolean} body.isOwner Optional
       * @param {string} body.ownerName Optional
       * @param {string} body.ownerUserName Optional
       * @param {array} body.savedSearchOrgs `SavedSearchOrg` Optional
       * @param {array} body.sharedOrgIds Optional
       * @param {object} body.savedSearchSetting `SavedSearchSetting` Optional
       * @param {string} body.dateCreated Required
       * @param {string} body.dateModified Required
       * @param {string} body.userCreated Optional
       * @param {string} body.userModified Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      update(body, options = {}) {
        const uri = '/api/saved-search'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * savedSearch.upgradeAll
       * GET /api/saved-search/upgrade-all
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      upgradeAll(options = {}) {
        const uri = '/api/saved-search/upgrade-all'
        return this.api.fetch(uri, 'GET', null, null, options)
      },
    }

    this.school = {
      api: this,

      /**
       * school.addStudent
       * POST /api/schools/{orgId}/students
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} body `AddStudentViewModel` Required
       * @param {string} body.name Optional
       * @param {string} body.email Required
       * @param {string} body.legalFirstName Required
       * @param {string} body.legalLastName Required
       * @param {number} body.orgId Optional
       * @param {number} body.studentYear Optional
       * @param {string} body.discipline Optional
       * @param {string} body.otherDiscipline Optional
       * @param {string} body.studentGraduationDate Optional
       * @param {array} body.tags Optional
       * @param {string} body.customMessage Optional
       * @param {string} body.customFieldValuesJson Optional
       * @param {object} body.customFieldValues Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addStudent({ orgId } = {}, body, options = {}) {
        const uri = encodePath`/api/schools/${orgId}/students`
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * school.confirmStudent
       * PUT /api/schools/{orgId}/students/{studentId}/confirm
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {string} parameters.studentId Required
       * @param {object} body `ConfirmStudentRequestBody` Required
       * @param {string} body.allowEmailOrgDomain Optional
       * @param {string} body.email Optional
       * @param {number} body.domainId Optional
       * @param {number} body.proposedOrgId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      confirmStudent({ orgId, studentId } = {}, body, options = {}) {
        const uri = encodePath`/api/schools/${orgId}/students/${studentId}/confirm`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * school.readdStudents
       * PUT /api/schools/{orgId}/students/readd
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} body `ToggleStudentsRequestBody` Required
       * @param {array} body.schoolStudentCompositeIds `CompositeSchoolStudentId` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      readdStudents({ orgId } = {}, body, options = {}) {
        const uri = encodePath`/api/schools/${orgId}/students/readd`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * school.rejectStudent
       * PUT /api/schools/{orgId}/students/{studentUserId}/{schoolStudentId}/reject
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {number} parameters.schoolStudentId Required
       * @param {string} parameters.studentUserId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      rejectStudent({ orgId, schoolStudentId, studentUserId } = {}, options = {}) {
        const uri = encodePath`/api/schools/${orgId}/students/${studentUserId}/${schoolStudentId}/reject`
        return this.api.fetch(uri, 'PUT', null, null, options)
      },

      /**
       * school.removeStudents
       * PUT /api/schools/{orgId}/students/remove
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} body `ToggleStudentsRequestBody` Required
       * @param {array} body.schoolStudentCompositeIds `CompositeSchoolStudentId` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      removeStudents({ orgId } = {}, body, options = {}) {
        const uri = encodePath`/api/schools/${orgId}/students/remove`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * school.resendInvite
       * PUT /api/schools/{orgId}/students/{studentId}/resendinvite
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {string} parameters.studentId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      resendInvite({ orgId, studentId } = {}, options = {}) {
        const uri = encodePath`/api/schools/${orgId}/students/${studentId}/resendinvite`
        return this.api.fetch(uri, 'PUT', null, null, options)
      },

      /**
       * school.updateStudents
       * PUT /api/schools/{orgId}/students
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} body `UpdateBulkStudentsRequest` Required
       * @param {number} body.studentYear Optional
       * @param {array} body.schoolStudentCompositeIds `CompositeSchoolStudentId` Optional
       * @param {number} body.orgId Optional
       * @param {string} body.graduationDate Optional
       * @param {string} body.discipline Optional
       * @param {string} body.otherDiscipline Optional
       * @param {string} body.customFieldValuesJson Optional
       * @param {array} body.tags Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      updateStudents({ orgId } = {}, body, options = {}) {
        const uri = encodePath`/api/schools/${orgId}/students`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.search = {
      api: this,

      /**
       * search.rebuildProfileIndex
       * GET /api/search/rebuildProfileIndex
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      rebuildProfileIndex(options = {}) {
        const uri = '/api/search/rebuildProfileIndex'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * search.searchProfiles
       * POST /api/search
       * @param {object} body `SearchProfilesRequest` Required
       * @param {string} body.search Optional
       * @param {string} body.type Optional
       * @param {array} body.rotationTypes Optional
       * @param {array} body.orgRoles Optional
       * @param {string} body.discipline Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      searchProfiles(body, options = {}) {
        const uri = '/api/search'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * search.searchUsers
       * POST /api/search/users
       * @param {object} body `SearchUsersRequest` Required
       * @param {array} body.orgIds Optional
       * @param {array} body.staffRoles `StaffRole` Optional
       * @param {string} body.search Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      searchUsers(body, options = {}) {
        const uri = '/api/search/users'
        return this.api.fetch(uri, 'POST', null, body, options)
      },
    }

    this.service = {
      api: this,

      /**
       * service.add
       * POST /api/services
       * @param {object} body `SaveServiceRequest` Required
       * @param {array} body.agreementIds Optional
       * @param {number} body.serviceId Optional
       * @param {number} body.orgId Optional
       * @param {number} body.teamId Optional
       * @param {string} body.name Optional
       * @param {string} body.description Optional
       * @param {array} body.specialties Optional
       * @param {array} body.disciplines Optional
       * @param {array} body.teamDisciplines Optional
       * @param {object} body.shouldSerialize `ShouldSerializeProps` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      add(body, options = {}) {
        const uri = '/api/services'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * service.canDelete
       * GET /api/services/{serviceId}/can-delete
       * @param {object} parameters
       * @param {number} parameters.serviceId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      canDelete({ serviceId } = {}, options = {}) {
        const uri = encodePath`/api/services/${serviceId}/can-delete`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * service.export
       * POST /api/services/export
       * @param {object} body `ExportServicesRequestBody` Required
       * @param {boolean} body.includeAddresses Optional
       * @param {boolean} body.includeSpecialties Optional
       * @param {boolean} body.includeAgreements Optional
       * @param {boolean} body.includeDisciplines Optional
       * @param {boolean} body.includeCapacities Optional
       * @param {boolean} body.includeOrgAndTeam Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {object} body.sortProperty `ServiceListProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      export(body, options = {}) {
        const uri = '/api/services/export'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * service.get
       * GET /api/services/{serviceId}
       * @param {object} parameters
       * @param {number} parameters.serviceId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      get({ serviceId } = {}, options = {}) {
        const uri = encodePath`/api/services/${serviceId}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * service.list
       * POST /api/services/list
       * @param {object} body `ListServicesRequestBody` Required
       * @param {boolean} body.includeAddresses Optional
       * @param {boolean} body.includeSpecialties Optional
       * @param {boolean} body.includeAgreements Optional
       * @param {boolean} body.includeDisciplines Optional
       * @param {boolean} body.includeCapacities Optional
       * @param {boolean} body.includeOrgAndTeam Optional
       * @param {boolean} body.excludeTotalCount Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {array} body.selectedServiceIds Optional
       * @param {object} body.sortProperty `ServiceListProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      list(body, options = {}) {
        const uri = '/api/services/list'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * service.softDelete
       * DELETE /api/services/{serviceId}
       * @param {object} parameters
       * @param {number} parameters.serviceId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      softDelete({ serviceId } = {}, options = {}) {
        const uri = encodePath`/api/services/${serviceId}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },

      /**
       * service.update
       * PUT /api/services/{serviceId}
       * @param {object} parameters
       * @param {number} parameters.serviceId Required
       * @param {object} body `SaveServiceRequest` Required
       * @param {array} body.agreementIds Optional
       * @param {number} body.serviceId Optional
       * @param {number} body.orgId Optional
       * @param {number} body.teamId Optional
       * @param {string} body.name Optional
       * @param {string} body.description Optional
       * @param {array} body.specialties Optional
       * @param {array} body.disciplines Optional
       * @param {array} body.teamDisciplines Optional
       * @param {object} body.shouldSerialize `ShouldSerializeProps` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      update({ serviceId } = {}, body, options = {}) {
        const uri = encodePath`/api/services/${serviceId}`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.serviceFilterOptions = {
      api: this,

      /**
       * serviceFilterOptions.getDisciplines
       * GET /api/filter-options/for-services/disciplines
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getDisciplines(options = {}) {
        const uri = '/api/filter-options/for-services/disciplines'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * serviceFilterOptions.getOrgs
       * GET /api/filter-options/for-services/orgs
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getOrgs(options = {}) {
        const uri = '/api/filter-options/for-services/orgs'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * serviceFilterOptions.getSpecialties
       * GET /api/filter-options/for-services/specialties
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getSpecialties(options = {}) {
        const uri = '/api/filter-options/for-services/specialties'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * serviceFilterOptions.getTeams
       * GET /api/filter-options/for-services/teams
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getTeams(options = {}) {
        const uri = '/api/filter-options/for-services/teams'
        return this.api.fetch(uri, 'GET', null, null, options)
      },
    }

    this.sso = {
      api: this,

      /**
       * sso.getProvidersByEmail
       * GET /api/sso/provider
       * @param {object} parameters
       * @param {string} parameters.email Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getProvidersByEmail({ email } = {}, options = {}) {
        const uri = '/api/sso/provider'
        return this.api.fetch(uri, 'GET', { email }, null, options)
      },

      /**
       * sso.listSchemes
       * GET /api/sso/schemes
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listSchemes(options = {}) {
        const uri = '/api/sso/schemes'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * sso.rebuildExistingSsoProviders
       * GET /api/sso/startup
       * @param {object} parameters
       * @param {string} parameters.scheme Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      rebuildExistingSsoProviders({ scheme } = {}, options = {}) {
        const uri = '/api/sso/startup'
        return this.api.fetch(uri, 'GET', { scheme }, null, options)
      },
    }

    this.staff = {
      api: this,

      /**
       * staff.getStaffUser
       * POST /api/staff/add/{userName}
       * @param {object} parameters
       * @param {string} parameters.userName Required
       * @param {object} body `StaffUserRequestBody` Required
       * @param {number} body.serviceId Optional
       * @param {number} body.capacityId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getStaffUser({ userName } = {}, body, options = {}) {
        const uri = encodePath`/api/staff/add/${userName}`
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * staff.list
       * POST /api/staff/list
       * @param {object} body `ListStaffRequestBody` Required
       * @param {number} body.orgId Optional
       * @param {number} body.staffId Optional
       * @param {boolean} body.includeAllEmails Optional
       * @param {boolean} body.includeCustomFields Optional
       * @param {boolean} body.includeChildren Optional
       * @param {boolean} body.includeEmail Optional
       * @param {boolean} body.includeParents Optional
       * @param {boolean} body.includeIsAllStaffCxFlag Optional
       * @param {boolean} body.includeLastActivityDate Optional
       * @param {boolean} body.excludeTotalCount Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {array} body.selectedStaffIds Optional
       * @param {array} body.selectedUserIds Optional
       * @param {object} body.sortProperty `StaffListProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {string} body.search Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      list(body, options = {}) {
        const uri = '/api/staff/list'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * staff.listPublic
       * POST /api/staff/list-public
       * @param {object} body `ListStaffRequestBody` Required
       * @param {number} body.orgId Optional
       * @param {number} body.staffId Optional
       * @param {boolean} body.includeAllEmails Optional
       * @param {boolean} body.includeCustomFields Optional
       * @param {boolean} body.includeChildren Optional
       * @param {boolean} body.includeEmail Optional
       * @param {boolean} body.includeParents Optional
       * @param {boolean} body.includeIsAllStaffCxFlag Optional
       * @param {boolean} body.includeLastActivityDate Optional
       * @param {boolean} body.excludeTotalCount Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {array} body.selectedStaffIds Optional
       * @param {array} body.selectedUserIds Optional
       * @param {object} body.sortProperty `StaffListProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {string} body.search Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listPublic(body, options = {}) {
        const uri = '/api/staff/list-public'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * staff.search
       * POST /api/staff/search
       * @param {object} body `StaffSearchRequest` Required
       * @param {number} body.serviceId Optional
       * @param {number} body.capacityId Optional
       * @param {boolean} body.includeServiceStaff Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      search(body, options = {}) {
        const uri = '/api/staff/search'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * staff.searchToAdd
       * POST /api/staff/add
       * @param {object} body `StaffToAddSearchRequest` Required
       * @param {number} body.serviceId Optional
       * @param {number} body.capacityId Optional
       * @param {boolean} body.searchAllUsers Optional
       * @param {string} body.search Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      searchToAdd(body, options = {}) {
        const uri = '/api/staff/add'
        return this.api.fetch(uri, 'POST', null, body, options)
      },
    }

    this.step = {
      api: this,

      /**
       * step.addFilesToMatchSteps
       * POST /api/steps/step-submission-files
       * @param {object} parameters
       * @param {string} parameters.endDateOverride Optional
       * @param {string} parameters.startDateOverride Optional
       * @param {object} body `StepSubmissionsWithFilesRequest` Required
       * @param {array} body.files `StepSubmissionFile` Required
       * @param {array} body.stepSubmissions `StepSubmission` Optional
       * @param {string} body.verificationExpirationDateSuggestion Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addFilesToMatchSteps({ endDateOverride, startDateOverride } = {}, body, options = {}) {
        const uri = '/api/steps/step-submission-files'
        return this.api.fetch(uri, 'POST', { endDateOverride, startDateOverride }, body, options)
      },

      /**
       * step.addFilesToStep
       * POST /api/steps/{stepId}/files
       * @param {object} parameters
       * @param {number} parameters.stepId Required
       * @param {object} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addFilesToStep({ stepId } = {}, body, options = {}) {
        const uri = encodePath`/api/steps/${stepId}/files`
        options = { ...options, isFileUpload: true }
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * step.addStep
       * POST /api/steps
       * @param {object} body `AddStepRequest` Required
       * @param {object} body.completion `StepCompletion` Optional
       * @param {array} body.config `JToken` Optional
       * @param {array} body.rules `StepRule` Optional
       * @param {object} body.verifiers `StepVerifiers` Optional
       * @param {object} body.viewers `StepViewers` Optional
       * @param {array} body.files `StepFileRequest` Optional
       * @param {number} body.orgId Optional
       * @param {number} body.teamId Optional
       * @param {string} body.orgName Optional
       * @param {string} body.orgRelativeName Optional
       * @param {number} body.teamOrgId Optional
       * @param {string} body.teamName Optional
       * @param {string} body.description Optional
       * @param {string} body.name Required
       * @param {object} body.stepType `StepType` Required
       * @param {object} body.stepInputType `StepInputType` Required
       * @param {boolean} body.otherOrgCoordinatorsCanAlsoComplete Optional
       * @param {boolean} body.sameOrgCoordinatorsCanAlsoComplete Optional
       * @param {object} body.completeFrom `StepCompletionWhen` Required
       * @param {number} body.completeFromAddDays Required
       * @param {object} body.completeTo `StepCompletionWhen` Required
       * @param {number} body.completeToAddDays Required
       * @param {array} body.completeToDateConfigurations `StepCompleteToDateConfiguration` Optional
       * @param {object} body.stepIncompleteAction `StepIncompleteAction` Required
       * @param {number} body.stepIncompleteActionAddDays Optional
       * @param {boolean} body.stepIncompleteActionAnyCompleter Optional
       * @param {boolean} body.perMatch Optional
       * @param {object} body.assignment `StepCompletionAssignment` Optional
       * @param {object} body.verificationType `StepVerificationType` Required
       * @param {boolean} body.requireVerificationExpiration Optional
       * @param {boolean} body.allowWaiving Optional
       * @param {boolean} body.allowSkipping Optional
       * @param {boolean} body.shouldHideRespondentIdentity Optional
       * @param {boolean} body.shouldHideUntilAllComplete Optional
       * @param {number} body.hideUntilNDaysAfterSubmission Optional
       * @param {number} body.hideUntilNSubmissionsReceived Optional
       * @param {array} body.acceptableFileTypes `FileTypeViewModel` Optional
       * @param {array} body.acceptableFileTypeIds Optional
       * @param {array} body.stepReminders Optional
       * @param {string} body.deletedDateTime Optional
       * @param {string} body.dateTimeArchived Optional
       * @param {string} body.lockedDateTime Optional
       * @param {string} body.lockReasonDateTime Optional
       * @param {string} body.lockReasonUserId Optional
       * @param {string} body.lockReasonUserName Optional
       * @param {string} body.lockReasonName Optional
       * @param {string} body.lockReasonProfilePicture Optional
       * @param {string} body.lockReason Optional
       * @param {string} body.lockReasonInitials Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addStep(body, options = {}) {
        const uri = '/api/steps'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * step.addUserDocument
       * POST /api/steps/user-documents
       * @param {number} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addUserDocument(body, options = {}) {
        const uri = '/api/steps/user-documents'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * step.addUserFiles
       * POST /api/steps/user-files
       * @param {object} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addUserFiles(body, options = {}) {
        const uri = '/api/steps/user-files'
        options = { ...options, isFileUpload: true }
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * step.archiveStep
       * PUT /api/steps/{stepId}/archive
       * @param {object} parameters
       * @param {number} parameters.stepId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      archiveStep({ stepId } = {}, options = {}) {
        const uri = encodePath`/api/steps/${stepId}/archive`
        return this.api.fetch(uri, 'PUT', null, null, options)
      },

      /**
       * step.deleteStep
       * DELETE /api/steps/{stepId}
       * @param {object} parameters
       * @param {number} parameters.stepId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      deleteStep({ stepId } = {}, options = {}) {
        const uri = encodePath`/api/steps/${stepId}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },

      /**
       * step.deleteStepFile
       * DELETE /api/steps/{stepId}/files/{stepFileId}
       * @param {object} parameters
       * @param {number} parameters.stepFileId Required
       * @param {number} parameters.stepId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      deleteStepFile({ stepFileId, stepId } = {}, options = {}) {
        const uri = encodePath`/api/steps/${stepId}/files/${stepFileId}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },

      /**
       * step.deleteStepSubmissionFiles
       * DELETE /api/steps/step-submission-files
       * @param {object} parameters
       * @param {string} parameters.endDateOverride Optional
       * @param {string} parameters.startDateOverride Optional
       * @param {object} body `StepSubmissionsWithFilesRequest` Required
       * @param {array} body.files `StepSubmissionFile` Required
       * @param {array} body.stepSubmissions `StepSubmission` Optional
       * @param {string} body.verificationExpirationDateSuggestion Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      deleteStepSubmissionFiles({ endDateOverride, startDateOverride } = {}, body, options = {}) {
        const uri = '/api/steps/step-submission-files'
        return this.api.fetch(uri, 'DELETE', { endDateOverride, startDateOverride }, body, options)
      },

      /**
       * step.getMyStepsDefaultFiltersSchool
       * GET /api/steps/my-steps-default-filters-school
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getMyStepsDefaultFiltersSchool(options = {}) {
        const uri = '/api/steps/my-steps-default-filters-school'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * step.getStep
       * GET /api/steps/{orgId}/{stepId}
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {number} parameters.stepId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getStep({ orgId, stepId } = {}, options = {}) {
        const uri = encodePath`/api/steps/${orgId}/${stepId}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * step.getStepFormSubmission
       * GET /api/steps/{stepId}/submission/{stepSubmissionId}
       * @param {object} parameters
       * @param {string} parameters.endDateOverride Optional
       * @param {number} parameters.formSubmissionId Optional
       * @param {string} parameters.startDateOverride Optional
       * @param {number} parameters.stepId Required
       * @param {number} parameters.stepSubmissionId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getStepFormSubmission({ endDateOverride, formSubmissionId, startDateOverride, stepId, stepSubmissionId } = {}, options = {}) {
        const uri = encodePathWithOptionalParts(1)`/api/steps/${stepId}/submission/${stepSubmissionId}`
        return this.api.fetch(uri, 'GET', { endDateOverride, formSubmissionId, startDateOverride }, null, options)
      },

      /**
       * step.listStepsByStepType
       * GET /api/steps/{orgId}/stepType/{stepType}
       * @param {object} parameters
       * @param {object} parameters.archiveActiveFilter `ArchiveActiveFilter` Optional
       * @param {boolean} parameters.includeArchived Optional
       * @param {boolean} parameters.includeInherited Optional
       * @param {boolean} parameters.includePageOfData Optional
       * @param {number} parameters.offset Optional
       * @param {number} parameters.orgId Required
       * @param {number} parameters.pageSize Optional
       * @param {string} parameters.search Optional
       * @param {boolean} parameters.showEditableOnly Optional
       * @param {boolean} parameters.sortAscending Optional
       * @param {object} parameters.sortProperty `StepListProperty` Optional
       * @param {object} parameters.stepType `StepType` Required
       * @param {number} parameters.teamId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listStepsByStepType({ archiveActiveFilter, includeArchived, includeInherited, includePageOfData, offset, orgId, pageSize, search, showEditableOnly, sortAscending, sortProperty, stepType, teamId } = {}, options = {}) {
        const uri = encodePath`/api/steps/${orgId}/stepType/${stepType}`
        return this.api.fetch(uri, 'GET', { archiveActiveFilter, includeArchived, includeInherited, includePageOfData, offset, pageSize, search, showEditableOnly, sortAscending, sortProperty, teamId }, null, options)
      },

      /**
       * step.lockStep
       * PUT /api/steps/{stepId}/lock
       * @param {object} parameters
       * @param {number} parameters.stepId Required
       * @param {string} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      lockStep({ stepId } = {}, body, options = {}) {
        const uri = encodePath`/api/steps/${stepId}/lock`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * step.saveStepFormSubmission
       * PUT /api/steps/match/form
       * @param {object} parameters
       * @param {string} parameters.endDateOverride Optional
       * @param {string} parameters.startDateOverride Optional
       * @param {object} body `StepFormSubmissionViewModel` Required
       * @param {object} body.values Optional
       * @param {number} body.formSubmissionId Optional
       * @param {array} body.stepSubmissions `StepSubmission` Optional
       * @param {string} body.verificationExpirationDateSuggestion Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      saveStepFormSubmission({ endDateOverride, startDateOverride } = {}, body, options = {}) {
        const uri = '/api/steps/match/form'
        return this.api.fetch(uri, 'PUT', { endDateOverride, startDateOverride }, body, options)
      },

      /**
       * step.setStepsCompleted
       * PUT /api/steps/complete
       * @param {object} parameters
       * @param {boolean} parameters.completed Optional
       * @param {string} parameters.endDateOverride Optional
       * @param {string} parameters.startDateOverride Optional
       * @param {object} body `StepSubmissionsRequest` Required
       * @param {array} body.stepSubmissions `StepSubmission` Optional
       * @param {string} body.verificationExpirationDateSuggestion Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      setStepsCompleted({ completed, endDateOverride, startDateOverride } = {}, body, options = {}) {
        const uri = '/api/steps/complete'
        return this.api.fetch(uri, 'PUT', { completed, endDateOverride, startDateOverride }, body, options)
      },

      /**
       * step.setStepsDueDate
       * PUT /api/steps/set-due-date
       * @param {object} parameters
       * @param {string} parameters.dueDate Optional
       * @param {string} parameters.endDateOverride Optional
       * @param {string} parameters.startDateOverride Optional
       * @param {array} body `StepSubmission` Required
       * @param {number} body.stepSubmissionId Optional
       * @param {number} body.matchUserStepId Optional
       * @param {string} body.userId Optional
       * @param {number} body.matchId Optional
       * @param {number} body.stepId Optional
       * @param {string} body.targetUserId Optional
       * @param {string} body.completedUserId Optional
       * @param {boolean} body.completed Optional
       * @param {string} body.completedDate Optional
       * @param {string} body.submissionInformationMarkdown Optional
       * @param {string} body.waivedUserId Optional
       * @param {boolean} body.waived Optional
       * @param {string} body.waivedDate Optional
       * @param {string} body.skippedUserId Optional
       * @param {boolean} body.skipped Optional
       * @param {string} body.skippedDate Optional
       * @param {string} body.verifiedUserId Optional
       * @param {object} body.verificationStatus `StepVerificationStatus` Required
       * @param {string} body.verifiedDate Optional
       * @param {string} body.verificationExpirationDate Optional
       * @param {string} body.verificationExpirationDateSuggestion Optional
       * @param {string} body.rejectedReason Optional
       * @param {number} body.formSubmissionId Optional
       * @param {object} body.status `StepSubmissionStatus` Required
       * @param {object} body.step `Step` Optional
       * @param {object} body.user `User` Optional
       * @param {object} body.targetUser `User` Optional
       * @param {object} body.completedUser `User` Optional
       * @param {object} body.waivedUser `User` Optional
       * @param {object} body.skippedUser `User` Optional
       * @param {object} body.verifiedUser `User` Optional
       * @param {array} body.stepSubmissionFiles `StepSubmissionFile` Optional
       * @param {string} body.dateCreated Required
       * @param {string} body.dateModified Required
       * @param {string} body.userCreated Optional
       * @param {string} body.userModified Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      setStepsDueDate({ dueDate, endDateOverride, startDateOverride } = {}, body, options = {}) {
        const uri = '/api/steps/set-due-date'
        return this.api.fetch(uri, 'PUT', { dueDate, endDateOverride, startDateOverride }, body, options)
      },

      /**
       * step.setStepsRejected
       * PUT /api/steps/reject
       * @param {object} parameters
       * @param {string} parameters.endDateOverride Optional
       * @param {string} parameters.startDateOverride Optional
       * @param {object} body `RejectStepViewModel` Required
       * @param {string} body.rejectedReason Optional
       * @param {boolean} body.closeMatch Optional
       * @param {array} body.stepSubmissions `StepSubmission` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      setStepsRejected({ endDateOverride, startDateOverride } = {}, body, options = {}) {
        const uri = '/api/steps/reject'
        return this.api.fetch(uri, 'PUT', { endDateOverride, startDateOverride }, body, options)
      },

      /**
       * step.setStepsSkipped
       * PUT /api/steps/skip
       * @param {object} parameters
       * @param {string} parameters.endDateOverride Optional
       * @param {boolean} parameters.skipped Optional
       * @param {string} parameters.startDateOverride Optional
       * @param {array} body `StepSubmission` Required
       * @param {number} body.stepSubmissionId Optional
       * @param {number} body.matchUserStepId Optional
       * @param {string} body.userId Optional
       * @param {number} body.matchId Optional
       * @param {number} body.stepId Optional
       * @param {string} body.targetUserId Optional
       * @param {string} body.completedUserId Optional
       * @param {boolean} body.completed Optional
       * @param {string} body.completedDate Optional
       * @param {string} body.submissionInformationMarkdown Optional
       * @param {string} body.waivedUserId Optional
       * @param {boolean} body.waived Optional
       * @param {string} body.waivedDate Optional
       * @param {string} body.skippedUserId Optional
       * @param {boolean} body.skipped Optional
       * @param {string} body.skippedDate Optional
       * @param {string} body.verifiedUserId Optional
       * @param {object} body.verificationStatus `StepVerificationStatus` Required
       * @param {string} body.verifiedDate Optional
       * @param {string} body.verificationExpirationDate Optional
       * @param {string} body.verificationExpirationDateSuggestion Optional
       * @param {string} body.rejectedReason Optional
       * @param {number} body.formSubmissionId Optional
       * @param {object} body.status `StepSubmissionStatus` Required
       * @param {object} body.step `Step` Optional
       * @param {object} body.user `User` Optional
       * @param {object} body.targetUser `User` Optional
       * @param {object} body.completedUser `User` Optional
       * @param {object} body.waivedUser `User` Optional
       * @param {object} body.skippedUser `User` Optional
       * @param {object} body.verifiedUser `User` Optional
       * @param {array} body.stepSubmissionFiles `StepSubmissionFile` Optional
       * @param {string} body.dateCreated Required
       * @param {string} body.dateModified Required
       * @param {string} body.userCreated Optional
       * @param {string} body.userModified Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      setStepsSkipped({ endDateOverride, skipped, startDateOverride } = {}, body, options = {}) {
        const uri = '/api/steps/skip'
        return this.api.fetch(uri, 'PUT', { endDateOverride, skipped, startDateOverride }, body, options)
      },

      /**
       * step.setStepsVerified
       * PUT /api/steps/verify
       * @param {object} parameters
       * @param {string} parameters.endDateOverride Optional
       * @param {string} parameters.startDateOverride Optional
       * @param {object} body `VerifyStepViewModel` Required
       * @param {string} body.verificationExpirationDate Optional
       * @param {array} body.stepSubmissions `StepSubmission` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      setStepsVerified({ endDateOverride, startDateOverride } = {}, body, options = {}) {
        const uri = '/api/steps/verify'
        return this.api.fetch(uri, 'PUT', { endDateOverride, startDateOverride }, body, options)
      },

      /**
       * step.setStepsWaived
       * PUT /api/steps/waive
       * @param {object} parameters
       * @param {string} parameters.endDateOverride Optional
       * @param {string} parameters.startDateOverride Optional
       * @param {boolean} parameters.waived Optional
       * @param {array} body `StepSubmission` Required
       * @param {number} body.stepSubmissionId Optional
       * @param {number} body.matchUserStepId Optional
       * @param {string} body.userId Optional
       * @param {number} body.matchId Optional
       * @param {number} body.stepId Optional
       * @param {string} body.targetUserId Optional
       * @param {string} body.completedUserId Optional
       * @param {boolean} body.completed Optional
       * @param {string} body.completedDate Optional
       * @param {string} body.submissionInformationMarkdown Optional
       * @param {string} body.waivedUserId Optional
       * @param {boolean} body.waived Optional
       * @param {string} body.waivedDate Optional
       * @param {string} body.skippedUserId Optional
       * @param {boolean} body.skipped Optional
       * @param {string} body.skippedDate Optional
       * @param {string} body.verifiedUserId Optional
       * @param {object} body.verificationStatus `StepVerificationStatus` Required
       * @param {string} body.verifiedDate Optional
       * @param {string} body.verificationExpirationDate Optional
       * @param {string} body.verificationExpirationDateSuggestion Optional
       * @param {string} body.rejectedReason Optional
       * @param {number} body.formSubmissionId Optional
       * @param {object} body.status `StepSubmissionStatus` Required
       * @param {object} body.step `Step` Optional
       * @param {object} body.user `User` Optional
       * @param {object} body.targetUser `User` Optional
       * @param {object} body.completedUser `User` Optional
       * @param {object} body.waivedUser `User` Optional
       * @param {object} body.skippedUser `User` Optional
       * @param {object} body.verifiedUser `User` Optional
       * @param {array} body.stepSubmissionFiles `StepSubmissionFile` Optional
       * @param {string} body.dateCreated Required
       * @param {string} body.dateModified Required
       * @param {string} body.userCreated Optional
       * @param {string} body.userModified Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      setStepsWaived({ endDateOverride, startDateOverride, waived } = {}, body, options = {}) {
        const uri = '/api/steps/waive'
        return this.api.fetch(uri, 'PUT', { endDateOverride, startDateOverride, waived }, body, options)
      },

      /**
       * step.unarchiveStep
       * PUT /api/steps/{stepId}/unarchive
       * @param {object} parameters
       * @param {number} parameters.stepId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      unarchiveStep({ stepId } = {}, options = {}) {
        const uri = encodePath`/api/steps/${stepId}/unarchive`
        return this.api.fetch(uri, 'PUT', null, null, options)
      },

      /**
       * step.undeleteStep
       * PUT /api/steps/{stepId}/undelete
       * @param {object} parameters
       * @param {number} parameters.stepId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      undeleteStep({ stepId } = {}, options = {}) {
        const uri = encodePath`/api/steps/${stepId}/undelete`
        return this.api.fetch(uri, 'PUT', null, null, options)
      },

      /**
       * step.unlockStep
       * PUT /api/steps/{stepId}/unlock
       * @param {object} parameters
       * @param {number} parameters.stepId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      unlockStep({ stepId } = {}, options = {}) {
        const uri = encodePath`/api/steps/${stepId}/unlock`
        return this.api.fetch(uri, 'PUT', null, null, options)
      },

      /**
       * step.updateStep
       * PUT /api/steps/{stepId}
       * @param {object} parameters
       * @param {number} parameters.stepId Required
       * @param {object} body `UpdateStepRequest` Required
       * @param {number} body.stepId Optional
       * @param {boolean} body.archiveNow Optional
       * @param {object} body.completion `StepCompletion` Optional
       * @param {array} body.config `JToken` Optional
       * @param {array} body.rules `StepRule` Optional
       * @param {object} body.verifiers `StepVerifiers` Optional
       * @param {object} body.viewers `StepViewers` Optional
       * @param {array} body.files `StepFileRequest` Optional
       * @param {number} body.orgId Optional
       * @param {number} body.teamId Optional
       * @param {string} body.orgName Optional
       * @param {string} body.orgRelativeName Optional
       * @param {number} body.teamOrgId Optional
       * @param {string} body.teamName Optional
       * @param {string} body.description Optional
       * @param {string} body.name Required
       * @param {object} body.stepType `StepType` Required
       * @param {object} body.stepInputType `StepInputType` Required
       * @param {boolean} body.otherOrgCoordinatorsCanAlsoComplete Optional
       * @param {boolean} body.sameOrgCoordinatorsCanAlsoComplete Optional
       * @param {object} body.completeFrom `StepCompletionWhen` Required
       * @param {number} body.completeFromAddDays Required
       * @param {object} body.completeTo `StepCompletionWhen` Required
       * @param {number} body.completeToAddDays Required
       * @param {array} body.completeToDateConfigurations `StepCompleteToDateConfiguration` Optional
       * @param {object} body.stepIncompleteAction `StepIncompleteAction` Required
       * @param {number} body.stepIncompleteActionAddDays Optional
       * @param {boolean} body.stepIncompleteActionAnyCompleter Optional
       * @param {boolean} body.perMatch Optional
       * @param {object} body.assignment `StepCompletionAssignment` Optional
       * @param {object} body.verificationType `StepVerificationType` Required
       * @param {boolean} body.requireVerificationExpiration Optional
       * @param {boolean} body.allowWaiving Optional
       * @param {boolean} body.allowSkipping Optional
       * @param {boolean} body.shouldHideRespondentIdentity Optional
       * @param {boolean} body.shouldHideUntilAllComplete Optional
       * @param {number} body.hideUntilNDaysAfterSubmission Optional
       * @param {number} body.hideUntilNSubmissionsReceived Optional
       * @param {array} body.acceptableFileTypes `FileTypeViewModel` Optional
       * @param {array} body.acceptableFileTypeIds Optional
       * @param {array} body.stepReminders Optional
       * @param {string} body.deletedDateTime Optional
       * @param {string} body.dateTimeArchived Optional
       * @param {string} body.lockedDateTime Optional
       * @param {string} body.lockReasonDateTime Optional
       * @param {string} body.lockReasonUserId Optional
       * @param {string} body.lockReasonUserName Optional
       * @param {string} body.lockReasonName Optional
       * @param {string} body.lockReasonProfilePicture Optional
       * @param {string} body.lockReason Optional
       * @param {string} body.lockReasonInitials Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      updateStep({ stepId } = {}, body, options = {}) {
        const uri = encodePath`/api/steps/${stepId}`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.stepSubmission = {
      api: this,

      /**
       * stepSubmission.bulkUpdateDueDate
       * POST /api/step-submissions/bulk-update-due-date
       * @param {object} parameters
       * @param {string} parameters.dueDate Optional
       * @param {object} body `ListStepSubmissionRequest` Required
       * @param {string} body.apiVersion Optional
       * @param {number} body.stepSubmissionId Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {array} body.matchFilters `FilterParameter` Optional
       * @param {boolean} body.includeStepStatusData Optional
       * @param {boolean} body.includeSubmissionData Optional
       * @param {boolean} body.includeHealthOrgHierarchy Optional
       * @param {boolean} body.sendFlatSubmissions Optional
       * @param {boolean} body.includeTotalCount Optional
       * @param {boolean} body.excludeTotalCount Optional
       * @param {boolean} body.includeSubmissionStatus Optional
       * @param {boolean} body.includeSteps Optional
       * @param {boolean} body.includeMatches Optional
       * @param {boolean} body.includeHostOrgs Optional
       * @param {boolean} body.includeGuestOrgs Optional
       * @param {boolean} body.includeCapacities Optional
       * @param {boolean} body.includeServices Optional
       * @param {boolean} body.includeUsers Optional
       * @param {boolean} body.includeUserEmails Optional
       * @param {object} body.stepType `StepType` Optional
       * @param {number} body.stepId Optional
       * @param {array} body.stepIds Optional
       * @param {number} body.matchId Optional
       * @param {string} body.userId Optional
       * @param {string} body.targetUserId Optional
       * @param {object} body.sortProperty `StepSubmissionListSortProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      bulkUpdateDueDate({ dueDate } = {}, body, options = {}) {
        const uri = '/api/step-submissions/bulk-update-due-date'
        return this.api.fetch(uri, 'POST', { dueDate }, body, options)
      },

      /**
       * stepSubmission.exportStepSubmissions
       * POST /api/step-submissions/export
       * @param {object} body `ListStepSubmissionRequest` Required
       * @param {string} body.apiVersion Optional
       * @param {number} body.stepSubmissionId Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {array} body.matchFilters `FilterParameter` Optional
       * @param {boolean} body.includeStepStatusData Optional
       * @param {boolean} body.includeSubmissionData Optional
       * @param {boolean} body.includeHealthOrgHierarchy Optional
       * @param {boolean} body.sendFlatSubmissions Optional
       * @param {boolean} body.includeTotalCount Optional
       * @param {boolean} body.excludeTotalCount Optional
       * @param {boolean} body.includeSubmissionStatus Optional
       * @param {boolean} body.includeSteps Optional
       * @param {boolean} body.includeMatches Optional
       * @param {boolean} body.includeHostOrgs Optional
       * @param {boolean} body.includeGuestOrgs Optional
       * @param {boolean} body.includeCapacities Optional
       * @param {boolean} body.includeServices Optional
       * @param {boolean} body.includeUsers Optional
       * @param {boolean} body.includeUserEmails Optional
       * @param {object} body.stepType `StepType` Optional
       * @param {number} body.stepId Optional
       * @param {array} body.stepIds Optional
       * @param {number} body.matchId Optional
       * @param {string} body.userId Optional
       * @param {string} body.targetUserId Optional
       * @param {object} body.sortProperty `StepSubmissionListSortProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      exportStepSubmissions(body, options = {}) {
        const uri = '/api/step-submissions/export'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * stepSubmission.listMyStepSubmissions
       * POST /api/step-submissions/list-my-step-submissions
       * @param {object} body `ListStepSubmissionRequest` Required
       * @param {string} body.apiVersion Optional
       * @param {number} body.stepSubmissionId Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {array} body.matchFilters `FilterParameter` Optional
       * @param {boolean} body.includeStepStatusData Optional
       * @param {boolean} body.includeSubmissionData Optional
       * @param {boolean} body.includeHealthOrgHierarchy Optional
       * @param {boolean} body.sendFlatSubmissions Optional
       * @param {boolean} body.includeTotalCount Optional
       * @param {boolean} body.excludeTotalCount Optional
       * @param {boolean} body.includeSubmissionStatus Optional
       * @param {boolean} body.includeSteps Optional
       * @param {boolean} body.includeMatches Optional
       * @param {boolean} body.includeHostOrgs Optional
       * @param {boolean} body.includeGuestOrgs Optional
       * @param {boolean} body.includeCapacities Optional
       * @param {boolean} body.includeServices Optional
       * @param {boolean} body.includeUsers Optional
       * @param {boolean} body.includeUserEmails Optional
       * @param {object} body.stepType `StepType` Optional
       * @param {number} body.stepId Optional
       * @param {array} body.stepIds Optional
       * @param {number} body.matchId Optional
       * @param {string} body.userId Optional
       * @param {string} body.targetUserId Optional
       * @param {object} body.sortProperty `StepSubmissionListSortProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listMyStepSubmissions(body, options = {}) {
        const uri = '/api/step-submissions/list-my-step-submissions'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * stepSubmission.listStepSubmissions
       * POST /api/step-submissions/list
       * @param {object} body `ListStepSubmissionRequest` Required
       * @param {string} body.apiVersion Optional
       * @param {number} body.stepSubmissionId Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {array} body.matchFilters `FilterParameter` Optional
       * @param {boolean} body.includeStepStatusData Optional
       * @param {boolean} body.includeSubmissionData Optional
       * @param {boolean} body.includeHealthOrgHierarchy Optional
       * @param {boolean} body.sendFlatSubmissions Optional
       * @param {boolean} body.includeTotalCount Optional
       * @param {boolean} body.excludeTotalCount Optional
       * @param {boolean} body.includeSubmissionStatus Optional
       * @param {boolean} body.includeSteps Optional
       * @param {boolean} body.includeMatches Optional
       * @param {boolean} body.includeHostOrgs Optional
       * @param {boolean} body.includeGuestOrgs Optional
       * @param {boolean} body.includeCapacities Optional
       * @param {boolean} body.includeServices Optional
       * @param {boolean} body.includeUsers Optional
       * @param {boolean} body.includeUserEmails Optional
       * @param {object} body.stepType `StepType` Optional
       * @param {number} body.stepId Optional
       * @param {array} body.stepIds Optional
       * @param {number} body.matchId Optional
       * @param {string} body.userId Optional
       * @param {string} body.targetUserId Optional
       * @param {object} body.sortProperty `StepSubmissionListSortProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listStepSubmissions(body, options = {}) {
        const uri = '/api/step-submissions/list'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * stepSubmission.saveSingleFormStepSubmission
       * PUT /api/step-submissions/form
       * @param {object} body `SaveStepFormSubmissionRequest` Required
       * @param {object} body.values Optional
       * @param {number} body.formSubmissionId Optional
       * @param {number} body.stepSubmissionId Optional
       * @param {number} body.matchId Optional
       * @param {string} body.userId Optional
       * @param {number} body.stepId Optional
       * @param {string} body.targetUserId Optional
       * @param {boolean} body.autoApplySharedSteps Optional
       * @param {string} body.submissionInformationMarkdown Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      saveSingleFormStepSubmission(body, options = {}) {
        const uri = '/api/step-submissions/form'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * stepSubmission.setSingleStepCompleted
       * PUT /api/step-submissions/set-completed
       * @param {object} body `SetStepSubmissionCompletedRequest` Required
       * @param {boolean} body.completed Optional
       * @param {number} body.stepSubmissionId Optional
       * @param {number} body.matchId Optional
       * @param {string} body.userId Optional
       * @param {number} body.stepId Optional
       * @param {string} body.targetUserId Optional
       * @param {boolean} body.autoApplySharedSteps Optional
       * @param {string} body.submissionInformationMarkdown Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      setSingleStepCompleted(body, options = {}) {
        const uri = '/api/step-submissions/set-completed'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.stepSubmissionFilterOptions = {
      api: this,

      /**
       * stepSubmissionFilterOptions.getCustomTags
       * GET /api/filter-options/for-step-submissions/custom-tags
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getCustomTags(options = {}) {
        const uri = '/api/filter-options/for-step-submissions/custom-tags'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * stepSubmissionFilterOptions.getOwnerOrgs
       * GET /api/filter-options/for-step-submissions/step-owner-orgs
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getOwnerOrgs(options = {}) {
        const uri = '/api/filter-options/for-step-submissions/step-owner-orgs'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * stepSubmissionFilterOptions.getSchoolFacultyUsers
       * GET /api/filter-options/for-step-submissions/school-faculty-users
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getSchoolFacultyUsers(options = {}) {
        const uri = '/api/filter-options/for-step-submissions/school-faculty-users'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * stepSubmissionFilterOptions.getSteps
       * GET /api/filter-options/for-step-submissions/steps
       * @param {object} parameters
       * @param {boolean} parameters.onlyOurOrgSteps Optional
       * @param {object} parameters.stepType `StepType` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getSteps({ onlyOurOrgSteps, stepType } = {}, options = {}) {
        const uri = '/api/filter-options/for-step-submissions/steps'
        return this.api.fetch(uri, 'GET', { onlyOurOrgSteps, stepType }, null, options)
      },
    }

    this.student = {
      api: this,

      /**
       * student.addStudents
       * POST /api/students
       * @param {object} body `StudentsApiViewModelRequest` Required
       * @param {array} body.students `StudentApiViewModelRequest` Optional
       * @param {string} body.customMessage Optional
       * @param {string} body.apiVersion Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addStudents(body, options = {}) {
        const uri = '/api/students'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * student.bulkEmail
       * POST /api/students/bulk-email
       * @param {object} body `BulkParameters<EmailParameters, IEnumerable<CompositeSchoolStudentId>>` Required
       * @param {array} body.filterParameters `CompositeSchoolStudentId` Optional
       * @param {object} body.actionParameters `EmailParameters` Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      bulkEmail(body, options = {}) {
        const uri = '/api/students/bulk-email'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * student.list
       * POST /api/students/list
       * @param {object} body `ListStudentRequest` Required
       * @param {string} body.email Optional
       * @param {string} body.earliestGraduationDate Optional
       * @param {string} body.latestGraduationDate Optional
       * @param {boolean} body.includeMatches Optional
       * @param {array} body.matchFilters `FilterParameter` Optional
       * @param {string} body.apiVersion Optional
       * @param {boolean} body.includeSchools Optional
       * @param {boolean} body.includeEmails Optional
       * @param {boolean} body.includeAllEmails Optional
       * @param {number} body.orgDomainOrgIdOverride Optional
       * @param {boolean} body.includeTags Optional
       * @param {boolean} body.includeCustomFields Optional
       * @param {boolean} body.includeCoordinators Optional
       * @param {boolean} body.includeStepSummary Optional
       * @param {string} body.studentUserId Optional
       * @param {number} body.capacityId Optional
       * @param {number} body.orgId Optional
       * @param {boolean} body.yearOverride Optional
       * @param {boolean} body.excludeTotalCount Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {array} body.selectedStudentUserIds Optional
       * @param {object} body.sortProperty `StudentListProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      list(body, options = {}) {
        const uri = '/api/students/list'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * student.listOrdersForToStep
       * GET /api/students/precheck/orders/{stepId}
       * @param {object} parameters
       * @param {number} parameters.stepId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listOrdersForToStep({ stepId } = {}, options = {}) {
        const uri = encodePath`/api/students/precheck/orders/${stepId}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * student.setOrderClients
       * POST /api/students/precheck/orderClients
       * @param {object} parameters
       * @param {boolean} parameters.isAssigned Optional
       * @param {number} parameters.orderId Optional
       * @param {number} parameters.orgId Optional
       * @param {boolean} parameters.push Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      setOrderClients({ isAssigned, orderId, orgId, push } = {}, options = {}) {
        const uri = '/api/students/precheck/orderClients'
        return this.api.fetch(uri, 'POST', { isAssigned, orderId, orgId, push }, null, options)
      },

      /**
       * student.update
       * PUT /api/students/{studentUserId}
       * @param {object} parameters
       * @param {string} parameters.studentUserId Required
       * @param {object} body `UpdateIndividualStudentRequestBody` Required
       * @param {string} body.legalFirstName Optional
       * @param {string} body.legalLastName Optional
       * @param {number} body.schoolStudentId Optional
       * @param {string} body.email Optional
       * @param {number} body.studentYear Optional
       * @param {number} body.orgId Optional
       * @param {string} body.graduationDate Optional
       * @param {string} body.discipline Optional
       * @param {string} body.otherDiscipline Optional
       * @param {string} body.customFieldValuesJson Optional
       * @param {array} body.tags Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      update({ studentUserId } = {}, body, options = {}) {
        const uri = encodePath`/api/students/${studentUserId}`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.studentFilterOptions = {
      api: this,

      /**
       * studentFilterOptions.getCustomTags
       * GET /api/filter-options/for-students/custom-tags
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getCustomTags(options = {}) {
        const uri = '/api/filter-options/for-students/custom-tags'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * studentFilterOptions.getDisciplines
       * GET /api/filter-options/for-students/disciplines
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getDisciplines(options = {}) {
        const uri = '/api/filter-options/for-students/disciplines'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * studentFilterOptions.getSchoolCoordinatorUsers
       * GET /api/filter-options/for-students/school-coordinator-users
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getSchoolCoordinatorUsers(options = {}) {
        const uri = '/api/filter-options/for-students/school-coordinator-users'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * studentFilterOptions.getSchools
       * GET /api/filter-options/for-students/schools
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getSchools(options = {}) {
        const uri = '/api/filter-options/for-students/schools'
        return this.api.fetch(uri, 'GET', null, null, options)
      },
    }

    this.studentReport = {
      api: this,

      /**
       * studentReport.exportReport
       * POST /api/reports/students/export
       * @param {object} body `StudentReportCriteria` Required
       * @param {boolean} body.includeProfile Optional
       * @param {boolean} body.includeRotationInfo Optional
       * @param {boolean} body.includeHealthOrgHierarchy Optional
       * @param {boolean} body.includeFaculty Optional
       * @param {boolean} body.includePreceptors Optional
       * @param {boolean} body.includeStudents Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {string} body.sortProperty Optional
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      exportReport(body, options = {}) {
        const uri = '/api/reports/students/export'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * studentReport.run
       * POST /api/reports/students
       * @param {object} body `StudentReportCriteria` Required
       * @param {boolean} body.includeProfile Optional
       * @param {boolean} body.includeRotationInfo Optional
       * @param {boolean} body.includeHealthOrgHierarchy Optional
       * @param {boolean} body.includeFaculty Optional
       * @param {boolean} body.includePreceptors Optional
       * @param {boolean} body.includeStudents Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {string} body.sortProperty Optional
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      run(body, options = {}) {
        const uri = '/api/reports/students'
        return this.api.fetch(uri, 'POST', null, body, options)
      },
    }

    this.subscriptions = {
      api: this,

      /**
       * subscriptions.addPromoCodeToExistingSubscription
       * PUT /api/subscriptions/promotion-code
       * @param {object} parameters
       * @param {string} parameters.promotionCode Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addPromoCodeToExistingSubscription({ promotionCode } = {}, options = {}) {
        const uri = '/api/subscriptions/promotion-code'
        return this.api.fetch(uri, 'PUT', { promotionCode }, null, options)
      },

      /**
       * subscriptions.checkPromoCode
       * GET /api/subscriptions/check-promo-code
       * @param {object} parameters
       * @param {string} parameters.promotionCode Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      checkPromoCode({ promotionCode } = {}, options = {}) {
        const uri = '/api/subscriptions/check-promo-code'
        return this.api.fetch(uri, 'GET', { promotionCode }, null, options)
      },

      /**
       * subscriptions.createStripeCustomerPortalSession
       * POST /api/subscriptions/create-customer-portal-session
       * @param {object} parameters
       * @param {string} parameters.billingEmail Optional
       * @param {number} parameters.orgId Optional
       * @param {string} parameters.pathname Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      createStripeCustomerPortalSession({ billingEmail, orgId, pathname } = {}, options = {}) {
        const uri = '/api/subscriptions/create-customer-portal-session'
        return this.api.fetch(uri, 'POST', { billingEmail, orgId, pathname }, null, options)
      },

      /**
       * subscriptions.createStripeSetupIntent
       * POST /api/subscriptions/createStripeSetupIntent
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      createStripeSetupIntent(options = {}) {
        const uri = '/api/subscriptions/createStripeSetupIntent'
        return this.api.fetch(uri, 'POST', null, null, options)
      },

      /**
       * subscriptions.getOrgSubscription
       * GET /api/subscriptions
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getOrgSubscription(options = {}) {
        const uri = '/api/subscriptions'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * subscriptions.getOrgSubscriptionByOrgId
       * GET /api/subscriptions/by-org/{orgId}
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getOrgSubscriptionByOrgId({ orgId } = {}, options = {}) {
        const uri = encodePath`/api/subscriptions/by-org/${orgId}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * subscriptions.getOrgSubscriptionHistory
       * GET /api/subscriptions/subscription-history
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getOrgSubscriptionHistory(options = {}) {
        const uri = '/api/subscriptions/subscription-history'
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * subscriptions.getOrgSubscriptionHistoryByOrgId
       * GET /api/subscriptions/subscription-history-cx
       * @param {object} parameters
       * @param {number} parameters.orgId Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getOrgSubscriptionHistoryByOrgId({ orgId } = {}, options = {}) {
        const uri = '/api/subscriptions/subscription-history-cx'
        return this.api.fetch(uri, 'GET', { orgId }, null, options)
      },

      /**
       * subscriptions.handleStripeWebhooks
       * POST /api/subscriptions/stripe-webhooks
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      handleStripeWebhooks(options = {}) {
        const uri = '/api/subscriptions/stripe-webhooks'
        return this.api.fetch(uri, 'POST', null, null, options)
      },

      /**
       * subscriptions.previewSaveSubscriptionPackagesInvoice
       * POST /api/subscriptions/packages-preview
       * @param {object} body `SaveSubscriptionPackagesRequestBody` Required
       * @param {string} body.prorationDate Required
       * @param {string} body.promotionCode Optional
       * @param {boolean} body.isAnnual Optional
       * @param {array} body.packages `SubscriptionPackageTvp` Optional
       * @param {string} body.billingEmail Optional
       * @param {string} body.paymentMethodId Optional
       * @param {string} body.paymentMethodType Optional
       * @param {string} body.comment Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      previewSaveSubscriptionPackagesInvoice(body, options = {}) {
        const uri = '/api/subscriptions/packages-preview'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * subscriptions.previewSaveSubscriptionPackagesInvoiceCx
       * POST /api/subscriptions/packages-preview-cx
       * @param {object} parameters
       * @param {number} parameters.orgId Optional
       * @param {object} body `SaveSubscriptionPackagesRequestBody` Required
       * @param {string} body.prorationDate Required
       * @param {string} body.promotionCode Optional
       * @param {boolean} body.isAnnual Optional
       * @param {array} body.packages `SubscriptionPackageTvp` Optional
       * @param {string} body.billingEmail Optional
       * @param {string} body.paymentMethodId Optional
       * @param {string} body.paymentMethodType Optional
       * @param {string} body.comment Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      previewSaveSubscriptionPackagesInvoiceCx({ orgId } = {}, body, options = {}) {
        const uri = '/api/subscriptions/packages-preview-cx'
        return this.api.fetch(uri, 'POST', { orgId }, body, options)
      },

      /**
       * subscriptions.saveSubscriptionPackages
       * PUT /api/subscriptions/packages
       * @param {object} body `SaveSubscriptionPackagesRequestBody` Required
       * @param {string} body.prorationDate Required
       * @param {string} body.promotionCode Optional
       * @param {boolean} body.isAnnual Optional
       * @param {array} body.packages `SubscriptionPackageTvp` Optional
       * @param {string} body.billingEmail Optional
       * @param {string} body.paymentMethodId Optional
       * @param {string} body.paymentMethodType Optional
       * @param {string} body.comment Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      saveSubscriptionPackages(body, options = {}) {
        const uri = '/api/subscriptions/packages'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * subscriptions.saveSubscriptionPackagesCx
       * PUT /api/subscriptions/packages-cx
       * @param {object} parameters
       * @param {number} parameters.orgId Optional
       * @param {object} body `SaveSubscriptionPackagesRequestBody` Required
       * @param {string} body.prorationDate Required
       * @param {string} body.promotionCode Optional
       * @param {boolean} body.isAnnual Optional
       * @param {array} body.packages `SubscriptionPackageTvp` Optional
       * @param {string} body.billingEmail Optional
       * @param {string} body.paymentMethodId Optional
       * @param {string} body.paymentMethodType Optional
       * @param {string} body.comment Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      saveSubscriptionPackagesCx({ orgId } = {}, body, options = {}) {
        const uri = '/api/subscriptions/packages-cx'
        return this.api.fetch(uri, 'PUT', { orgId }, body, options)
      },

      /**
       * subscriptions.upsertPaymentMethod
       * PUT /api/subscriptions/upsert-payment-method
       * @param {object} body `UpsertPaymentMethodRequest` Required
       * @param {string} body.stripeTokenId Optional
       * @param {string} body.paymentMethodType Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      upsertPaymentMethod(body, options = {}) {
        const uri = '/api/subscriptions/upsert-payment-method'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },

      /**
       * subscriptions.verifyBankAccount
       * PUT /api/subscriptions/verify-bank-account
       * @param {object} body `VerifyBankAccountRequest` Required
       * @param {string} body.bankId Optional
       * @param {array} body.microTransactionAmounts Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      verifyBankAccount(body, options = {}) {
        const uri = '/api/subscriptions/verify-bank-account'
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.tag = {
      api: this,

      /**
       * tag.listClinicalInterests
       * GET /api/tags/clinical-interests
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listClinicalInterests(options = {}) {
        const { cache } = this.listClinicalInterests
        const cachePromise = cache.tryGet(null, null, options)
        if (cachePromise) return cachePromise
        const uri = '/api/tags/clinical-interests'
        const promise = this.api.fetch(uri, 'GET', null, null, options)
        cache.add(null, null, options, promise)
        return promise
      },

      /**
       * tag.listDisciplines
       * GET /api/tags/disciplines
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listDisciplines(options = {}) {
        const { cache } = this.listDisciplines
        const cachePromise = cache.tryGet(null, null, options)
        if (cachePromise) return cachePromise
        const uri = '/api/tags/disciplines'
        const promise = this.api.fetch(uri, 'GET', null, null, options)
        cache.add(null, null, options, promise)
        return promise
      },

      /**
       * tag.listInterests
       * GET /api/tags/interests
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listInterests(options = {}) {
        const { cache } = this.listInterests
        const cachePromise = cache.tryGet(null, null, options)
        if (cachePromise) return cachePromise
        const uri = '/api/tags/interests'
        const promise = this.api.fetch(uri, 'GET', null, null, options)
        cache.add(null, null, options, promise)
        return promise
      },

      /**
       * tag.listLanguages
       * GET /api/tags/languages
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listLanguages(options = {}) {
        const { cache } = this.listLanguages
        const cachePromise = cache.tryGet(null, null, options)
        if (cachePromise) return cachePromise
        const uri = '/api/tags/languages'
        const promise = this.api.fetch(uri, 'GET', null, null, options)
        cache.add(null, null, options, promise)
        return promise
      },

      /**
       * tag.listRotationTypes
       * GET /api/tags/rotation-types
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listRotationTypes(options = {}) {
        const { cache } = this.listRotationTypes
        const cachePromise = cache.tryGet(null, null, options)
        if (cachePromise) return cachePromise
        const uri = '/api/tags/rotation-types'
        const promise = this.api.fetch(uri, 'GET', null, null, options)
        cache.add(null, null, options, promise)
        return promise
      },

      /**
       * tag.listSpecialties
       * GET /api/tags/specialties
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      listSpecialties(options = {}) {
        const { cache } = this.listSpecialties
        const cachePromise = cache.tryGet(null, null, options)
        if (cachePromise) return cachePromise
        const uri = '/api/tags/specialties'
        const promise = this.api.fetch(uri, 'GET', null, null, options)
        cache.add(null, null, options, promise)
        return promise
      },
    }

    this.tag.listClinicalInterests.cache = new ApiCache(1800, false)
    this.tag.listDisciplines.cache = new ApiCache(1800, false)
    this.tag.listInterests.cache = new ApiCache(1800, false)
    this.tag.listLanguages.cache = new ApiCache(1800, false)
    this.tag.listRotationTypes.cache = new ApiCache(1800, false)
    this.tag.listSpecialties.cache = new ApiCache(1800, false)

    this.time = {
      api: this,

      /**
       * time.getRecommendedTimeZones
       * GET /api/time/recommend-zones
       * @param {object} parameters
       * @param {number} parameters.latitude Optional
       * @param {number} parameters.longitude Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getRecommendedTimeZones({ latitude, longitude } = {}, options = {}) {
        const uri = '/api/time/recommend-zones'
        return this.api.fetch(uri, 'GET', { latitude, longitude }, null, options)
      },

      /**
       * time.getTimeZones
       * GET /api/time/zones
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getTimeZones(options = {}) {
        const uri = '/api/time/zones'
        return this.api.fetch(uri, 'GET', null, null, options)
      },
    }

    this.user = {
      api: this,

      /**
       * user.connections
       * GET /api/user/{username}/connections
       * @param {object} parameters
       * @param {string} parameters.username Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      connections({ username } = {}, options = {}) {
        const uri = encodePath`/api/user/${username}/connections`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * user.get
       * GET /api/user/{username}
       * @param {object} parameters
       * @param {string} parameters.username Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      get({ username } = {}, options = {}) {
        const uri = encodePath`/api/user/${username}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * user.matchHistory
       * GET /api/user/{username}/matchHistory
       * @param {object} parameters
       * @param {string} parameters.username Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      matchHistory({ username } = {}, options = {}) {
        const uri = encodePath`/api/user/${username}/matchHistory`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * user.resetTwoFactor
       * PUT /api/user/{userId}/resetTwoFactor
       * @param {object} parameters
       * @param {string} parameters.userId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      resetTwoFactor({ userId } = {}, options = {}) {
        const uri = encodePath`/api/user/${userId}/resetTwoFactor`
        return this.api.fetch(uri, 'PUT', null, null, options)
      },

      /**
       * user.unlockUser
       * PUT /api/user/{userId}/unlock
       * @param {object} parameters
       * @param {string} parameters.userId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      unlockUser({ userId } = {}, options = {}) {
        const uri = encodePath`/api/user/${userId}/unlock`
        return this.api.fetch(uri, 'PUT', null, null, options)
      },
    }

    this.userDocument = {
      api: this,

      /**
       * userDocument.addDocument
       * POST /api/user-documents
       * @param {object} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      addDocument(body, options = {}) {
        const uri = '/api/user-documents'
        options = { ...options, isFileUpload: true }
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * userDocument.delete
       * DELETE /api/user-documents
       * @param {object} body `DeleteUserDocumentsRequest` Required
       * @param {array} body.userDocumentIds Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      delete(body, options = {}) {
        const uri = '/api/user-documents'
        return this.api.fetch(uri, 'DELETE', null, body, options)
      },

      /**
       * userDocument.getByFileName
       * GET /api/user-documents/by-file-name
       * @param {object} parameters
       * @param {string} parameters.fileName Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getByFileName({ fileName } = {}, options = {}) {
        const uri = '/api/user-documents/by-file-name'
        return this.api.fetch(uri, 'GET', { fileName }, null, options)
      },

      /**
       * userDocument.list
       * POST /api/user-documents/list
       * @param {object} body `ListUserDocumentsRequestBody` Required
       * @param {boolean} body.excludeTotalCount Optional
       * @param {array} body.filters `FilterParameter` Optional
       * @param {array} body.selectedUserDocumentIds Optional
       * @param {object} body.sortProperty `UserDocumentListProperty` Required
       * @param {boolean} body.sortAscending Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      list(body, options = {}) {
        const uri = '/api/user-documents/list'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * userDocument.replaceDocument
       * POST /api/user-documents/{userDocumentId}/replace
       * @param {object} parameters
       * @param {number} parameters.userDocumentId Required
       * @param {object} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      replaceDocument({ userDocumentId } = {}, body, options = {}) {
        const uri = encodePath`/api/user-documents/${userDocumentId}/replace`
        options = { ...options, isFileUpload: true }
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * userDocument.update
       * PUT /api/user-documents/{userDocumentId}
       * @param {object} parameters
       * @param {number} parameters.userDocumentId Required
       * @param {object} body `UpdateUserDocumentRequest` Required
       * @param {number} body.userDocumentId Optional
       * @param {string} body.nameOriginal Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      update({ userDocumentId } = {}, body, options = {}) {
        const uri = encodePath`/api/user-documents/${userDocumentId}`
        return this.api.fetch(uri, 'PUT', null, body, options)
      },
    }

    this.userImport = {
      api: this,

      /**
       * userImport.getEmptyStudentSheet
       * GET /api/import/empty/students
       * @param {object} parameters
       * @param {boolean} parameters.includeTags Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getEmptyStudentSheet({ includeTags } = {}, options = {}) {
        const uri = '/api/import/empty/students'
        return this.api.fetch(uri, 'GET', { includeTags }, null, options)
      },

      /**
       * userImport.importStudents
       * POST /api/import/{orgId}/students
       * @param {object} parameters
       * @param {string} parameters.customMessage Optional
       * @param {number} parameters.orgId Required
       * @param {object} body Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      importStudents({ customMessage, orgId } = {}, body, options = {}) {
        const uri = encodePath`/api/import/${orgId}/students`
        options = { ...options, isFileUpload: true }
        return this.api.fetch(uri, 'POST', { customMessage }, body, options)
      },
    }

    this.utilizationReport = {
      api: this,

      /**
       * utilizationReport.detailReportForOrg
       * GET /api/reports/utilization/{orgId}/detail
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      detailReportForOrg({ orgId } = {}, options = {}) {
        const uri = encodePath`/api/reports/utilization/${orgId}/detail`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * utilizationReport.getMonthlyMatchCountsForOrg
       * GET /api/reports/utilization/{orgId}/rotation-counts/by-month/from/{from}/to/{to}
       * @param {object} parameters
       * @param {string} parameters.from Required
       * @param {number} parameters.orgId Required
       * @param {string} parameters.to Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getMonthlyMatchCountsForOrg({ from, orgId, to } = {}, options = {}) {
        const uri = encodePath`/api/reports/utilization/${orgId}/rotation-counts/by-month/from/${from}/to/${to}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * utilizationReport.getRotationBreakdown
       * GET /api/reports/utilization/rotations/{orgId}
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getRotationBreakdown({ orgId } = {}, options = {}) {
        const uri = encodePath`/api/reports/utilization/rotations/${orgId}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * utilizationReport.getYearlyMatchCountsForOrg
       * GET /api/reports/utilization/{orgId}/rotation-counts/by-year/from/{from}/to/{to}
       * @param {object} parameters
       * @param {number} parameters.from Required
       * @param {number} parameters.orgId Required
       * @param {number} parameters.to Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getYearlyMatchCountsForOrg({ from, orgId, to } = {}, options = {}) {
        const uri = encodePath`/api/reports/utilization/${orgId}/rotation-counts/by-year/from/${from}/to/${to}`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * utilizationReport.reportForAll
       * POST /api/reports/utilization/all
       * @param {object} body `OrgCountsRequest` Required
       * @param {number} body.orgId Optional
       * @param {number} body.parentOrgId Optional
       * @param {string} body.filter Optional
       * @param {array} body.includeColumns `OrgReportColumn` Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      reportForAll(body, options = {}) {
        const uri = '/api/reports/utilization/all'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * utilizationReport.reportForAllExport
       * POST /api/reports/utilization/all/export
       * @param {object} body `OrgCountsRequest` Required
       * @param {number} body.orgId Optional
       * @param {number} body.parentOrgId Optional
       * @param {string} body.filter Optional
       * @param {array} body.includeColumns `OrgReportColumn` Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      reportForAllExport(body, options = {}) {
        const uri = '/api/reports/utilization/all/export'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * utilizationReport.reportForOrg
       * POST /api/reports/utilization/{orgId}
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} body `OrgCountsRequest` Required
       * @param {number} body.orgId Optional
       * @param {number} body.parentOrgId Optional
       * @param {string} body.filter Optional
       * @param {array} body.includeColumns `OrgReportColumn` Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      reportForOrg({ orgId } = {}, body, options = {}) {
        const uri = encodePath`/api/reports/utilization/${orgId}`
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * utilizationReport.reportForOrgExport
       * POST /api/reports/utilization/{orgId}/export
       * @param {object} parameters
       * @param {number} parameters.orgId Required
       * @param {object} body `OrgCountsRequest` Required
       * @param {number} body.orgId Optional
       * @param {number} body.parentOrgId Optional
       * @param {string} body.filter Optional
       * @param {array} body.includeColumns `OrgReportColumn` Optional
       * @param {number} body.pageSize Optional
       * @param {number} body.offset Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      reportForOrgExport({ orgId } = {}, body, options = {}) {
        const uri = encodePath`/api/reports/utilization/${orgId}/export`
        return this.api.fetch(uri, 'POST', null, body, options)
      },
    }

    this.warmup = {
      api: this,

      /**
       * warmup.warmup
       * GET /api/warmup
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      warmup(options = {}) {
        const uri = '/api/warmup'
        return this.api.fetch(uri, 'GET', null, null, options)
      },
    }

    this.webhook = {
      api: this,

      /**
       * webhook.add
       * POST /api/webhooks
       * @param {object} body `WebhookViewModel` Required
       * @param {number} body.webhookId Optional
       * @param {number} body.orgId Optional
       * @param {string} body.method Required
       * @param {string} body.uri Required
       * @param {array} body.headers `KeyValuePair<string, IEnumerable<string>>` Optional
       * @param {string} body.body Optional
       * @param {string} body.deleted Optional
       * @param {array} body.notificationTypes `NotificationType` Optional
       * @param {string} body.dateCreated Required
       * @param {string} body.dateModified Required
       * @param {string} body.userCreated Optional
       * @param {string} body.userModified Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      add(body, options = {}) {
        const uri = '/api/webhooks'
        return this.api.fetch(uri, 'POST', null, body, options)
      },

      /**
       * webhook.delete
       * DELETE /api/webhooks/{webhookId}
       * @param {object} parameters
       * @param {number} parameters.webhookId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      delete({ webhookId } = {}, options = {}) {
        const uri = encodePath`/api/webhooks/${webhookId}`
        return this.api.fetch(uri, 'DELETE', null, null, options)
      },

      /**
       * webhook.getLogs
       * GET /api/webhooks/{webhookId}/logs
       * @param {object} parameters
       * @param {number} parameters.webhookId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      getLogs({ webhookId } = {}, options = {}) {
        const uri = encodePath`/api/webhooks/${webhookId}/logs`
        return this.api.fetch(uri, 'GET', null, null, options)
      },

      /**
       * webhook.list
       * GET /api/webhooks
       * @param {object} parameters
       * @param {boolean} parameters.includeDeleted Optional
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      list({ includeDeleted } = {}, options = {}) {
        const uri = '/api/webhooks'
        return this.api.fetch(uri, 'GET', { includeDeleted }, null, options)
      },

      /**
       * webhook.test
       * GET /api/webhooks/{webhookId}/test
       * @param {object} parameters
       * @param {number} parameters.webhookId Required
       * @param {object} options to override fetch settings
       * @returns {Promise} fetch promise
       */
      test({ webhookId } = {}, options = {}) {
        const uri = encodePath`/api/webhooks/${webhookId}/test`
        return this.api.fetch(uri, 'GET', null, null, options)
      },
    }
  }
}

export default Api