<Alert type="warning" class={className}>
  {retryMessage}
  <span class="select-none">
    {#if loading}
      <Spinner /> Retrying…
    {:else}
      <a href={null} on:click={() => loadManager.retry()}>Retry?</a>
    {/if}
  </span>
</Alert>

<script>
  import Alert from 'components/bootstrap/Alert.svelte'
  import Spinner from 'components/Spinner.svelte'

  export let loadManager
  export let retryMessage
  export let isFirstPage = false
  let className = null
  export { className as class }

  const lmLoadingFirstPage = loadManager.loadingFirstPage
  const lmLoading = loadManager.loading

  $: loading = isFirstPage ? $lmLoadingFirstPage : $lmLoading
</script>
